import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { mapGender } from "app/modules/employee/helpers";
import { Employee } from "app/services/employee";
import { FC } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";

export interface DetailEmployeeGeneralProps {}

const DetailEmployeeGeneral: FC<DetailEmployeeGeneralProps> = () => {
  const { data: employee } = useDetailPageContext<Employee>();
  const { employeeText } = useAppTranslation();
  const fullName = employee ? employee.fullName : "";

  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={8}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {employeeText.detail.infomation}
              </h5>
              <div className="table-responsive">
                <Table className="table-borderless mb-0">
                  <tbody>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.fullName}:
                      </th>
                      <td className="text-muted">{fullName}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.gender}:
                      </th>
                      <td className="text-muted">
                        {employee?.gender ? mapGender(employee.gender) : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.phone}:
                      </th>
                      <td className="text-muted">{employee?.phone}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.email} :
                      </th>
                      <td className="text-muted">{employee?.email}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.department}:
                      </th>
                      <td className="text-muted">
                        {employee?.department.name}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.jobTitle}:
                      </th>
                      <td className="text-muted">{employee?.jobTitle.name}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.dateOfBirth}:
                      </th>
                      <td className="text-muted">
                        {employee?.birthdate
                          ? new Date(employee.birthdate).toLocaleDateString(
                              "vi-VN",
                            )
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.taxCode}:
                      </th>
                      <td className="text-muted">{employee?.taxCode || "-"}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.nationalId}:
                      </th>
                      <td className="text-muted">
                        {employee?.nationalId || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.dateOfIssue}:
                      </th>
                      <td className="text-muted">
                        {employee?.dateOfIssue
                          ? new Date(employee.dateOfIssue).toLocaleDateString(
                              "vi-VN",
                            )
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.placeOfIssue}:
                      </th>
                      <td className="text-muted">
                        {employee?.placeOfIssue || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.permanentAddress}:
                      </th>
                      <td className="text-muted">
                        {employee?.permanentAddress || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.dateOfJoining}:
                      </th>
                      <td className="text-muted">
                        {employee?.dateOfJoining
                          ? new Date(employee.dateOfJoining).toLocaleDateString(
                              "vi-VN",
                            )
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {employeeText.field.dateOfLeaving}:
                      </th>
                      <td className="text-muted">
                        {employee?.dateOfLeaving
                          ? new Date(employee.dateOfLeaving).toLocaleDateString(
                              "vi-VN",
                            )
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailEmployeeGeneral;
