import { FC } from "react";
import Lightbox, { LightboxProps } from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export interface ImagePopupProps {
  open: LightboxProps["open"];
  onClose: LightboxProps["close"];
  index?: LightboxProps["index"];
  updateIndex?: ({ index }: { index: number }) => void;
  slides: LightboxProps["slides"];
  render?: LightboxProps["render"];
}

export const ImagePopup: FC<ImagePopupProps> = ({
  onClose,
  open,
  index,
  updateIndex,
  slides,
  render,
}) => {
  return (
    <Lightbox
      styles={{
        root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .5)" },
      }}
      open={!!open}
      close={onClose}
      plugins={[Download, Zoom, Counter, Thumbnails]}
      thumbnails={{
        position: "bottom",
        showToggle: false,
        vignette: false,
        imageFit: "cover",
      }}
      index={index}
      on={{
        view: updateIndex,
      }}
      carousel={{ finite: true }}
      controller={{ closeOnBackdropClick: true }}
      slides={slides}
      render={{
        iconPrev: () => null,
        iconNext: () => null,
        ...render,
      }}
    />
  );
};
