import isNil from "lodash/isNil";
import isString from "lodash/isString";
import { FC, ReactNode } from "react";
import { Col, Row } from "reactstrap";

export interface ListInfoItemProps {
  value: string | ReactNode;
  label: string | ReactNode;
}

export const ListInfoItem: FC<ListInfoItemProps> = ({ label, value }) => {
  return (
    <Row>
      <Col xs={12} sm={6} md={4}>
        {isString(label) || isNil(label) ? (
          <p className="list-info-item-title">{label}:</p>
        ) : (
          `${label}:`
        )}
      </Col>
      <Col xs={12} sm={6} md={8}>
        {typeof value !== "object" ? (
          <p className="text-muted">{value || "-"}</p>
        ) : (
          value || "-"
        )}
      </Col>
    </Row>
  );
};
