import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { MasterData } from "./types";
import { Response } from "../types";

export const RETRIEVE_MASTER_DATA_BY_ID_URL = (id: ID) =>
  `/api/master-data/${id}`;

export type RetrieveMasterDataByIdResponse = Response<MasterData>;

export type RetrieveMasterDataByIdArgs = {
  id: ID;
};

export const retrieveMasterDataById = ({ id }: RetrieveMasterDataByIdArgs) => {
  return httpClient.get<RetrieveMasterDataByIdResponse>(
    RETRIEVE_MASTER_DATA_BY_ID_URL(id),
  );
};

export const useRetrieveMasterDataByIdQuery = (
  retrieveMasterDatasArgs: RetrieveMasterDataByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveMasterDataByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveMasterDataByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_MASTER_DATA_BY_ID_URL(retrieveMasterDatasArgs.id)],
    queryFn: () => retrieveMasterDataById(retrieveMasterDatasArgs),
    ...opts,
  });
