import { PropsWithChildren } from "react";
import { FormPageBreadCrumb } from "./FormPageBreadCrumb";
import { createFormPageContext } from "./FormPageContext";
import { FormPageMain } from "./FormPageMain";

export interface FormPageProps {
  loading?: boolean;
}

const FormPage = ({ children, loading }: FormPageProps & PropsWithChildren) => {
  const FormPageContext = createFormPageContext();
  return (
    <FormPageContext.Provider value={{ loading }}>
      <div className="page-detail-content">
        <div className="page-content-wrapper overflow-x-hidden">{children}</div>
      </div>
    </FormPageContext.Provider>
  );
};

export default Object.assign(FormPage, {
  Main: FormPageMain,
  BreadCrumb: FormPageBreadCrumb,
});
