import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { User } from "./types";

export const ACTIVE_USER_URL = (id: ID) => `/api/users/${id}/active`;

export enum ActiveUserError {}

export const activeUserErrorMessages = {};

export interface ActiveUserArgs {
  id: ID;
}

export type ActiveUserResponse = Response<User>;

export const activeUser = ({ id }: ActiveUserArgs) => {
  return httpClient.post<ActiveUserResponse>(ACTIVE_USER_URL(id));
};

export const useActiveUserMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<ActiveUserResponse>,
      AxiosError<any>,
      ActiveUserArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<ActiveUserResponse>,
    AxiosError<any>,
    ActiveUserArgs
  >({
    mutationFn: activeUser,
    mutationKey: [ACTIVE_USER_URL],
    ...opts,
  });
