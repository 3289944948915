import { useAuth } from "app/components/organisms/AuthProvider";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  changePasswordErrorMessages,
  useChangePasswordMutation,
} from "app/services/auth";

interface useChangePasswordProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useChangePassword = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: useChangePasswordProps = {}) => {
  const toast = useToastify();
  const { refreshCurrentUser } = useAuth();
  return useChangePasswordMutation({
    onSuccess: () => {
      toast.success();
      refreshCurrentUser();
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          changePasswordErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
