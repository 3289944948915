import once from "lodash/once";
import { createContext, useContext } from "react";
interface DetailPageContextProps<T = any> {
  data?: T;
  loading?: boolean;
}

export const createDetailPageContext = once(<T,>() =>
  createContext<DetailPageContextProps<T>>({
    data: undefined,
  }),
);

export const useDetailPageContext = <T,>() => {
  const context = useContext(createDetailPageContext<T>());

  return context;
};
