import { FC } from "react";
import { Spinner } from "reactstrap";

export interface LoadingProps {}

export const Loading: FC<LoadingProps> = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: 50 }}
    >
      <Spinner animation="border" color="primary" />
    </div>
  );
};
