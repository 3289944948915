import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { JobTitle } from "./types";

export const UPDATE_JOB_TITLE_URL = (id: ID) => `/api/job-titles/${id}`;

export enum UpdateJobTitleError {}

export const updateJobTitleErrorMessages = {};

export type UpdateJobTitleResponse = Response<JobTitle>;

export type UpdateJobTitleArgs = {
  id: ID;
};

export const updateJobTitle = ({ id, ...args }: UpdateJobTitleArgs) => {
  return httpClient.put<UpdateJobTitleResponse>(UPDATE_JOB_TITLE_URL(id), args);
};

export const useUpdateJobTitleMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateJobTitleResponse>,
      AxiosError<any>,
      UpdateJobTitleArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateJobTitleResponse>,
    AxiosError<any>,
    UpdateJobTitleArgs
  >({
    mutationFn: updateJobTitle,
    mutationKey: [UPDATE_JOB_TITLE_URL],
    ...opts,
  });
