import { UseInfiniteQueryResult } from "@tanstack/react-query";
import {
  RetrieveFilesArgs,
  RetrieveFilesResponse,
  useRetrieveFilesInfiniteQuery,
} from "app/services/file";
import { Response } from "app/services/types";

export const useRetrieveFilesInfinite = (
  args: RetrieveFilesArgs,
  opts?: Parameters<typeof useRetrieveFilesInfiniteQuery>[1],
) => {
  const result = useRetrieveFilesInfiniteQuery(
    args,
    opts,
  ) as UseInfiniteQueryResult<{ pages: Response<RetrieveFilesResponse>[] }>;
  return {
    files: result.data,
    isLoadingFiles: result.isPending,
    ...result,
  };
};
