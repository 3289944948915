import {
  RetrieveMasterTypesArgs,
  useRetrieveMasterTypesQuery,
} from "app/services/masterData";

export const useRetrieveMasterTypes = (
  args: RetrieveMasterTypesArgs,
  opts?: Parameters<typeof useRetrieveMasterTypesQuery>[1],
) => {
  const result = useRetrieveMasterTypesQuery(args, opts);
  return {
    masterTypes: result.data?.data.data,
    isLoadingMasterTypes: result.isLoading,
    ...result,
  };
};
