import {
  RetrieveEmployeeByIdArgs,
  useRetrieveEmployeeByIdQuery,
} from "app/services/employee";

export const useRetrieveEmployeeById = (
  args: RetrieveEmployeeByIdArgs,
  opts?: Parameters<typeof useRetrieveEmployeeByIdQuery>[1],
) => {
  const result = useRetrieveEmployeeByIdQuery(args, opts as any);
  return {
    employee: result.data?.data.data,
    isLoadingEmployee: result.isLoading,
    ...result,
  };
};
