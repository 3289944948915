import { FC } from "react";
import { ButtonProps, Spinner, Button as RSButton } from "reactstrap";

export interface IButtonProps extends ButtonProps {
  loading?: boolean;
}

export const Button: FC<IButtonProps> = ({ loading, children, ...props }) => {
  return (
    <RSButton {...props}>{loading ? <Spinner size="sm" /> : children}</RSButton>
  );
};
