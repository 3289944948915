import {
  RetrieveVendorByIdArgs,
  useRetrieveVendorByIdQuery,
} from "app/services/vendor";

export const useRetrieveVendorById = (
  args: RetrieveVendorByIdArgs,
  opts?: Parameters<typeof useRetrieveVendorByIdQuery>[1],
) => {
  const result = useRetrieveVendorByIdQuery(args, opts as any);
  return {
    vendor: result.data?.data.data,
    isLoadingVendor: result.isLoading,
    ...result,
  };
};
