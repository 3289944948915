import { AUTH_ACCESS_TOKEN, AUTH_REFRESH_TOKEN } from "app/helpers";
import { refreshToken } from "app/services/auth";
import axios, { AxiosInstance } from "axios";

const httpClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 120000, // Waiting 2m for request timeout
  headers: {
    "Cache-Control": "no-cache",
  },
});

httpClient.interceptors.request.use(function handleRequest(reqConfig) {
  const configOverride = reqConfig;
  const token = localStorage.getItem(AUTH_ACCESS_TOKEN);
  configOverride.headers!.Authorization = token ? `Bearer ${token}` : false;
  return configOverride;
});

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const token = localStorage.getItem(AUTH_ACCESS_TOKEN);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const originalRequest = error.config;
    if (token && error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const data = await refreshToken();
        if (data.data.accessToken) {
          // Save the new tokens to localStorage
          localStorage.setItem(AUTH_ACCESS_TOKEN, data.data.accessToken);
          localStorage.setItem(AUTH_REFRESH_TOKEN, data.data.refreshToken);

          // Retry the original request with the new token
          originalRequest.headers["Authorization"] =
            `Bearer ${data.data.accessToken}`;
          return httpClient(originalRequest);
        }
      } catch (refreshError) {
        localStorage.removeItem(AUTH_ACCESS_TOKEN);
        localStorage.removeItem(AUTH_REFRESH_TOKEN);
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);

export default httpClient;
