import {
  CreateDepartmentArgs,
  Department,
  UpdateDepartmentArgs,
} from "app/services/department";
import { WithID } from "app/models";
import { DepartmentFormData } from "./components";

export const mapper = ({
  name,
}: DepartmentFormData): CreateDepartmentArgs | UpdateDepartmentArgs => {
  return {
    name,
  };
};

export const mapCreateDepartment = (
  data: DepartmentFormData,
): CreateDepartmentArgs => {
  return mapper(data);
};

export const mapUpdateDepartment = ({
  id,
  ...data
}: WithID<DepartmentFormData>): UpdateDepartmentArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultDepartment = ({
  name,
}: Partial<Department> = {}): DepartmentFormData => {
  return {
    name: name ?? "",
  };
};
