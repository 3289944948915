import { Loading } from "app/components/molecules/Loading";
import { Source } from "app/models";
import { getUploadedFileIds } from "app/modules/file/helpers";
import { OrderForm, OrderFormData } from "app/modules/order/components";
import { useCreateOrder, useRetrieveOrderById } from "app/modules/order/hooks";
import { mapCreateOrder, mapDefaultOrder } from "app/modules/order/mapper";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

export interface DuplicateOrderProps {}

const DuplicateOrder: FC<DuplicateOrderProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { order, isLoading } = useRetrieveOrderById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useCreateOrder({
    onSuccess: () => {
      navigate("/orders");
    },
  });

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const { mutateAsync: deleteFile } = useDeleteFile();

  const handleSubmit = async (data: OrderFormData) => {
    const imageIds =
      (await getUploadedFileIds({
        data: data.images,
        fileKey: "image",
        source: Source.Order,
        upload,
      })) || [];
    const documentIds =
      (await getUploadedFileIds({
        data: data.documents,
        fileKey: "document",
        source: Source.Order,
        upload,
      })) || [];
    await mutateAsync(
      mapCreateOrder({ ...data, files: [...imageIds, ...documentIds] }),
      {
        onError: () => {
          imageIds?.map((imageId) => {
            deleteFile({ id: imageId });
          });
        },
      },
    );
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-content-wrapper">
          <BreadCrumb title="Thêm đơn hàng" pageTitle="Đơn hàng" isBack />
          <Row>
            <Col
              md={{
                offset: 2,
                size: 8,
              }}
              xl={{
                offset: 3,
                size: 6,
              }}
            >
              {isLoading ? (
                <Loading />
              ) : (
                <Card>
                  <CardBody>
                    <OrderForm
                      onSubmit={handleSubmit}
                      defaultValues={mapDefaultOrder(order, true)}
                      submitting={isPending || isUploading}
                      isDuplicate
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default DuplicateOrder;
