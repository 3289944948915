import {
  CreateMasterDataArgs,
  MasterData,
  MasterDataType,
  UpdateMasterDataArgs,
} from "app/services/masterData";
import { WithID } from "app/models";
import { MasterDataFormData } from "./components";
import { CommonProperties } from "app/services/types";

export const mapper = ({
  name,
  description,
  colorCode,
  type,
}: MasterDataFormData): CommonProperties<
  CreateMasterDataArgs,
  UpdateMasterDataArgs
> => {
  return {
    name,
    description: description ?? null,
    type: type?.value as MasterDataType,
    colorCode: colorCode,
  };
};

export const mapCreateMasterData = (
  data: MasterDataFormData,
): CreateMasterDataArgs => {
  return mapper(data);
};

export const mapUpdateMasterData = ({
  id,
  ...data
}: WithID<MasterDataFormData>): UpdateMasterDataArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultMasterData = ({
  name,
  colorCode,
  description,
  masterType,
}: Partial<MasterData> = {}): MasterDataFormData => {
  return {
    name: name ?? "",
    description: description ?? "",
    type: masterType
      ? {
          value: masterType.key,
          label: masterType.name,
        }
      : null,
    colorCode: colorCode?.toLowerCase() ?? "#646a73",
  };
};
