import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { User } from "./types";

export const CREATE_USER_URL = "/api/users";

export enum CreateUserError {}

export const createUserErrorMessages = {};

export interface CreateUserArgs {
  username: ID;
  password: string;
  employeeId: ID | null;
  roleId: ID;
  status: number;
}

export type CreateUserResponse = Response<User>;

export const createUser = (args: CreateUserArgs) => {
  return httpClient.post<CreateUserResponse>(CREATE_USER_URL, args);
};

export const useCreateUserMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateUserResponse>,
      AxiosError<any>,
      CreateUserArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateUserResponse>,
    AxiosError<any>,
    CreateUserArgs
  >({
    mutationFn: createUser,
    mutationKey: [CREATE_USER_URL],
    ...opts,
  });
