import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { CurrentUser } from "./types";

export const RETRIEVE_CURRENT_PROFILE = "/api/auth/profile";

export enum CurrentProfileError {
  AccountNotFound = "ACCOUNT_NOT_FOUND",
}

export const currentProfileErrorMessages = {
  [CurrentProfileError.AccountNotFound]: "Tài khoản không tồn tại",
};

export type RetrieveCurrentProfileResponse = Response<CurrentUser>;

export const retrieveCurrentProfile = () => {
  return httpClient.get<RetrieveCurrentProfileResponse>(
    RETRIEVE_CURRENT_PROFILE,
  );
};

export const useRetrieveCurrentProfile = (
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveCurrentProfileResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveCurrentProfileResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_CURRENT_PROFILE],
    queryFn: retrieveCurrentProfile,
    ...opts,
  });
