import {
  Column,
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { Row, Spinner, Table } from "reactstrap";

export interface DataTableProps<T> {
  total: number;
  loading?: boolean;
  columns: ColumnDef<T>[];
  data: T[];
  page?: number;
  limit?: number;
  setPage?: (page: number) => void;
  setLimit?: (limit: number) => void;
  isHiddenPagination?: boolean;
  onRowClick?: (data: T) => void;
  isTreeTable?: boolean;
  getSubRows?: (row: T) => T[];
}

export const DataTable = <T,>({
  loading = false,
  total = 0,
  data = [],
  columns,
  setPage,
  setLimit,
  page = DEFAULT_PAGE,
  limit = DEFAULT_LIMIT_PAGE,
  isHiddenPagination = false,
  isTreeTable = false,
  onRowClick,
  getSubRows,
}: DataTableProps<T>) => {
  const { limitPageText, noDataTableText } = useAppTranslation();
  const getCommonPinningStyles = (column: Column<T>): CSSProperties => {
    const isPinned = column.getIsPinned();

    return {
      paddingLeft: 0,
      background: "white",
      left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
      right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
      opacity: isPinned ? 0.95 : 1,
      position: isPinned ? "sticky" : "relative",
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
    };
  };

  const cols: ColumnDef<T>[] = isTreeTable
    ? columns.map((column, index) => {
        if (index === 0) {
          return {
            ...column,
            header: (props) => (
              <div>
                <i
                  className="cursor-pointer me-1"
                  onClick={table.getToggleAllRowsExpandedHandler()}
                >
                  {table.getIsAllRowsExpanded() ? (
                    <i className="ri-arrow-down-s-line fs-16" />
                  ) : (
                    <i className=" ri-arrow-right-s-line fs-16" />
                  )}
                </i>
                {column.header ? (
                  <>
                    {typeof column.header === "function"
                      ? column.header(props)
                      : column.header}
                  </>
                ) : (
                  "-"
                )}
              </div>
            ),
            cell: ({ row, getValue, ...props }) => (
              <div
                style={{
                  paddingLeft: !row.getCanExpand()
                    ? `calc(${row.depth * 1}rem + 1.25rem)`
                    : `${row.depth * 1}rem`,
                }}
              >
                {row.getCanExpand() ? (
                  <i
                    className="cursor-pointer me-1"
                    onClick={row.getToggleExpandedHandler()}
                  >
                    {row.getIsExpanded() ? (
                      <i className="ri-arrow-down-s-line fs-16" />
                    ) : (
                      <i className=" ri-arrow-right-s-line fs-16" />
                    )}
                  </i>
                ) : null}
                {column.cell ? (
                  <>
                    {typeof column.cell === "function"
                      ? column.cell({ row, getValue, ...props })
                      : column.cell}
                  </>
                ) : (
                  getValue<boolean>()
                )}
              </div>
            ),
          } as ColumnDef<T>;
        }
        return column;
      }) || []
    : columns;

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    data: data,
    columns: cols,
    manualPagination: true,
    rowCount: total,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: (updater) => {
      const newState =
        typeof updater === "function"
          ? updater(table.getState().pagination)
          : updater;
      setPage?.(newState.pageIndex + 1);
      setLimit?.(newState.pageSize);
      return newState;
    },
    onExpandedChange: setExpanded,
    getSubRows,
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      columnPinning: {
        right: ["action"],
      },
      pagination: {
        pageIndex: page - 1,
        pageSize: limit,
      },
      expanded,
    },
  });

  return (
    <>
      <div className="table-container">
        <Table hover style={{ maxHeight: 300 }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup: any) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      minWidth: `${header.getSize()}px`,
                      ...getCommonPinningStyles(header.column),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <React.Fragment>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </React.Fragment>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length && !loading
              ? table.getRowModel().rows.map((row: any) => {
                  return (
                    <tr
                      key={row.id}
                      className={onRowClick ? "cursor-pointer" : ""}
                      onClick={() => {
                        onRowClick?.(row.original);
                      }}
                    >
                      {row.getVisibleCells().map((cell: any) => {
                        return (
                          <td
                            key={cell.id}
                            style={{ ...getCommonPinningStyles(cell.column) }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
        {loading ? (
          <div className="no-data-cell">
            <Spinner color="primary" />
          </div>
        ) : null}
        {table.getRowModel().rows.length === 0 && !loading ? (
          <div className="no-data-cell">{noDataTableText}</div>
        ) : null}
      </div>
      {isHiddenPagination ? null : (
        <Row className="align-items-center pb-4 pt-3 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              {limitPageText.show}
              <span className="fw-semibold ms-1">
                {table.getState().pagination.pageSize > (total || 0)
                  ? total
                  : table.getState().pagination.pageSize}
              </span>{" "}
              {limitPageText.in} <span className="fw-semibold">{total}</span>{" "}
              {limitPageText.result}
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <li
                className={
                  !table.getCanPreviousPage()
                    ? "page-item disabled"
                    : "page-item"
                }
              >
                <Link to="#" className="page-link" onClick={table.previousPage}>
                  <i className="ri-arrow-left-s-line" />
                </Link>
              </li>
              {table.getPageOptions().map((item: any, key: number) => (
                <React.Fragment key={key}>
                  <li className="page-item">
                    <Link
                      to="#"
                      className={
                        table.getState().pagination.pageIndex === item
                          ? "page-link active"
                          : "page-link"
                      }
                      onClick={() => table.setPageIndex(item)}
                    >
                      {item + 1}
                    </Link>
                  </li>
                </React.Fragment>
              ))}
              <li
                className={
                  !table.getCanNextPage() ? "page-item disabled" : "page-item"
                }
              >
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    table.nextPage();
                  }}
                >
                  <i className="ri-arrow-right-s-line" />
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </>
  );
};
