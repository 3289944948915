import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfirmationProvider } from "app/components/molecules/ConfirmationProvider";
import { AuthProvider } from "app/components/organisms/AuthProvider";
import i18n from "./i18n";
import { FC, PropsWithChildren, Suspense, useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRegisterSW } from "virtual:pwa-register/react";
import { NewUpdateModal } from "app/components/organisms/NewUpdateModal";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOnLogout, setIsOnLogout] = useState(true);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);

  const onAuthenticated = async () => {
    setIsOnLogout(false);
  };

  const onLogout = () => {
    if (!isOnLogout) {
      setIsOnLogout(true);
    }
  };

  const intervalMS = 60 * 1000;

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW: (_, r) => {
      r &&
        setInterval(() => {
          r.update();
        }, intervalMS);
    },
    onRegisterError: (error) => {
      console.log("Error during service worker registration:", error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  useEffect(() => {
    if (offlineReady || needRefresh) {
      setIsOpenUpdateModal(true);
    }
  }, [needRefresh]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <I18nextProvider i18n={i18n}>
        <AuthProvider onAuthenticated={onAuthenticated} onLogout={onLogout}>
          <ToastContainer
            hideProgressBar
            position="top-right"
            autoClose={3000}
            theme="colored"
          />
          <NewUpdateModal
            open={isOpenUpdateModal}
            onClose={close}
            onConfirm={() => updateServiceWorker(true)}
            onToggle={() => setIsOpenUpdateModal((prev) => !prev)}
          />
          <ConfirmationProvider>
            <Suspense fallback={"Loading"}>{children}</Suspense>
          </ConfirmationProvider>
        </AuthProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
};

export default AppProvider;
