import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Vendor } from "./types";
import { Response } from "../types";

export const RETRIEVE_VENDOR_BY_ID_URL = (id: ID) => `/api/vendors/${id}`;

export type RetrieveVendorByIdResponse = Response<Vendor>;

export type RetrieveVendorByIdArgs = {
  id: ID;
};

export const retrieveVendorById = ({ id }: RetrieveVendorByIdArgs) => {
  return httpClient.get<RetrieveVendorByIdResponse>(
    RETRIEVE_VENDOR_BY_ID_URL(id),
  );
};

export const useRetrieveVendorByIdQuery = (
  retrieveVendorsArgs: RetrieveVendorByIdArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveVendorByIdResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveVendorByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_VENDOR_BY_ID_URL(retrieveVendorsArgs.id)],
    queryFn: () => retrieveVendorById(retrieveVendorsArgs),
    ...opts,
  });
