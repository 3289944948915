import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { AvatarUpload } from "app/components/organisms/AvatarUpload/AvatarUpload";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { FilePreview } from "app/models";
import MasterDataInfiniteSelect from "app/modules/masterData/components/MasterDataInfinitySelect/MasterDataInfinitySelect";
import { MasterData, MasterDataType } from "app/services/masterData";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface ProductFormProps {
  defaultValues?: ProductFormData;
  onSubmit: (data: ProductFormData) => void;
  submitting?: boolean;
}

export interface BomProductItemFormData {
  products: OptionSelect<ID> | null;
  quantity: number | null;
  unit?: any;
  type?: any;
  group?: any;
  id?: ID | null;
}

export interface ProductFormData {
  code: string;
  nameEn: string;
  nameVn: string;
  type: OptionSelect<ID> | null;
  group: OptionSelect<ID> | null;
  unit: OptionSelect<ID> | null;
  countryOfOrigin: string;
  description: string;
  // Product size
  lengthCm: number | null;
  widthCm: number | null;
  heightCm: number | null;
  // Packaging síze
  packagingLengthCm: number | null;
  packagingWidthCm: number | null;
  packagingHeightCm: number | null;
  seatSizeCm: string;
  weightKg: number | null;
  boxQuantity: number | null;
  color: string;
  images: FilePreview[];
}

export const ProductForm: FC<ProductFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
}) => {
  const { productText, buttonText } = useAppTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<ProductFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        code: yupSchema.stringRequired("product.error.productCodeRequired"),
        nameEn: yupSchema.stringNotRequired(),
        nameVn: yupSchema.stringRequired("product.error.nameVnRequired"),
        type: yupSchema.objectNotRequired(),
        group: yupSchema.objectNotRequired(),
        unit: yupSchema.objectRequired("product.error.unitRequired"),
        countryOfOrigin: yupSchema.stringRequired(
          "product.error.countryOfOriginRequired",
        ),
        description: yupSchema.stringNotRequired(),
        lengthCm: yupSchema.numberMoreThanNotRequired(
          0,
          "product.error.lengthCmMoreThanZero",
        ),
        widthCm: yupSchema.numberMoreThanNotRequired(
          0,
          "product.error.widthCmMoreThanZero",
        ),
        heightCm: yupSchema.numberMoreThanNotRequired(
          0,
          "product.error.heightCmMoreThanZero",
        ),
        packagingLengthCm: yupSchema.numberMoreThanNotRequired(
          0,
          "product.error.lengthCmMoreThanZero",
        ),
        packagingWidthCm: yupSchema.numberMoreThanNotRequired(
          0,
          "product.error.widthCmMoreThanZero",
        ),
        packagingHeightCm: yupSchema.numberMoreThanNotRequired(
          0,
          "product.error.heightCmMoreThanZero",
        ),
        seatSizeCm: yupSchema.stringNotRequired(),
        color: yupSchema.stringNotRequired(),
        weightKg: yupSchema.numberMoreThanNotRequired(
          0,
          "product.error.weightKgMoreThanZero",
        ),
        boxQuantity: yupSchema.numberNotRequired(),
        images: yupSchema.fileNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const handleSetValue =
    (name: keyof ProductFormData) => (data: MasterData) => {
      setValue(
        name,
        {
          value: data.id,
          label: data.name,
        },
        { shouldDirty: true },
      );
    };

  return (
    <Form onSubmit={submit} id="product-form">
      <Row>
        <h5 className="py-2">{productText.detail.generalInfomation}</h5>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="images" className="form-label">
              {productText.field.avatar}
            </FormLabel>
            <Controller
              name="images"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <AvatarUpload
                    value={field.value || []}
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="code" className="form-label">
              {productText.field.code}
            </FormLabel>
            <Controller
              name="code"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="code"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="nameVn" className="form-label">
              {productText.field.nameVn}
            </FormLabel>
            <Controller
              name="nameVn"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="nameVn"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="nameEn" className="form-label">
              {productText.field.nameEn}
            </FormLabel>
            <Controller
              name="nameEn"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="nameEn"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="type" className="form-label">
              {productText.field.type}
            </FormLabel>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="type"
                    name="type"
                    inputId="type"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.ProductType }}
                    onCreateSuccess={handleSetValue("type")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="group" className="form-label">
              {productText.field.group}
            </FormLabel>
            <Controller
              name="group"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="group"
                    name="group"
                    inputId="group"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.ProductGroup }}
                    onCreateSuccess={handleSetValue("group")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="unit" className="form-label">
              {productText.field.unit}
            </FormLabel>
            <Controller
              name="unit"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="unit"
                    name="unit"
                    inputId="unit"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.ProductUnit }}
                    onCreateSuccess={handleSetValue("unit")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel
              required
              htmlFor="countryOfOrigin"
              className="form-label"
            >
              {productText.field.countryOfOrigin}
            </FormLabel>
            <Controller
              name="countryOfOrigin"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="countryOfOrigin"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="description" className="form-label">
              {productText.field.description}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="textarea"
                    className="form-control"
                    id="description"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <h5 className="py-2">{productText.detail.detailInfomation}</h5>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="lengthCm" className="form-label">
              {productText.field.lengthCm}
            </FormLabel>
            <Controller
              name="lengthCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="lengthCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="widthCm" className="form-label">
              {productText.field.widthCm}
            </FormLabel>
            <Controller
              name="widthCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="widthCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="heightCm" className="form-label">
              {productText.field.heightCm}
            </FormLabel>
            <Controller
              name="heightCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="heightCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="seatSizeCm" className="form-label">
              {productText.field.seatSizeCm}
            </FormLabel>
            <Controller
              name="seatSizeCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="seatSizeCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="weightKg" className="form-label">
              {productText.field.weightKg}
            </FormLabel>
            <Controller
              name="weightKg"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="weightKg"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="color" className="form-label">
              {productText.field.color}
            </FormLabel>
            <Controller
              name="color"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="color"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="packagingLengthCm" className="form-label">
              {productText.field.packagingLengthCm}
            </FormLabel>
            <Controller
              name="packagingLengthCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="packagingLengthCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="packagingWidthCm" className="form-label">
              {productText.field.packagingWidthCm}
            </FormLabel>
            <Controller
              name="packagingWidthCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="packagingWidthCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="packagingHeightCm" className="form-label">
              {productText.field.packagingHeightCm}
            </FormLabel>
            <Controller
              name="packagingHeightCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="packagingHeightCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="boxQuantity" className="form-label">
              {productText.field.boxQuantity}
            </FormLabel>
            <Controller
              name="boxQuantity"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="boxQuantity"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
