import { Button } from "app/components/molecules/Button";
import React, { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

export interface BreadCrumbProps {
  title?: ReactNode;
  pageTitle?: ReactNode;
  isBack?: boolean;
}

const BreadCrumb = ({ title, pageTitle, isBack }: BreadCrumbProps) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-center gap-2">
              {isBack ? (
                <Button
                  color=""
                  className="btn-icon"
                  style={{ height: 18, width: 18 }}
                  onClick={() => navigate(-1)}
                >
                  <i className="ri-arrow-left-s-line fs-24" />
                </Button>
              ) : null}
              <h4 className="mb-sm-0">{title}</h4>
            </div>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="#">{pageTitle}</Link>
                </li>
                <li className="breadcrumb-item active">{title}</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
