import { useAppTranslation } from "app/hooks";
import { useRetrieveCustomerById } from "app/modules/customer/hooks";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

export interface DetailCustomerHeaderProps {}

export const DetailCustomerHeader: FC<DetailCustomerHeaderProps> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { buttonText } = useAppTranslation();
  const { customer } = useRetrieveCustomerById(
    { id: id as string },
    { enabled: !!id },
  );

  const avatar = false;

  return (
    <Row className="g-4">
      <div className="col-auto">
        <div className="avatar-lg d-flex justify-content-center align-items-center">
          {avatar ? (
            <img
              src={avatar}
              alt="user-img"
              className="img-thumbnail rounded-circle"
            />
          ) : (
            <i className="ri-account-circle-fill text-body"></i>
          )}
        </div>
      </div>

      <Col>
        <div className="p-2">
          <h3 className="mb-1">{customer?.customerName}</h3>
          <p className="text-muted">{customer?.customerCode}</p>
          <div className="hstack text-muted gap-1">
            <div className="me-2">
              <i className="ri-mail-line me-1 fs-16 align-bottom text-body"></i>
              {customer?.email}
            </div>
            <div className="me-2">
              <i className="ri-phone-line me-1 fs-16 align-bottom text-body"></i>
              {customer?.phoneNumber}
            </div>
          </div>
        </div>
      </Col>
      <Col className="col-lg-auto order-last order-lg-0">
        <Row className="text text-center">
          <Col className="py-2">
            <Button
              color="primary"
              onClick={() => navigate(`/customer/${id}/update`)}
            >
              {buttonText.edit}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
