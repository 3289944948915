import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { APIError, APISuccess, Response } from "../types";

export const LOGIN_POS_URL = "/api/auth/login";

export type LoginPosResponse = Response<{
  accessToken: string;
  refreshToken: string;
}>;

export type LoginPosArgs = {
  username: string;
  password: string;
  rememberMe?: boolean;
};

export enum LoginPosError {
  InvalidLoginPosInfo = "User Not Found",
  HaveNotPermission = "HAVE_NOT_PERMISSION",
  Unauthorized = "Unauthorized",
}

export const loginPosErrorMessages = {
  [LoginPosError.InvalidLoginPosInfo]: "Thông tin đăng nhập không chính xác",
  [LoginPosError.HaveNotPermission]: "Bạn không có quyền truy cập",
  [LoginPosError.Unauthorized]: "Thông tin đăng nhập không chính xác",
};

export const loginPos = (loginPosArgs: LoginPosArgs) => {
  return httpClient.post<LoginPosResponse>(LOGIN_POS_URL, loginPosArgs);
};

export const useLoginPosMutation = (
  opts?: UseMutationOptions<
    APISuccess<LoginPosResponse>,
    APIError,
    LoginPosArgs
  >,
) =>
  useMutation({ mutationFn: loginPos, mutationKey: [LOGIN_POS_URL], ...opts });
