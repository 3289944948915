import { Permission } from "app/services/permission";

export const permissonMappers: Record<Permission["key"], string> = {
  "user:read": "user-read",
  "user:create": "user-create",
  "user:update": "user-update",
  "user:delete": "user-delete",
  "role:read": "role-read",
  "role:create": "role-create",
  "role:update": "role-update",
  "role:delete": "role-delete",
};
