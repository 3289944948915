import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Customer } from "./types";

export const CREATE_CUSTOMER_URL = "/api/customers";

export enum CreateCustomerError {}

export const createCustomerErrorMessages = {};

export interface CreateCustomerArgs {
  customerCode: string | null;
  customerName: string;
  customerTypeId: ID | null;
  address: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  representative: string | null;
  representativeRole: string | null;
  paymentTerms: string | null;
  depositPercentage: number | null;
  paymentDueDays: number | null;
  notes: string | null;
  startDate: DateTimeString | null;
  customerStatusId: ID | null;
  assigneeId: ID | null;
}

export type CreateCustomerResponse = Response<Customer>;

export const createCustomer = (args: CreateCustomerArgs) => {
  return httpClient.post<CreateCustomerResponse>(CREATE_CUSTOMER_URL, args);
};

export const useCreateCustomerMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateCustomerResponse>,
      AxiosError<any>,
      CreateCustomerArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateCustomerResponse>,
    AxiosError<any>,
    CreateCustomerArgs
  >({
    mutationFn: createCustomer,
    mutationKey: [CREATE_CUSTOMER_URL],
    ...opts,
  });
