import {
  RetrieveJobTitleByIdArgs,
  useRetrieveJobTitleByIdQuery,
} from "app/services/jobTitle";

export const useRetrieveJobTitleById = (
  args: RetrieveJobTitleByIdArgs,
  opts: Parameters<typeof useRetrieveJobTitleByIdQuery>[1],
) => {
  const result = useRetrieveJobTitleByIdQuery(args, opts as any);
  return {
    jobTitle: result.data?.data.data,
    isLoadingJobTitle: result.isLoading,
    ...result,
  };
};
