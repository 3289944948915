import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  deletedJobTitleErrorMessages,
  RETRIEVE_JOB_TITLES_URL,
  useDeleteJobTitleMutation,
} from "app/services/jobTitle";

interface UseDeleteJobTitleProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDeleteJobTitle = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseDeleteJobTitleProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useDeleteJobTitleMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_JOB_TITLES_URL] });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          deletedJobTitleErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
