import { IntlConfig, createIntl, createIntlCache } from "react-intl";

const cache = createIntlCache();

const config: IntlConfig = {
  defaultLocale: "vi-Vn",
  locale: "vi-Vn",
};

export const intl = createIntl(config, cache);
