import { AUTH_ACCESS_TOKEN, AUTH_REFRESH_TOKEN } from "app/helpers";

export const tokenStorage = {
  accessTokenStorageKey: AUTH_ACCESS_TOKEN,
  refreshTokenStorageKey: AUTH_REFRESH_TOKEN,
  setAccessToken(accessToken: string) {
    try {
      localStorage.setItem(this.accessTokenStorageKey, accessToken);
    } catch (error) {
      console.warn(`setAccessToken error: ${JSON.stringify(error)}`);
    }
  },
  getAccessToken() {
    try {
      return localStorage.getItem(this.accessTokenStorageKey);
    } catch (error) {
      console.warn(`getAccessToken error: ${JSON.stringify(error)}`);
      return null;
    }
  },
  removeAccessToken() {
    try {
      localStorage.removeItem(this.accessTokenStorageKey);
    } catch (error) {
      console.warn(`removeAccessToken error: ${JSON.stringify(error)}`);
    }
  },
  setRefreshToken(refreshToken: string) {
    try {
      localStorage.setItem(this.refreshTokenStorageKey, refreshToken);
    } catch (error) {
      console.warn(`setRefreshToken error: ${JSON.stringify(error)}`);
    }
  },
  getRefreshToken() {
    try {
      return localStorage.getItem(this.refreshTokenStorageKey);
    } catch (error) {
      console.warn(`getRefreshToken error: ${JSON.stringify(error)}`);
      return null;
    }
  },
  removeRefreshToken() {
    try {
      localStorage.removeItem(this.refreshTokenStorageKey);
    } catch (error) {
      console.warn(`removeRefreshToken error: ${JSON.stringify(error)}`);
    }
  },
};
