import { useRetrieveJobTitleById } from "app/modules/jobTitle/hooks";
import { JobTitle } from "app/services/jobTitle";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { JobTitleForm, JobTitleFormData } from "./JobTitleForm";

export interface JobTitleFormModalProps {
  data?: JobTitle;
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: JobTitleFormData) => void;
  submitting?: boolean;
  edit?: boolean;
}

export const JobTitleFormModal: FC<JobTitleFormModalProps> = ({
  data,
  onToggle,
  open = false,
  onSubmit,
  submitting,
  edit = false,
}) => {
  const title = edit ? "Sửa chức vụ" : "Thêm chức vụ";
  const { jobTitle, isLoading } = useRetrieveJobTitleById(
    {
      id: data?.id as string,
    },
    { enabled: edit && !!data?.id },
  );

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {!isLoading ? (
          <JobTitleForm
            onSubmit={onSubmit}
            onToggle={onToggle}
            defaultValues={
              edit
                ? {
                    name: jobTitle?.name || "",
                  }
                : {
                    name: "",
                  }
            }
            submitting={submitting}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};
