import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en.json";
import translationVN from "./locales/vn.json";
import translationENGenerate from "./locales/vnGenerate.json";
import translationVNGenerate from "./locales/enGenerate.json";

// the translations
const resources = {
  en: {
    translation: { ...translationEN, ...translationENGenerate },
  },
  vn: {
    translation: { ...translationVN, ...translationVNGenerate },
  },
};

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "vn");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "vn",
    fallbackLng: "vn", // use en if detected lng is not available

    keySeparator: ".", // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
