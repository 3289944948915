import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { intl } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import { OrderAction, OrderActionDropdown } from "app/modules/order/components";
import { useDeleteOrder, useRetrieveOrders } from "app/modules/order/hooks";
import { Order } from "app/services/order";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

interface ListOrderProps {}

const ListOrder: FC<ListOrderProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { orderText, confirmText } = useAppTranslation();

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: 1,
      initialLimit: 20,
      initialSearchString: "",
    });

  const { orders, isLoadingOrders } = useRetrieveOrders({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteOrder } = useDeleteOrder();

  const handleActionClick = (action: OrderAction, data?: Order) => {
    switch (action) {
      case "edit":
        if (data) {
          navigate(`/order/${data.id}/update`);
        }
        break;
      case "duplicate":
        if (data) {
          navigate(`/order/${data.id}/duplicate`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/order/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(orderText.title),
            description: confirmText.deleteContent(orderText.title),
            onConfirm: () => deleteOrder({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Order>[] = useMemo(
    () => [
      {
        header: orderText.field.orderCode,
        accessorKey: "orderCode",
        cell: ({ row }) => row.original.orderCode || "-",
      },
      {
        header: orderText.field.customer,
        accessorKey: "customer",
        cell: ({ row }) => row.original.customer?.customerName || "-",
      },
      {
        header: orderText.field.orderDate,
        accessorKey: "orderDate",
        cell: ({ row }) =>
          row.original.orderDate
            ? intl.formatDate(new Date(row.original.orderDate))
            : "-",
      },
      {
        header: orderText.field.expectedDeliveryDate,
        accessorKey: "expectedDeliveryDate",
        cell: ({ row }) =>
          row.original.expectedDeliveryDate
            ? intl.formatDate(new Date(row.original.expectedDeliveryDate))
            : "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <OrderActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [orderText],
  );

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={orderText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <DebouncedInput
                  name="list-order-search"
                  value={searchString || ""}
                  onChange={(value) => {
                    setSearchString(String(value));
                  }}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => navigate("/order/new")}
              >
                {orderText.add}
              </Button>
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<Order>
            columns={columns}
            data={orders?.result || []}
            loading={isLoadingOrders}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={orders?.total || 0}
            onRowClick={(row) => handleActionClick("detail", row)}
          />
        </ListPage.Main>
      </ListPage>
    </React.Fragment>
  );
};

export default ListOrder;
