import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { Source } from "app/models";
import { getUploadedFileIds } from "app/modules/file/helpers";
import { ProductForm, ProductFormData } from "app/modules/product/components";
import {
  useRetrieveProductById,
  useUpdateProduct,
} from "app/modules/product/hooks";
import {
  mapDefaultProduct,
  mapUpdateProduct,
} from "app/modules/product/mapper";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateProductProps {}

const UpdateProduct: FC<UpdateProductProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { productText } = useAppTranslation();

  const { product, isLoading } = useRetrieveProductById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateProduct({
    id,
    onSuccess: () => {
      navigate(-1);
    },
  });

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const { mutateAsync: deleteFile } = useDeleteFile();

  const handleSubmit = async (data: ProductFormData) => {
    const imageIds =
      (await getUploadedFileIds({
        data: data.images,
        fileKey: "image",
        source: Source.Product,
        upload,
      })) || [];
    const imageId = imageIds[0];
    await mutateAsync(
      mapUpdateProduct({ ...data, id: id as string, imageId }),
      {
        onError: () => {
          if (imageIds[0]) {
            deleteFile({ id: imageId });
          }
        },
      },
    );
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={productText.edit}
          pageTitle={productText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <ProductForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultProduct(product)}
              submitting={isPending || isUploading}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateProduct;
