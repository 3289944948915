import {
  AttachmentAction,
  AttachmentActionDropdown,
} from "app/components/molecules/AttachmentActionDropdown";
import { intl } from "app/helpers";
import { mapSource } from "app/helpers/utils";
import { FileDTO } from "app/models";
import { FC } from "react";
import { Badge, Col } from "reactstrap";

export interface SidebarImageGridItemProps {
  image: FileDTO;
  onActionClick: (action: AttachmentAction, data?: FileDTO) => void;
}

export const SidebarImageGridItem: FC<SidebarImageGridItemProps> = ({
  image,
  onActionClick,
}) => {
  return (
    <Col xs={6}>
      <img
        style={{
          height: 90,
          objectFit: "cover",
          overflowClipMargin: "unset",
        }}
        onClick={() => onActionClick("view", image)}
        className="w-100 rounded-3 cursor-pointer"
        src={image.attachmentUrl}
        alt={image.fileName}
      />
      <Badge color="primary">{mapSource(image.moduleType)}</Badge>
      <div className="d-flex justify-content-between">
        <div className="text-truncate">{image.fileName}</div>
        <AttachmentActionDropdown data={image} onAction={onActionClick} />
      </div>
      <p className="fs-10 mb-0">{intl.formatDate(image.createdAt)}</p>
    </Col>
  );
};
