import BreadCrumb from "Components/Common/BreadCrumb";
import classnames from "classnames";
import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

//import images
import { FormInput } from "app/components/molecules/FormInput";
import { useAuth } from "app/components/organisms/AuthProvider";
import { useAppTranslation } from "app/hooks";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { ChangeProfileForm } from "./ChangeProfileForm";
import avatar1 from "/src/assets/images/users/avatar-1.jpg";

const Settings = () => {
  const { authText } = useAppTranslation();
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState("2");
  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={authText.userInfo} pageTitle="Kingston" />
          <Row>
            <Col xxl={3}>
              <Card className="card-bg-fill">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      <img
                        src={avatar1}
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <FormInput
                          id="profile-img-file-input"
                          type="file"
                          className="profile-img-file-input"
                        />
                        <Label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </Label>
                      </div>
                    </div>
                    <h5 className="fs-16 mb-1">
                      {currentUser?.employee?.fullName || "-"}
                    </h5>
                    <p className="text-muted mb-0">
                      {currentUser?.employee?.jobTitle.name || "-"}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xxl={9}>
              <Card>
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <i className="fas fa-home"></i>
                        Thông tin cá nhân
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          tabChange("2");
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        {authText.changePassword}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    {/* <TabPane tabId="1">
                      <ChangeProfileForm />
                    </TabPane> */}

                    <TabPane tabId="2">
                      <ChangePasswordForm />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
