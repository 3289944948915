import { CreateUserArgs, UpdateUserArgs, User } from "app/services/user";
import { WithID } from "app/models";
import { UserFormData } from "./components";
import { CommonProperties } from "app/services/types";

export const mapper = ({
  password,
  role,
  username,
  employeeId,
}: UserFormData & { employeeId: ID }): CommonProperties<
  CreateUserArgs,
  UpdateUserArgs
> => {
  return {
    password,
    username,
    employeeId,
    roleId: role?.value || "",
  };
};

export const mapCreateUser = (
  data: UserFormData & { employeeId: ID },
): CreateUserArgs => {
  return { status: 1, ...mapper(data) };
};

export const mapUpdateUser = ({
  id,
  ...data
}: WithID<UserFormData & { employeeId: ID }>): UpdateUserArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultUser = ({
  username,
  password,
  role,
}: Partial<User> = {}): UserFormData => {
  return {
    username: username || "",
    password: password || "",
    role: role
      ? {
          value: role.id,
          label: role.name,
        }
      : null,
  };
};
