import { PagingData } from "./../types";
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { Role } from "./types";

export const RETRIEVE_ROLES_URL = "/api/roles";

export interface RetrieveRolesArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveRolesResponse = Response<PagingData<Role[]>>;

export const retrieveRoles = (args: RetrieveRolesArgs) => {
  return httpClient.get<RetrieveRolesResponse>(RETRIEVE_ROLES_URL, {
    params: args,
  });
};

export const useRetrieveRolesQuery = (
  retrieveRolesArgs: RetrieveRolesArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveRolesResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveRolesResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_ROLES_URL, retrieveRolesArgs],
    queryFn: () => retrieveRoles(retrieveRolesArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
