import { useAppTranslation } from "app/hooks";
import { Employee } from "app/services/employee";
import isEmpty from "lodash/isEmpty";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type EmployeeAction = "edit" | "delete" | "create-user" | "detail";

export interface EmployeeActionDropdownProps {
  data?: Employee;
  onAction: (action: EmployeeAction, data?: Employee) => void;
}

export const EmployeeActionDropdown: FC<EmployeeActionDropdownProps> = ({
  data,
  onAction,
}) => {
  const { buttonText, employeeText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: EmployeeAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  const isHaveUser = !isEmpty(data?.user);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle tag="button" className="btn py-0 px-2 m-0">
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-start mr-2">
        <DropdownItem className="p-0" onClick={handleAction("detail")}>
          <div className="dropdown-item">
            <i className=" ri-arrow-right-s-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.detail}</span>
          </div>
        </DropdownItem>
        {!isHaveUser ? (
          <DropdownItem className="p-0" onClick={handleAction("create-user")}>
            <div className="dropdown-item">
              <i className="ri-user-add-line text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">{employeeText.addAccount}</span>
            </div>
          </DropdownItem>
        ) : null}
        <DropdownItem className="p-0" onClick={handleAction("edit")}>
          <div className="dropdown-item">
            <i className="ri-pencil-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.edit}</span>
          </div>
        </DropdownItem>
        <DropdownItem className="p-0" onClick={handleAction("delete")}>
          <div className="dropdown-item">
            <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.delete}</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
