import { useAppTranslation } from "app/hooks";
import { Role } from "app/services/role";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useRetrieveRoleById } from "../../hooks";
import { RoleForm, RoleFormData } from "./RoleForm";

export interface RoleFormModalProps {
  data?: Role;
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: RoleFormData) => void;
  submitting?: boolean;
  edit?: boolean;
}

export const RoleFormModal: FC<RoleFormModalProps> = ({
  data,
  onToggle,
  open = false,
  onSubmit,
  submitting,
  edit = false,
}) => {
  const { roleText } = useAppTranslation();
  const title = edit ? roleText.edit : roleText.add;
  const { role, isLoading } = useRetrieveRoleById(
    {
      id: data?.id as string,
    },
    { enabled: edit && !!data?.id },
  );

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {!isLoading ? (
          <RoleForm
            onSubmit={onSubmit}
            onToggle={onToggle}
            defaultValues={
              edit
                ? {
                    name: role?.name || "",
                    description: role?.description || "",
                    permission: role?.permissions.map((item) => item.id) || [],
                  }
                : {
                    name: "",
                    description: "",
                    permission: [],
                  }
            }
            submitting={submitting}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};
