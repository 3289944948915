import { useMemo } from "react";

export const useGetEnableSubmitButton = ({
  isDirty,
  isValid,
  submitting,
}: {
  isDirty?: boolean;
  isValid?: boolean;
  submitting?: boolean;
}) => {
  return useMemo(
    () => isDirty && isValid && !submitting,
    [isDirty, isValid, submitting],
  );
};
