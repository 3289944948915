import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "app/components/organisms/DataTable";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { BomProduct, BomProductItem } from "app/services/bomProduct";
import { FC, useMemo } from "react";

export interface DetailBomProductItemsProps {}

const DetailBomProductItems: FC<DetailBomProductItemsProps> = () => {
  const { bomProductText, productText } = useAppTranslation();

  const { data: bomProduct, loading } = useDetailPageContext<BomProduct>();

  const bomProductItems = bomProduct?.product;

  const columns: ColumnDef<BomProductItem>[] = useMemo(
    () => [
      {
        header: productText.field.code,
        accessorKey: "productCode",
        cell: ({ row }) => row.original.code || "-",
      },
      {
        header: productText.field.name,
        accessorKey: "productName",
        cell: ({ row }) => row.original.nameVn || "-",
      },
      {
        header: productText.field.type,
        accessorKey: "productType",
        cell: ({ row }) => row.original.productType?.name || "-",
      },
      {
        header: productText.field.group,
        accessorKey: "productGroup",
        cell: ({ row }) => row.original.productGroup?.name || "-",
      },
      {
        header: productText.field.unit,
        accessorKey: "productUnit",
        cell: ({ row }) => row.original.productUnit?.name || "-",
      },
      {
        header: bomProductText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
    ],
    [bomProductText, productText],
  );

  return (
    <div className="p-3 py-0 h-100 d-flex flex-column">
      <DataTable<BomProductItem>
        columns={columns}
        data={bomProductItems ? [bomProductItems] : []}
        total={1}
        loading={loading}
        isHiddenPagination
      />
    </div>
  );
};

export default DetailBomProductItems;
