import { PagingData } from "./../types";
// src/services/retrievePermission.tsx
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { Permission } from "./types";

export const RETRIEVE_PERMISSIONS_URL = "/api/permissions";

export type RetrievePermissionsResponse = Response<Permission[]>;

export const retrievePermissions = () => {
  return httpClient.get<RetrievePermissionsResponse>(RETRIEVE_PERMISSIONS_URL);
};

export const useRetrievePermissionsQuery = (
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrievePermissionsResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrievePermissionsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PERMISSIONS_URL],
    queryFn: retrievePermissions,
    placeholderData: keepPreviousData,
    ...opts,
  });
