import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Employee, EmployeeGender } from "./types";

export const UPDATE_EMPLOYEE_URL = (id: ID) => `/api/employees/${id}`;

export enum UpdateEmployeeError {}

export const updateEmployeeErrorMessages = {};

export type UpdateEmployeeResponse = Response<Employee>;

export type UpdateEmployeeArgs = {
  id: ID;
  employeeCode: string;
  departmentId: ID;
  jobTitleId: ID;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string;
  phone: string;
  taxCode: string | null;
  nationalId: string | null;
  gender: EmployeeGender | null;
  birthdate: DateTimeString | null;
  dateOfIssue: DateTimeString | null;
  placeOfIssue: string | null;
  permanentAddress: string | null;
  dateOfJoining: DateTimeString | null;
  dateOfLeaving: DateTimeString | null;
  status: number;
};

export const updateEmployee = ({ id, ...args }: UpdateEmployeeArgs) => {
  return httpClient.put<UpdateEmployeeResponse>(UPDATE_EMPLOYEE_URL(id), args);
};

export const useUpdateEmployeeMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateEmployeeResponse>,
      AxiosError<any>,
      UpdateEmployeeArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateEmployeeResponse>,
    AxiosError<any>,
    UpdateEmployeeArgs
  >({
    mutationFn: updateEmployee,
    mutationKey: [UPDATE_EMPLOYEE_URL],
    ...opts,
  });
