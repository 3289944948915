import { useAppTranslation, useRouteMatch } from "app/hooks";
import classnames from "classnames";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { DetailProductTabEnum } from "./DetailOrder";

export interface DetailOrderTabsProps {}

export const DetailOrderTabs: FC<DetailOrderTabsProps> = () => {
  const { orderText } = useAppTranslation();
  const { id } = useParams();
  const routeMatch = useRouteMatch([
    "/order/:id/general",
    "/order/:id/order-items",
  ]);

  const currentTab = routeMatch?.pattern?.path ?? DetailProductTabEnum.General;

  return (
    <div className="d-flex px-3">
      <Nav
        pills
        className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
      >
        <NavItem>
          <Link
            to={`/order/${id}/general`}
            className={classnames(
              {
                active: currentTab === DetailProductTabEnum.General,
              },
              "fs-14 nav-link",
            )}
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {orderText.detail.tab.general}
            </span>
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to={`/order/${id}/order-items`}
            className={classnames(
              {
                active: currentTab === DetailProductTabEnum.OrderItems,
              },
              "fs-14 nav-link",
            )}
          >
            <i className="ri-history-line d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {orderText.detail.tab.orderItems}
            </span>
          </Link>
        </NavItem>
      </Nav>
    </div>
  );
};
