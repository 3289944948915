import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Order } from "./types";

export const DELETE_ORDER_URL = (id: ID) => `/api/orders/${id}`;

export enum DeleteOrderError {}

export const deletedOrderErrorMessages = {};

export type DeleteOrderResponse = Response<Order>;

export type DeleteOrderArgs = {
  id: ID;
};

export const deleteOrder = ({ id }: DeleteOrderArgs) => {
  return httpClient.delete<DeleteOrderResponse>(DELETE_ORDER_URL(id));
};

export const useDeleteOrderMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteOrderResponse>,
    AxiosError<Response<null>>,
    DeleteOrderArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_ORDER_URL],
    mutationFn: (deletedOrderArgs: DeleteOrderArgs) =>
      deleteOrder(deletedOrderArgs),
    ...opts,
  });
};
