import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { FileRelationType, Source } from "app/models";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";

export const UPLOAD_MODULE_FILES_URL = "/api/attachments/relations";

export enum UploadModuleFilesError {}

export const uploadModuleFilesErrorMessages = {};

export interface UploadModuleFilesArgs {
  moduleType: Source;
  relationType: FileRelationType;
  moduleId: ID;
  fileIds: ID[];
}

export type UploadModuleFilesResponse = Response<unknown>;

export const uploadModuleFiles = (args: UploadModuleFilesArgs) => {
  return httpClient.post<UploadModuleFilesResponse>(
    UPLOAD_MODULE_FILES_URL,
    args,
  );
};

export const useUploadModuleFilesMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UploadModuleFilesResponse>,
      AxiosError<any>,
      UploadModuleFilesArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UploadModuleFilesResponse>,
    AxiosError<any>,
    UploadModuleFilesArgs
  >({
    mutationFn: uploadModuleFiles,
    mutationKey: [UPLOAD_MODULE_FILES_URL],
    ...opts,
  });
