import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { DateInput } from "app/components/molecules/DateInput";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { ReactSelectCustom } from "app/components/molecules/ReactSelectCustom";
import { AvatarUpload } from "app/components/organisms/AvatarUpload";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { FilePreview } from "app/models";
import MasterDataInfiniteSelect from "app/modules/masterData/components/MasterDataInfinitySelect/MasterDataInfinitySelect";
import { EmployeeGender } from "app/services/employee";
import { MasterData, MasterDataType } from "app/services/masterData";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface EmployeeFormProps {
  defaultValues?: EmployeeFormData;
  onSubmit: (data: EmployeeFormData) => void;
  submitting?: boolean;
}

export interface EmployeeFormData {
  employeeCode: string;
  department: OptionSelect<ID> | null;
  jobTitle: OptionSelect<ID> | null;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  taxCode: string;
  nationalId: string;
  gender: OptionSelect<EmployeeGender> | null;
  birthdate: Date | null;
  dateOfIssue: Date | null;
  placeOfIssue: string;
  permanentAddress: string;
  dateOfJoining: Date | null;
  dateOfLeaving: Date | null;
  avatar: FilePreview[];
}

export const EmployeeForm: FC<EmployeeFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
}) => {
  const { employeeText, buttonText } = useAppTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid },
  } = useForm<EmployeeFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        department: yupSchema.objectRequired(
          "employee.error.departmentRequired",
        ),
        jobTitle: yupSchema.objectRequired("employee.error.jobTitleRequired"),
        employeeCode: yupSchema.stringRequired(
          "employee.error.employeeCodeRequired",
        ),
        firstName: yupSchema.stringRequired("employee.error.firstNameRequired"),
        middleName: yupSchema.stringNotRequired(),
        lastName: yupSchema.stringRequired("employee.error.lastNameRequired"),
        email: yupSchema.emailRequired(
          "employee.error.emailRequired",
          "employee.error.emailInvalid",
        ),
        phone: yupSchema.stringRequired("employee.error.phoneRequired"),
        taxCode: yupSchema.stringNotRequired(),
        nationalId: yupSchema.stringNotRequired(),
        gender: Yup.mixed<OptionSelect<EmployeeGender>>().nullable().defined(),
        birthdate: yupSchema.dateNotRequired(),
        dateOfIssue: yupSchema.dateNotRequired(),
        placeOfIssue: yupSchema.stringNotRequired(),
        permanentAddress: yupSchema.stringNotRequired(),
        dateOfJoining: yupSchema.dateNotRequired(),
        dateOfLeaving: yupSchema.dateNotRequired(),
        avatar: Yup.array().of(Yup.mixed<File>().defined()).defined(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const handleSetValue =
    (name: keyof EmployeeFormData) => (data: MasterData) => {
      setValue(
        name,
        {
          value: data.id,
          label: data.name,
        },
        { shouldDirty: true },
      );
    };

  return (
    <Form onSubmit={submit} id="employee-form">
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="avatar" className="form-label">
              {employeeText.field.avatar}
            </FormLabel>
            <Controller
              name="avatar"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <AvatarUpload
                    value={field.value || []}
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <FormLabel htmlFor="firstName" className="form-label">
              {employeeText.field.fullName}
            </FormLabel>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    id="firstName"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <FormLabel htmlFor="middleName" className="form-label">
              {employeeText.field.middleName}
            </FormLabel>
            <Controller
              name="middleName"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    id="middleName"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="lastName" className="form-label">
              {employeeText.field.lastName}
            </FormLabel>
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    id="lastName"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="employeeCode" className="form-label">
              {employeeText.field.employeeCode}
            </FormLabel>
            <Controller
              name="employeeCode"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    id="employeeCode"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="email" className="form-label">
              {employeeText.field.email}
            </FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    id="email"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="phone" className="form-label">
              {employeeText.field.phone}
            </FormLabel>
            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    id="phone"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="department" className="form-label">
              {employeeText.field.department}
            </FormLabel>
            <Controller
              name="department"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="department"
                    name="department"
                    inputId="department"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.Department }}
                    onCreateSuccess={handleSetValue("department")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="jobTitle" className="form-label">
              {employeeText.field.jobTitle}
            </FormLabel>
            <Controller
              name="jobTitle"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="jobTitle"
                    name="jobTitle"
                    inputId="jobTitle"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.JobTitle }}
                    onCreateSuccess={handleSetValue("jobTitle")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="gender" className="form-label">
              {employeeText.field.gender}
            </FormLabel>
            <Controller
              name="gender"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <ReactSelectCustom
                    value={field.value}
                    name={field.name}
                    inputId="gender"
                    onChange={field.onChange}
                    options={[
                      { label: "Nam", value: "male" },
                      { label: "Nữ", value: "female" },
                    ]}
                    placeholder="Chọn"
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="birthdate" className="form-label">
              {employeeText.field.dateOfBirth}
            </FormLabel>
            <Controller
              name="birthdate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="birthdate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date || null);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="taxCode" className="form-label">
              {employeeText.field.taxCode}
            </FormLabel>
            <Controller
              name="taxCode"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    id="taxCode"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="nationalId" className="form-label">
              {employeeText.field.nationalId}
            </FormLabel>
            <Controller
              name="nationalId"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    id="nationalId"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="dateOfIssue" className="form-label">
              {employeeText.field.dateOfIssue}
            </FormLabel>
            <Controller
              name="dateOfIssue"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="dateOfIssue"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date || null);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="placeOfIssue" className="form-label">
              {employeeText.field.placeOfIssue}
            </FormLabel>
            <Controller
              name="placeOfIssue"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    id="placeOfIssue"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="permanentAddress" className="form-label">
              {employeeText.field.permanentAddress}
            </FormLabel>
            <Controller
              name="permanentAddress"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    id="permanentAddress"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="dateOfJoining" className="form-label">
              {employeeText.field.dateOfJoining}
            </FormLabel>
            <Controller
              name="dateOfJoining"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="dateOfJoining"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date || null);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="dateOfLeaving" className="form-label">
              {employeeText.field.dateOfLeaving}
            </FormLabel>
            <Controller
              name="dateOfLeaving"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="dateOfLeaving"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date || null);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
