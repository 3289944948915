import { Source } from "app/models";

export const DEFAULT_ERROR_MESSAGE = "Đã xảy ra lỗi!";

export const DEFAULT_SUCCESS_MESSAGE = "Thành công";

export const AUTH_REFRESH_TOKEN = "ks-auth-refresh-token";
export const AUTH_ACCESS_TOKEN = "ks-auth-access-token";
export const USER_INFO = "ks-user-info";

export const DEFAULT_LIMIT_PAGE = 20;
export const DEFAULT_PAGE = 1;

export const sourceMappers: Record<Source, string> = {
  employee: "Nhân sự",
  product: "Sản phẩm",
  order: "Đơn hàng",
};
