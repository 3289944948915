import React, { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

//routes
import Layout from "app/components/molecules/Layout/Layout";
import NonAuthMainLayout from "app/components/molecules/NonAuthLayout/NonAuthLayout";
import Error404 from "app/components/pages/error/Error404";
import {
  appPosProtectedRoutes,
  appPosPublicRoutes,
  appProtectedRoutes,
  appPublicRoutes,
} from "./allRoutes";
import AuthPosProtected from "./AuthPosProtected";
import AuthProtected from "./AuthProtected";

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {appPublicRoutes.map((route: any, idx: any) => (
            <Route
              index={route.index}
              path={route.path}
              element={<NonAuthMainLayout>{route.component}</NonAuthMainLayout>}
              key={idx}
            >
              {route.children?.map((child: any, idx: any) => (
                <Route
                  index={child.index}
                  path={child.path}
                  element={child.component}
                  key={idx}
                />
              ))}
            </Route>
          ))}
        </Route>

        <Route>
          {appPosPublicRoutes.map((route: any, idx: any) => (
            <Route
              index={route.index}
              path={route.path}
              element={<NonAuthMainLayout>{route.component}</NonAuthMainLayout>}
              key={idx}
            >
              {route.children?.map((child: any, idx: any) => (
                <Route
                  index={child.index}
                  path={child.path}
                  element={child.component}
                  key={idx}
                />
              ))}
            </Route>
          ))}
        </Route>

        <Route>
          {appProtectedRoutes.map((route: any, idx: any) => (
            <Route
              index={route.index}
              path={route.path}
              element={
                <AuthProtected>
                  <Layout>{route.component}</Layout>
                </AuthProtected>
              }
              key={idx}
            >
              {route.children?.map((child: any, idx: any) => (
                <Route
                  index={child.index}
                  path={child.path}
                  element={child.component}
                  key={idx}
                />
              ))}
            </Route>
          ))}
        </Route>

        <Route>
          {appPosProtectedRoutes.map((route: any, idx: any) => (
            <Fragment key={idx}>
              <Route
                index={route.index}
                path={route.path}
                element={<AuthPosProtected>{route.component}</AuthPosProtected>}
                key={idx}
              >
                {route.children?.map((child: any, idx: any) => (
                  <Route
                    index={child.index}
                    path={child.path}
                    element={child.component}
                    key={idx}
                  />
                ))}
              </Route>
            </Fragment>
          ))}
        </Route>
        <Route path="*" element={<Navigate to="404" />} />
        <Route path="404" element={<Error404 />} />
      </Routes>
    </React.Fragment>
  );
};

export default Index;
