import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { BomProduct } from "app/services/bomProduct";
import { FC } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";

export interface DetailBomProductGeneralProps {}

const DetailBomProductGeneral: FC<DetailBomProductGeneralProps> = () => {
  const { bomProductText, productText } = useAppTranslation();
  const { data: bomProduct } = useDetailPageContext<BomProduct>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {bomProductText.detail.infomation}
              </h5>
              <div className="table-responsive">
                <Table className="table-borderless mb-0">
                  <tbody>
                    <tr>
                      <th className="ps-0" scope="row">
                        {bomProductText.field.bomCode}:
                      </th>
                      <td className="text-muted">
                        {bomProduct?.bomCode || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {productText.field.code}:
                      </th>
                      <td className="text-muted">
                        {bomProduct?.product.code || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {productText.field.name}:
                      </th>
                      <td className="text-muted">
                        {bomProduct?.product.nameVn || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {bomProductText.field.storage}:
                      </th>
                      <td className="text-muted">
                        {bomProduct?.storage?.name || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {bomProductText.field.productionLine}:
                      </th>
                      <td className="text-muted">
                        {bomProduct?.productionLine?.name || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {bomProductText.field.version}:
                      </th>
                      <td className="text-muted">
                        {bomProduct?.version || "-"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailBomProductGeneral;
