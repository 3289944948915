import { useAuth } from "app/components/organisms/AuthProvider";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  loginErrorMessages,
  tokenStorage,
  useLoginMutation,
} from "app/services/auth";
import { useNavigate } from "react-router-dom";

export const useLogin = () => {
  const { authenticate } = useAuth();
  const navigate = useNavigate();
  const toast = useToastify();
  return useLoginMutation({
    onSuccess: (data) => {
      const { accessToken, refreshToken } = data.data.data;
      tokenStorage.setRefreshToken(refreshToken);
      authenticate({ accessToken });
      navigate("/");
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(loginErrorMessages, error.response?.data.message),
      );
    },
  });
};
