import React, { FC, FormEvent, useMemo, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import withRouter from "Components/Common/withRouter";
// Formik validation
import * as Yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import AuthSlider from "app/components/molecules/AuthCarousel/AuthCarousel";
import { Button } from "app/components/molecules/Button";
import { FormInput } from "app/components/molecules/FormInput";
import { useAppTranslation } from "app/hooks";
import { useLoginPos } from "app/modules/auth/hooks";
import LanguageDropdown from "Components/Common/LanguageDropdown";
import { Controller, useForm } from "react-hook-form";
//import images

export type LoginFormData = {
  username: any;
  password: string;
  rememberMe?: boolean;
};

export interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const { authText, t, i18n } = useAppTranslation();
  const [passwordShow, setPasswordShow] = useState(false);

  const schema = useMemo(
    () =>
      Yup.object({
        username: Yup.string()
          .required("auth.error.usernameRequired")
          .test("is-valid-username", "auth.error.usernameInvalid", (value) => {
            if (typeof value === "number") {
              return true; // Valid if it's a number
            }
            if (typeof value === "string") {
              // Check if the string is either alphanumeric or a valid email
              const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(value);
              const isEmail = Yup.string().email().isValidSync(value);
              return isAlphanumeric || isEmail;
            }
            return false;
          }),

        password: Yup.string()
          .required("auth.error.passwordRequired")
          .min(8, "auth.error.passwordMinLength"),
      }),
    [t, i18n.language],
  );

  const { control, handleSubmit } = useForm<LoginFormData>({
    mode: "onChange",
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const { mutateAsync: login, isPending } = useLoginPos();

  const handleLogin = ({ username, password }: LoginFormData) => {
    login({ username: username, password: password, rememberMe: false });
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(handleLogin)();
  };

  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100 position-relative">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden card-bg-fill border-0 card-border-effect-none">
                  <Row className="g-0">
                    <AuthSlider />

                    <Col lg={6}>
                      <div className="py-lg-5 p-0">
                        <div className="p-lg-5 p-4">
                          <div>
                            <h5 className="text-primary">{authText.login}</h5>
                            <p className="text-muted">{authText.titleLogin}</p>
                          </div>

                          <div className="mt-4">
                            <Form onSubmit={submit}>
                              <FormGroup>
                                <Label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  {authText.username}
                                </Label>
                                <Controller
                                  name="username"
                                  control={control}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <FormInput
                                        {...field}
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        invalid={!!fieldState.invalid}
                                      />
                                      <FormFeedback type="invalid">
                                        {fieldState.error?.message
                                          ? t(fieldState.error.message)
                                          : null}
                                      </FormFeedback>
                                    </>
                                  )}
                                />
                              </FormGroup>
                              <FormGroup>
                                {/* <div className="float-end">
                                  <Link
                                    to="/reset-password"
                                    className="text-muted"
                                  >
                                    {authText.forgotPassword}
                                  </Link>
                                </div> */}
                                <Label
                                  className="form-label"
                                  htmlFor="password-input"
                                >
                                  {authText.password}
                                </Label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                  <Controller
                                    name="password"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <FormInput
                                          {...field}
                                          type={
                                            passwordShow ? "text" : "password"
                                          }
                                          className="form-control pe-5 password-input"
                                          id="password-input"
                                          invalid={!!fieldState.invalid}
                                        />
                                        <FormFeedback type="invalid">
                                          {fieldState.error?.message
                                            ? t(fieldState.error.message)
                                            : null}
                                        </FormFeedback>
                                      </>
                                    )}
                                  />
                                  <button
                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon"
                                    onClick={() =>
                                      setPasswordShow(!passwordShow)
                                    }
                                  >
                                    <i className="ri-eye-fill align-middle"></i>
                                  </button>
                                </div>
                              </FormGroup>

                              <div className="mt-4">
                                <Button
                                  color="success"
                                  className="w-100"
                                  type="submit"
                                  loading={isPending}
                                >
                                  {authText.login}
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="change-language-btn">
          <LanguageDropdown />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
