import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  updateVendorErrorMessages,
  RETRIEVE_VENDORS_URL,
  useUpdateVendorMutation,
  RETRIEVE_VENDOR_BY_ID_URL,
} from "app/services/vendor";

interface useCreateVendorProps {
  onSuccess?: () => void;
  onError?: () => void;
  id?: ID; // to invalidate the cache
}

export const useUpdateVendor = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
}: useCreateVendorProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useUpdateVendorMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_VENDORS_URL] });
      if (id) {
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_VENDOR_BY_ID_URL(id)],
        });
      }
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          updateVendorErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
