import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { FileDTO, FileKey, FilePreview, Source } from "app/models";
import {
  UploadFilesArgs,
  UploadFilesResponse,
  UploadSingleFilesArgs,
  UploadSingleFilesResponse,
} from "app/services/file";
import difference from "lodash/difference";

export interface UploadFilesProps {
  upload: UseMutateAsyncFunction<
    UploadFilesResponse,
    any,
    UploadFilesArgs,
    unknown
  >;
  data: File[];
  source: Source;
  fileKey: FileKey;
}

export const getUploadedFileIds = async ({
  upload,
  data,
  source,
  fileKey,
}: UploadFilesProps) => {
  let images: string[] | undefined = undefined;

  const newImages: File[] = data?.filter((item) => item instanceof File) || [];

  if (newImages.length > 0) {
    images = await upload({
      files: newImages,
      moduleType: source,
      fileType: fileKey,
    });
  }

  return images;
};

export interface UploadSingleFilesProps {
  upload: UseMutateAsyncFunction<
    UploadSingleFilesResponse,
    any,
    UploadSingleFilesArgs,
    unknown
  >;
  data: File;
  source: Source;
  fileKey: FileKey;
}

export const getUploadedSingleFileId = async ({
  upload,
  data,
  source,
  fileKey,
}: UploadSingleFilesProps) => {
  let imageId: string | undefined = undefined;

  const newImages: File | null = data instanceof File ? data : null;

  if (newImages) {
    imageId = await upload({
      file: newImages,
      moduleType: source,
      fileType: fileKey,
    });
  }

  return imageId;
};

export const getDeleteFileIds = (
  currents?: FileDTO[],
  remains?: FilePreview[],
) => {
  const currentIds = currents?.map((image) => image.id) || [];
  const remainIds =
    remains?.reduce<ID[]>((ids, image) => {
      if (image.id) ids.push(image.id);
      return ids;
    }, []) || [];
  return difference(currentIds, remainIds);
};
