// src/pages/ListRole.tsx
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  RoleAction,
  RoleActionDropdown,
  RoleFormData,
  RoleFormModal,
} from "app/modules/role/components";
import {
  useCreateRole,
  useRetrieveRoles,
  useUpdateRole,
} from "app/modules/role/hooks";
import { useDeleteRole } from "app/modules/role/hooks/useDeleteRole";
import { mapCreateRole, mapUpdateRole } from "app/modules/role/mapper";
import { Role } from "app/services/role";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo, useRef, useState } from "react";
import { Col, Row } from "reactstrap";

interface ListRoleProps {}

const ListRole: FC<ListRoleProps> = () => {
  const { roleText, confirmText } = useAppTranslation();
  const { confirm } = useConfirm();
  const [isRoleModalOpen, toggleRoleModal] = useState(false);
  const rowSelected = useRef<Role | null>(null);

  const handleToggleRoleModal = (data?: Role) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isRoleModalOpen) {
      rowSelected.current = null;
    }
    toggleRoleModal((prevState) => !prevState);
  };

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: DEFAULT_PAGE,
      initialLimit: DEFAULT_LIMIT_PAGE,
      initialSearchString: "",
    });

  const { roles, isLoadingRoles } = useRetrieveRoles({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteRole } = useDeleteRole();

  const handleActionClick = (action: RoleAction, data?: Role) => {
    switch (action) {
      case "edit":
        if (data) {
          handleToggleRoleModal(data);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(roleText.title),
            description: confirmText.deleteContent(roleText.title),
            onConfirm: () => deleteRole({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Role>[] = useMemo(
    () => [
      {
        header: roleText.field.name,
        accessorKey: "name",
      },
      {
        header: roleText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
      {
        header: "",
        size: 40,
        accessorKey: "action",
        cell: ({ row }) => (
          <RoleActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [roleText],
  );

  const { mutateAsync: createRole, isPending: isCreating } = useCreateRole({
    onSuccess: () => {
      handleToggleRoleModal();
    },
  });

  const handleCreateRole = (data: RoleFormData) => {
    createRole(mapCreateRole(data));
  };

  const { mutateAsync: updateRole, isPending: isUpdating } = useUpdateRole({
    id: rowSelected.current?.id,
    onSuccess: () => {
      handleToggleRoleModal();
    },
  });

  const handleUpdateRole = (data: RoleFormData) => {
    if (rowSelected.current) {
      updateRole(
        mapUpdateRole({
          id: rowSelected.current.id,
          ...data,
        }),
      );
    }
  };

  const handleSubmitRole = (data: RoleFormData) => {
    if (rowSelected.current) {
      handleUpdateRole(data);
    } else {
      handleCreateRole(data);
    }
  };

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={roleText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <DebouncedInput
                  name="list-role-search"
                  value={searchString || ""}
                  onChange={(value) => {
                    setSearchString(String(value));
                  }}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => handleToggleRoleModal()}
              >
                {roleText.add}
              </Button>
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<Role>
            columns={columns}
            data={roles?.result || []}
            loading={isLoadingRoles}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={roles?.total || 0}
          />
        </ListPage.Main>
      </ListPage>
      <RoleFormModal
        open={isRoleModalOpen}
        onToggle={handleToggleRoleModal}
        onSubmit={handleSubmitRole}
        submitting={isCreating || isUpdating}
        edit={!!rowSelected.current}
        data={rowSelected.current || undefined}
      />
    </React.Fragment>
  );
};

export default ListRole;
