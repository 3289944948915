import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Role } from "./types";

export const UPDATE_ROLE_URL = (id: ID) => `/api/roles/${id}`;

export enum UpdateRoleError {
  Exist = "Role already existed",
}

export const updateRoleErrorMessages = {
  [UpdateRoleError.Exist]: "Vai trò đã tồn tại",
};

export type UpdateRoleResponse = Response<Role>;

export type UpdateRoleArgs = {
  id: ID;
  name: string;
  description?: string;
  permissionIds: ID[];
};

export const updateRole = ({ id, ...args }: UpdateRoleArgs) => {
  return httpClient.put<UpdateRoleResponse>(UPDATE_ROLE_URL(id), args);
};

export const useUpdateRoleMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateRoleResponse>,
      AxiosError<any>,
      UpdateRoleArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateRoleResponse>,
    AxiosError<any>,
    UpdateRoleArgs
  >({
    mutationFn: updateRole,
    mutationKey: [UPDATE_ROLE_URL],
    ...opts,
  });
