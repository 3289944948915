import { WithID } from "app/models";
import {
  BomProduct,
  CreateBomProductArgs,
  UpdateBomProductArgs,
} from "app/services/bomProduct";
import { CommonProperties } from "app/services/types";
import { BomProductFormData } from "./components";

export const mapper = ({
  bomProductItems,
  product,
  productionLine,
  storage,
}: BomProductFormData): CommonProperties<
  CreateBomProductArgs,
  UpdateBomProductArgs
> => {
  return {
    productId: product?.value as ID,
    productionLineId: productionLine?.value as ID,
    storageId: storage?.value as ID,
    bomProductItems: bomProductItems.map((item) => ({
      productId: item.products?.value as ID,
      quantity: item.quantity as number,
    })),
  };
};

export const mapCreateBomProduct = (
  data: BomProductFormData,
): CreateBomProductArgs => {
  return mapper(data);
};

export const mapUpdateBomProduct = ({
  id,
  ...data
}: WithID<BomProductFormData>): UpdateBomProductArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultBomProduct = ({
  product,
  productionLine,
  storage,
  bomProductItems,
}: Partial<BomProduct> = {}): BomProductFormData => {
  return {
    product: product
      ? {
          value: product.id,
          label: product.nameVn,
        }
      : null,
    productionLine: productionLine
      ? {
          value: productionLine.id,
          label: productionLine.name,
        }
      : null,
    storage: storage
      ? {
          value: storage.id,
          label: storage.name,
        }
      : null,
    bomProductItems:
      bomProductItems?.map((item) => ({
        products: {
          value: item.id,
          label: item.nameVn,
        },
        quantity: item.quantity || null,
        id: item.id,
        group: item.productGroup
          ? {
              value: item.productGroup.id,
              label: item.productGroup.name,
            }
          : null,
        type: item.productType
          ? {
              value: item.productType.id,
              label: item.productType.name,
            }
          : null,
        unit: item.productUnit
          ? {
              value: item.productUnit.id,
              label: item.productUnit.name,
            }
          : null,
      })) || [],
  };
};
