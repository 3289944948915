import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { BomProduct } from "./types";

export const CREATE_BOM_PRODUCT_URL = "/api/bom-products";

export enum CreateBomProductError {}

export const createBomProductErrorMessages = {};

export interface CreateBomProductArgs {
  productId: ID;
  storageId: ID;
  productionLineId: ID | null;
  bomProductItems: {
    productId: ID;
    quantity: number;
  }[];
}

export type CreateBomProductResponse = Response<BomProduct>;

export const createBomProduct = (args: CreateBomProductArgs) => {
  return httpClient.post<CreateBomProductResponse>(
    CREATE_BOM_PRODUCT_URL,
    args,
  );
};

export const useCreateBomProductMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateBomProductResponse>,
      AxiosError<any>,
      CreateBomProductArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateBomProductResponse>,
    AxiosError<any>,
    CreateBomProductArgs
  >({
    mutationFn: createBomProduct,
    mutationKey: [CREATE_BOM_PRODUCT_URL],
    ...opts,
  });
