import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  createEmployeeErrorMessages,
  RETRIEVE_EMPLOYEES_URL,
  useCreateEmployeeMutation,
} from "app/services/employee";

interface UseCreateEmployeeProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreateEmployee = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseCreateEmployeeProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateEmployeeMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_EMPLOYEES_URL] });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createEmployeeErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
