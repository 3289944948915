import { useAppTranslation } from "app/hooks";
import { FileDTO } from "app/models";
import { FC, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type AttachmentAction = "delete" | "download" | "view";

export interface AttachmentActionDropdownProps {
  data?: FileDTO;
  onAction: (action: AttachmentAction, data?: FileDTO) => void;
  isDocument?: boolean;
}

export const AttachmentActionDropdown: FC<AttachmentActionDropdownProps> = ({
  data,
  onAction,
  isDocument,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const { buttonText } = useAppTranslation();

  const handleAction = (action: AttachmentAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  return (
    <Dropdown isOpen={isOpen} toggle={handleToggle} direction="end">
      <DropdownToggle tag="button" className="btn py-0 px-2 m-0">
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu style={{ minWidth: 126 }} className="dropdown-menu-end">
        {!isDocument && (
          <DropdownItem className="p-0" onClick={handleAction("view")}>
            <div className="dropdown-item">
              <i className="ri-eye-fill text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">{buttonText.view}</span>
            </div>
          </DropdownItem>
        )}
        <DropdownItem className="p-0" onClick={handleAction("download")}>
          <div className="dropdown-item">
            <i className=" ri-download-2-fill text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.download}</span>
          </div>
        </DropdownItem>
        <DropdownItem className="p-0" onClick={handleAction("delete")}>
          <div className="dropdown-item">
            <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.delete}</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
