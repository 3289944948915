import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { VendorForm, VendorFormData } from "app/modules/vendor/components";
import {
  useRetrieveVendorById,
  useUpdateVendor,
} from "app/modules/vendor/hooks";
import { mapDefaultVendor, mapUpdateVendor } from "app/modules/vendor/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateVendorProps {}

const UpdateVendor: FC<UpdateVendorProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { vendorText } = useAppTranslation();

  const { vendor, isLoading } = useRetrieveVendorById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateVendor({
    id,
    onSuccess: () => {
      navigate(-1);
    },
  });

  const handleSubmit = async (data: VendorFormData) => {
    mutateAsync(mapUpdateVendor({ id: id as string, ...data }));
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={vendorText.edit}
          pageTitle={vendorText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <VendorForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultVendor(vendor)}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateVendor;
