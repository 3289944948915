import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import useAppSearch from "app/hooks/useAppSearch";
import {
  JobTitleAction,
  JobTitleActionDropdown,
  JobTitleFormData,
  JobTitleFormModal,
} from "app/modules/jobTitle/components";
import {
  useCreateJobTitle,
  useDeleteJobTitle,
  useRetrieveJobTitles,
  useUpdateJobTitle,
} from "app/modules/jobTitle/hooks";
import {
  mapCreateJobTitle,
  mapUpdateJobTitle,
} from "app/modules/jobTitle/mapper";
import { JobTitle } from "app/services/jobTitle";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";

interface ListJobTitleProps {}

const ListJobTitle: FC<ListJobTitleProps> = () => {
  const { confirm } = useConfirm();
  const [isJobTitleModalOpen, toggleJobTitleModal] = useState(false);
  const rowSelected = useRef<JobTitle | null>(null);

  const handleToggleJobTitleModal = (data?: JobTitle) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isJobTitleModalOpen) {
      rowSelected.current = null;
    }
    toggleJobTitleModal((prevState) => !prevState);
  };

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: DEFAULT_PAGE,
      initialLimit: DEFAULT_LIMIT_PAGE,
      initialSearchString: "",
    });

  const { jobTitles, isLoadingJobTitles } = useRetrieveJobTitles({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteJobTitle } = useDeleteJobTitle();

  const handleActionClick = (action: JobTitleAction, data?: JobTitle) => {
    switch (action) {
      case "edit":
        if (data) {
          handleToggleJobTitleModal(data);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: "Xóa chức vụ",
            description: "Bạn có chắc chắn muốn xóa chức vụ này?",
            onConfirm: () => deleteJobTitle({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<JobTitle>[] = useMemo(
    () => [
      {
        header: "Tên",
        accessorKey: "name",
      },
      {
        header: "Hành động",
        accessorKey: "action",
        cell: ({ row }) => (
          <JobTitleActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [],
  );

  const { mutateAsync: createJobTitle, isPending: isCreating } =
    useCreateJobTitle({
      onSuccess: () => {
        handleToggleJobTitleModal();
      },
    });

  const handleCreateJobTitle = (data: JobTitleFormData) => {
    createJobTitle(mapCreateJobTitle(data));
  };

  const { mutateAsync: updateJobTitle, isPending: isUpdating } =
    useUpdateJobTitle({
      id: rowSelected.current?.id,
      onSuccess: () => {
        handleToggleJobTitleModal();
      },
    });

  const handleUpdateJobTitle = (data: JobTitleFormData) => {
    if (rowSelected.current) {
      updateJobTitle(
        mapUpdateJobTitle({
          id: rowSelected.current.id,
          ...data,
        }),
      );
    }
  };

  const handleSubmitJobTitle = (data: JobTitleFormData) => {
    if (rowSelected.current) {
      handleUpdateJobTitle(data);
    } else {
      handleCreateJobTitle(data);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-content-wrapper">
            <BreadCrumb title="Chức vụ" pageTitle="Kingston" />
            <Row>
              <Col xs={6}>
                <div className="search-box me-2 mb-2 d-inline-block col-12">
                  <DebouncedInput
                    value={searchString || ""}
                    onChange={(value) => {
                      setSearchString(String(value));
                    }}
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </Col>
              <Col xs={6} className="text-end">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => handleToggleJobTitleModal()}
                >
                  Thêm chức vụ
                </Button>
              </Col>
            </Row>
            <DataTable<JobTitle>
              columns={columns}
              data={jobTitles?.result || []}
              loading={isLoadingJobTitles}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              total={jobTitles?.total || 0}
            />
          </div>
        </Container>
      </div>
      <JobTitleFormModal
        open={isJobTitleModalOpen}
        onToggle={handleToggleJobTitleModal}
        onSubmit={handleSubmitJobTitle}
        submitting={isCreating || isUpdating}
        edit={!!rowSelected.current}
        data={rowSelected.current || undefined}
      />
    </React.Fragment>
  );
};

export default ListJobTitle;
