import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation } from "app/hooks";
import MasterDataInfiniteSelect from "app/modules/masterData/components/MasterDataInfinitySelect/MasterDataInfinitySelect";
import { ProductSelect } from "app/modules/product/components/ProductSelect";
import { useRetrieveProductById } from "app/modules/product/hooks";
import { MasterDataType } from "app/services/masterData";
import { retrieveProductById } from "app/services/product";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface BomProductFormProps {
  defaultValues?: BomProductFormData;
  onSubmit: (data: BomProductFormData) => void;
  submitting?: boolean;
}

export interface BomProductItemFormData {
  products: OptionSelect<ID> | null;
  quantity: number | null;
  unit?: any;
  type?: any;
  group?: any;
  id?: ID | null;
}

export interface BomProductFormData {
  product: OptionSelect<ID> | null;
  storage: OptionSelect<ID> | null;
  productionLine: OptionSelect<ID> | null;
  bomProductItems: BomProductItemFormData[];
}

export const BomProductForm: FC<BomProductFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
}) => {
  const { buttonText } = useAppTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { isDirty, isValid },
  } = useForm<BomProductFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        product: yupSchema.objectRequired("Vui lòng chọn sản phẩm"),
        storage: yupSchema.objectRequired("Vui lòng chọn kho"),
        productionLine: yupSchema.objectNotRequired(),
        bomProductItems: Yup.array()
          .of(
            Yup.object({
              products: yupSchema.objectRequired("order.error.productRequired"),
              quantity: yupSchema.numberMoreThanRequired(
                0,
                "order.error.quantityMoreThanZero",
                "order.error.quantityRequired",
              ),
              unit: yupSchema.any(),
            }),
          )
          .defined(),
      }),
    ),
    defaultValues,
  });

  const watchProduct = watch("product");

  const { product } = useRetrieveProductById(
    { id: watchProduct?.value as ID },
    { enabled: !!watchProduct?.value },
  );

  const { fields, remove, append } = useFieldArray({
    control,
    name: "bomProductItems",
  });

  const watchFieldArray = watch("bomProductItems");
  const bomProductItemsField = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const selectedProduct =
    bomProductItemsField.length > 0
      ? [
          ...bomProductItemsField.map((item) => item.products?.value),
          watchProduct?.value,
        ]
      : [];

  const isEnableSubmit = useMemo(() => isDirty && isValid, [isDirty, isValid]);

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  return (
    <Form onSubmit={submit} id="bom-product-form">
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="product" className="form-label">
              Sản phẩm
            </FormLabel>
            <Controller
              name="product"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <ProductSelect
                    name="product"
                    inputId="product"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="productType" className="form-label">
              Loại sản phẩm
            </FormLabel>
            <MasterDataInfiniteSelect
              isDisabled
              name="productType"
              inputId="productType"
              value={{
                value: product?.productType?.id || "",
                label: product?.productType?.name || "-",
              }}
              onChange={() => null}
              retrieveKeys={{ type: MasterDataType.ProductType }}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="productGroup" className="form-label">
              Nhóm sản phẩm
            </FormLabel>
            <MasterDataInfiniteSelect
              isDisabled
              name="productGroup"
              inputId="productGroup"
              value={{
                value: product?.productGroup?.id || "",
                label: product?.productGroup?.name || "-",
              }}
              onChange={() => null}
              retrieveKeys={{ type: MasterDataType.ProductGroup }}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="storage" className="form-label">
              Kho
            </FormLabel>
            <Controller
              name="storage"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    name="storage"
                    inputId="storage"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.ProductUnit }}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="productionLine" className="form-label">
              Dây chuyền sản xuất
            </FormLabel>
            <Controller
              name="productionLine"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    name="productionLine"
                    inputId="productionLine"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.ProductUnit }}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <FormLabel className="form-label">Thông tin vật tư đính kèm</FormLabel>
        <Col lg={12}>
          {bomProductItemsField.map((field, index) => (
            <Card key={field.id}>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <FormLabel htmlFor="notes" className="form-label">
                            Sản phẩm
                          </FormLabel>
                        </Col>
                        <Col>
                          <div className="text-end">
                            <i
                              className="ri-delete-bin-line fs-18 cursor-pointer lh-1"
                              onClick={() => remove(index)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Controller
                        name={`bomProductItems.${index}.products` as const}
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <ProductSelect
                              isOptionDisabled={(option) =>
                                selectedProduct.includes(option.value)
                              }
                              inputId={`bomProductItems.${index}.products`}
                              name={`bomProductItems.${index}.products`}
                              value={field.value}
                              onChange={async (value) => {
                                field.onChange(value);
                                if (value?.value) {
                                  const { data } = await retrieveProductById({
                                    id: value?.value as ID,
                                  });
                                  const product = data?.data;
                                  setValue(`bomProductItems.${index}.unit`, {
                                    value: product?.productUnit?.id || "",
                                    label: product?.productUnit?.name || "-",
                                  });
                                  setValue(`bomProductItems.${index}.type`, {
                                    value: product?.productType?.id || "",
                                    label: product?.productType?.name || "-",
                                  });
                                  setValue(`bomProductItems.${index}.group`, {
                                    value: product?.productGroup?.id || "",
                                    label: product?.productGroup?.name || "-",
                                  });
                                } else {
                                  setValue(
                                    `bomProductItems.${index}.unit`,
                                    null,
                                    { shouldTouch: true },
                                  );
                                  setValue(
                                    `bomProductItems.${index}.type`,
                                    null,
                                    { shouldTouch: true },
                                  );
                                  setValue(
                                    `bomProductItems.${index}.group`,
                                    null,
                                    { shouldTouch: true },
                                  );
                                }
                              }}
                              isInvalid={fieldState.invalid}
                            />
                            <FormHelper message={fieldState.error?.message} />
                          </>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel
                        required
                        htmlFor={`bomProductItems.${index}.quantity`}
                        className="form-label"
                      >
                        Số lượng
                      </FormLabel>
                      <Controller
                        name={`bomProductItems.${index}.quantity` as const}
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <FormInput
                              {...field}
                              value={field.value ?? ""}
                              onChange={(value) => {
                                field.onChange(
                                  value.target.value === ""
                                    ? null
                                    : Number(value.target.value),
                                );
                              }}
                              type="number"
                              className="form-control"
                              id={`bomProductItems.${index}.quantity`}
                              invalid={!!fieldState.invalid}
                            />
                            <FormHelper message={fieldState.error?.message} />
                          </>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <FormLabel
                        required
                        htmlFor={`bomProductItems.${index}.unit`}
                        className="form-label"
                      >
                        Đơn vị
                      </FormLabel>
                      <Controller
                        name={`bomProductItems.${index}.unit` as const}
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <MasterDataInfiniteSelect
                              isDisabled
                              name={`bomProductItems.${index}.unit`}
                              inputId={`bomProductItems.${index}.unit`}
                              value={field.value}
                              onChange={field.onChange}
                              isInvalid={fieldState.invalid}
                              retrieveKeys={{
                                type: MasterDataType.ProductUnit,
                              }}
                            />
                          </>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <FormLabel
                        htmlFor={`bomProductItems.${index}.type`}
                        className="form-label"
                      >
                        Loại sản phẩm
                      </FormLabel>
                      <Controller
                        name={`bomProductItems.${index}.type` as const}
                        control={control}
                        render={({ field }) => (
                          <>
                            <MasterDataInfiniteSelect
                              isDisabled
                              name={`bomProductItems.${index}.type`}
                              inputId={`bomProductItems.${index}.type`}
                              value={field.value}
                              onChange={field.onChange}
                              retrieveKeys={{
                                type: MasterDataType.ProductType,
                              }}
                            />
                          </>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <FormLabel
                        htmlFor={`bomProductItems.${index}.group`}
                        className="form-label"
                      >
                        Nhóm sản phẩm
                      </FormLabel>
                      <Controller
                        name={`bomProductItems.${index}.group` as const}
                        control={control}
                        render={({ field }) => (
                          <>
                            <MasterDataInfiniteSelect
                              isDisabled
                              name={`bomProductItems.${index}.group`}
                              inputId={`bomProductItems.${index}.group`}
                              value={field.value}
                              onChange={field.onChange}
                              retrieveKeys={{
                                type: MasterDataType.ProductGroup,
                              }}
                            />
                          </>
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}
          <Button
            color="primary"
            size="sm"
            className="mb-4"
            onClick={() =>
              append({
                products: null,
                quantity: null,
              })
            }
          >
            {buttonText.add}
          </Button>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
