import { FC, PropsWithChildren, ReactElement } from "react";

export interface DetailPageMainProps {
  tabs?: ReactElement;
  attachmentBar?: ReactElement;
}

export const DetailPageMain: FC<DetailPageMainProps & PropsWithChildren> = ({
  children,
  tabs,
  attachmentBar,
}) => {
  return (
    <div className="detail-container flex-fill">
      <div className="h-100 d-flex flex-column">
        {tabs || null}
        <div className="d-flex justify-content-between overflow-hidden border-top mt-3 flex-fill">
          <div className="flex-fill overflow-auto overflow-x-hidden">
            {children}
          </div>
          {attachmentBar || null}
        </div>
      </div>
    </div>
  );
};
