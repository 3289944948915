import { PagingData } from "./../types";
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { Order } from "./types";

export const RETRIEVE_ORDERS_URL = "/api/orders";

export interface RetrieveOrdersArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveOrdersResponse = Response<PagingData<Order[]>>;

export const retrieveOrders = (args: RetrieveOrdersArgs) => {
  return httpClient.get<RetrieveOrdersResponse>(RETRIEVE_ORDERS_URL, {
    params: args,
  });
};

export const useRetrieveOrdersQuery = (
  retrieveOrdersArgs: RetrieveOrdersArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveOrdersResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveOrdersResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_ORDERS_URL, retrieveOrdersArgs],
    queryFn: () => retrieveOrders(retrieveOrdersArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
