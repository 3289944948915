import { AUTH_ACCESS_TOKEN, USER_INFO } from "app/helpers";
import { CurrentUser } from "./types";

export const userStorage = {
  storageKey: USER_INFO,
  isFirstLoginKey: "is-first-login",
  set(user: CurrentUser) {
    try {
      localStorage.setItem(this.storageKey, JSON.stringify(user));
    } catch (error) {
      console.warn(`userStorage.set error: ${JSON.stringify(error)}`);
    }
  },
  get(): CurrentUser | null {
    try {
      const user = localStorage.getItem(this.storageKey);

      if (!user) return null;

      return JSON.parse(user); // TODO: Need validate user data here, using Zod?
    } catch (error) {
      console.warn(`userStorage.get error: ${JSON.stringify(error)}`);
      return null;
    }
  },
  remove() {
    try {
      localStorage.removeItem(this.storageKey);
    } catch (error) {
      console.warn(`userStorage.remove error: ${JSON.stringify(error)}`);
    }
  },
  setIsFirstLogin(value: boolean) {
    try {
      localStorage.setItem(this.isFirstLoginKey, JSON.stringify(value));
    } catch (error) {
      console.warn(`userStorage.set error: ${JSON.stringify(error)}`);
    }
  },
  getIsFirstLogin(): boolean | null {
    try {
      const isFirstLogin = localStorage.getItem(this.isFirstLoginKey);

      if (!isFirstLogin) return null;

      return Boolean(JSON.parse(isFirstLogin));
    } catch (error) {
      console.warn(
        `userStorage.getIsFirstLogin error: ${JSON.stringify(error)}`,
      );
      return null;
    }
  },
  removeIsFirstLogin() {
    try {
      localStorage.removeItem(this.isFirstLoginKey);
    } catch (error) {
      console.warn(
        `userStorage.removeIsFirstLogin error: ${JSON.stringify(error)}`,
      );
    }
  },
};
