import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import useAppSearch from "app/hooks/useAppSearch";
import {
  DepartmentAction,
  DepartmentActionDropdown,
  DepartmentFormData,
  DepartmentFormModal,
} from "app/modules/department/components";
import {
  useCreateDepartment,
  useDeleteDepartment,
  useRetrieveDepartments,
  useUpdateDepartment,
} from "app/modules/department/hooks";
import {
  mapCreateDepartment,
  mapUpdateDepartment,
} from "app/modules/department/mapper";
import { Department } from "app/services/department";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";

interface ListDepartmentProps {}

const ListDepartment: FC<ListDepartmentProps> = () => {
  const { confirm } = useConfirm();
  const [isDepartmentModalOpen, toggleDepartmentModal] = useState(false);
  const rowSelected = useRef<Department | null>(null);

  const handleToggleDepartmentModal = (data?: Department) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isDepartmentModalOpen) {
      rowSelected.current = null;
    }
    toggleDepartmentModal((prevState) => !prevState);
  };

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: DEFAULT_PAGE,
      initialLimit: DEFAULT_LIMIT_PAGE,
      initialSearchString: "",
    });

  const { departments, isLoadingDepartments } = useRetrieveDepartments({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteDepartment } = useDeleteDepartment();

  const handleActionClick = (action: DepartmentAction, data?: Department) => {
    switch (action) {
      case "edit":
        if (data) {
          handleToggleDepartmentModal(data);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: "Xóa phòng ban",
            description: "Bạn có chắc chắn muốn xóa phòng ban này?",
            onConfirm: () => deleteDepartment({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Department>[] = useMemo(
    () => [
      {
        header: "Tên",
        accessorKey: "name",
      },
      {
        header: "",
        accessorKey: "action",
        cell: ({ row }) => (
          <DepartmentActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [],
  );

  const { mutateAsync: createDepartment, isPending: isCreating } =
    useCreateDepartment({
      onSuccess: () => {
        handleToggleDepartmentModal();
      },
    });

  const handleCreateDepartment = (data: DepartmentFormData) => {
    createDepartment(mapCreateDepartment(data));
  };

  const { mutateAsync: updateDepartment, isPending: isUpdating } =
    useUpdateDepartment({
      id: rowSelected.current?.id,
      onSuccess: () => {
        handleToggleDepartmentModal();
      },
    });

  const handleUpdateDepartment = (data: DepartmentFormData) => {
    if (rowSelected.current) {
      updateDepartment(
        mapUpdateDepartment({
          id: rowSelected.current.id,
          ...data,
        }),
      );
    }
  };

  const handleSubmitDepartment = (data: DepartmentFormData) => {
    if (rowSelected.current) {
      handleUpdateDepartment(data);
    } else {
      handleCreateDepartment(data);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-content-wrapper">
            <BreadCrumb title="Phòng ban" pageTitle="Kingston" />
            <Row>
              <Col xs={6}>
                <div className="search-box me-2 mb-2 d-inline-block col-12">
                  <DebouncedInput
                    value={searchString || ""}
                    onChange={(value) => {
                      setSearchString(String(value));
                    }}
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </Col>
              <Col xs={6} className="text-end">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => handleToggleDepartmentModal()}
                >
                  Thêm phòng ban
                </Button>
              </Col>
            </Row>
            <DataTable<Department>
              columns={columns}
              data={departments?.result || []}
              loading={isLoadingDepartments}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              total={departments?.total || 0}
            />
          </div>
        </Container>
      </div>
      <DepartmentFormModal
        open={isDepartmentModalOpen}
        onToggle={handleToggleDepartmentModal}
        onSubmit={handleSubmitDepartment}
        submitting={isCreating || isUpdating}
        edit={!!rowSelected.current}
        data={rowSelected.current || undefined}
      />
    </React.Fragment>
  );
};

export default ListDepartment;
