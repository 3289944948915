import { FC } from "react";
import { Spinner } from "reactstrap";

export interface LoadingScreenProps {}

export const LoadingScreen: FC<LoadingScreenProps> = () => {
  return (
    <Spinner
      className="position-absolute top-50 start-50"
      animation="border"
      color="primary"
    />
  );
};
