import {
  CreateCustomerArgs,
  Customer,
  UpdateCustomerArgs,
} from "app/services/customer";
import { CommonProperties } from "app/services/types";
import { WithID } from "app/models";
import { CustomerFormData } from "./components";

export const mapper = ({
  address,
  assignee,
  customerCode,
  customerName,
  customerStatus,
  customerType,
  email,
  depositPercentage,
  notes,
  paymentDueDays,
  paymentTerms,
  phoneNumber,
  representative,
  representativeRole,
  startDate,
  website,
}: CustomerFormData): CommonProperties<
  CreateCustomerArgs,
  UpdateCustomerArgs
> => {
  return {
    customerName,
    customerCode: customerCode || null,
    address: address || null,
    email: email || null,
    phoneNumber: phoneNumber || null,
    website: website || null,
    assigneeId: assignee?.value || null,
    customerStatusId: customerStatus?.value || null,
    customerTypeId: customerType?.value || null,
    depositPercentage: depositPercentage || null,
    notes: notes || null,
    paymentDueDays: paymentDueDays || null,
    paymentTerms: paymentTerms || null,
    representative: representative || null,
    representativeRole: representativeRole || null,
    startDate: startDate ? startDate.toISOString() : null,
  };
};

export const mapCreateCustomer = (
  data: CustomerFormData,
): CreateCustomerArgs => {
  return mapper(data);
};

export const mapUpdateCustomer = ({
  id,
  ...data
}: WithID<CustomerFormData>): UpdateCustomerArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultCustomer = ({
  address = "",
  assignee,
  customerCode = "",
  customerName = "",
  customerStatus,
  customerType,
  email = "",
  depositPercentage = 0,
  notes = "",
  paymentDueDays = 0,
  paymentTerms = "",
  phoneNumber = "",
  representative = "",
  representativeRole = "",
  startDate,
  website = "",
}: Partial<Customer> = {}): CustomerFormData => {
  return {
    address: address || "",
    assignee: assignee
      ? { value: assignee.id, label: assignee.fullName }
      : null,
    customerCode: customerCode || "",
    customerName,
    customerStatus: customerStatus
      ? { value: customerStatus.id, label: customerStatus.name }
      : null,
    customerType: customerType
      ? { value: customerType.id, label: customerType.name }
      : null,
    email: email || "",
    depositPercentage: depositPercentage || null,
    notes: notes || "",
    paymentDueDays: paymentDueDays || null,
    paymentTerms: paymentTerms || "",
    phoneNumber: phoneNumber || "",
    representative: representative || "",
    representativeRole: representativeRole || "",
    startDate: startDate ? new Date(startDate) : null,
    website: website || "",
    avatar: [],
  };
};
