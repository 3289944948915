import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import {
  CustomerForm,
  CustomerFormData,
} from "app/modules/customer/components";
import {
  useRetrieveCustomerById,
  useUpdateCustomer,
} from "app/modules/customer/hooks";
import {
  mapDefaultCustomer,
  mapUpdateCustomer,
} from "app/modules/customer/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateCustomerProps {}

const UpdateCustomer: FC<UpdateCustomerProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { customerText } = useAppTranslation();

  const { customer, isLoading } = useRetrieveCustomerById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateCustomer({
    id,
    onSuccess: () => {
      navigate(-1);
    },
  });

  const handleSubmit = async (data: CustomerFormData) => {
    mutateAsync(mapUpdateCustomer({ id: id as string, ...data }));
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={customerText.edit}
          pageTitle={customerText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <CustomerForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultCustomer(customer)}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateCustomer;
