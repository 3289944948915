import { User } from "app/services/user";
import { sourceMappers, USER_INFO } from "./constants";
import { Source } from "app/models";

export const getExtFile = (name: string) => name.split(".").pop() || "";

export const getFileNameWithoutExt = (name: string) =>
  name.slice(0, name.indexOf(getExtFile(name))).slice(0, -1);

export const getTime = () => new Date().getTime();

export const getUnitKey = () => (Math.random() + 1).toString(36).substring(4);

export const formatFileName = (
  name: string,
  fieldName: string,
  id: number | undefined,
) => {
  const ext = getExtFile(name);
  const hexPath = `${fieldName}/${id}-${getTime()}-${getUnitKey()}.${ext}`;
  return hexPath;
};

export const readAsArrayBuffer = (
  file: File,
): Promise<FileReader["result"]> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      return resolve(fileReader.result);
    };

    fileReader.onerror = () => {
      return reject(new Error("Read file as array buffer error!"));
    };

    fileReader.readAsArrayBuffer(file);
  });
};

export const getUserId = (): number | undefined => {
  try {
    const user: User = localStorage.getItem(USER_INFO)
      ? JSON.parse(localStorage.getItem(USER_INFO) || "")
      : null;

    return Number(user.id);
  } catch (error) {
    return undefined;
  }
};

export const mapSource = (sourceKey: Source) => {
  return sourceMappers[sourceKey] || sourceKey;
};
