import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { VendorForm, VendorFormData } from "app/modules/vendor/components";
import { useCreateVendor } from "app/modules/vendor/hooks";
import { mapCreateVendor, mapDefaultVendor } from "app/modules/vendor/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateVendorProps {}

const CreateVendor: FC<CreateVendorProps> = () => {
  const navigate = useNavigate();
  const { vendorText } = useAppTranslation();
  const { mutateAsync, isPending } = useCreateVendor({
    onSuccess: () => {
      navigate("/vendors");
    },
  });

  const handleSubmit = async (data: VendorFormData) => {
    mutateAsync(mapCreateVendor(data));
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={vendorText.add}
          pageTitle={vendorText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <VendorForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultVendor()}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateVendor;
