import {
  RetrieveOrderByIdArgs,
  useRetrieveOrderByIdQuery,
} from "app/services/order";

export const useRetrieveOrderById = (
  args: RetrieveOrderByIdArgs,
  opts?: Parameters<typeof useRetrieveOrderByIdQuery>[1],
) => {
  const result = useRetrieveOrderByIdQuery(args, opts as any);
  const images =
    result.data?.data.data.attachments?.filter(
      (attachment) => attachment.fileType === "image",
    ) || [];
  const documents =
    result.data?.data.data.attachments?.filter(
      (attachment) => attachment.fileType === "document",
    ) || [];
  return {
    order: { ...result.data?.data.data, images, documents },
    isLoadingOrder: result.isLoading,
    ...result,
  };
};
