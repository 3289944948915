import PropTypes from "prop-types";
import { useEffect } from "react";

//redux

import { useLogout } from "app/modules/auth/hooks";
import withRouter from "Components/Common/withRouter";

const Logout = () => {
  const { mutateAsync: logout } = useLogout();

  useEffect(() => {
    logout();
  }, []);
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
