import { useRetrievePermissionsQuery } from "app/services/permission";

export const useRetrievePermissions = () => {
  const result = useRetrievePermissionsQuery();
  return {
    permissions: result.data?.data.data,
    isLoadingPermissions: result.isLoading,
    ...result,
  };
};
