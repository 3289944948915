import {
  RetrieveVendorsArgs,
  useRetrieveVendorsQuery,
} from "app/services/vendor";

export const useRetrieveVendors = (args: RetrieveVendorsArgs) => {
  const result = useRetrieveVendorsQuery(args);
  return {
    vendors: result.data?.data.data,
    isLoadingVendors: result.isLoading,
    ...result,
  };
};
