import { useAppTranslation } from "app/hooks";
import {
  BomProductForm,
  BomProductFormData,
} from "app/modules/bomProduct/components";
import { useCreateBomProduct } from "app/modules/bomProduct/hooks";
import {
  mapCreateBomProduct,
  mapDefaultBomProduct,
} from "app/modules/bomProduct/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

export interface CreateBomProductProps {}

const CreateBomProduct: FC<CreateBomProductProps> = () => {
  const { bomProductText } = useAppTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateBomProduct({
    onSuccess: () => {
      navigate("/bomProducts");
    },
  });

  const handleSubmit = async (data: BomProductFormData) => {
    mutateAsync(mapCreateBomProduct(data));
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-content-wrapper">
          <BreadCrumb
            title={bomProductText.add}
            pageTitle={bomProductText.title}
          />
          <Row>
            <Col
              md={{
                offset: 2,
                size: 8,
              }}
              xl={{
                offset: 3,
                size: 6,
              }}
            >
              <Card>
                <CardBody>
                  <BomProductForm
                    onSubmit={handleSubmit}
                    defaultValues={mapDefaultBomProduct()}
                    submitting={isPending}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CreateBomProduct;
