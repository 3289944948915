import { useAppTranslation } from "app/hooks";
import { useRetrieveOrderById } from "app/modules/order/hooks";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Col, Row } from "reactstrap";

export interface DetailOrderHeaderProps {}

const DetailOrderHeader: FC<DetailOrderHeaderProps> = () => {
  const { buttonText, orderText } = useAppTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { order } = useRetrieveOrderById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <Row className="g-4">
      <Col>
        <div className="p-2">
          <h3 className="mb-1">
            {orderText.title} {order?.orderCode}
          </h3>
          <Badge pill color="success">
            Đã giao hàng
          </Badge>
        </div>
      </Col>
      <Col className="col-lg-auto order-last order-lg-0">
        <Row className="text text-center">
          <Col className="py-2">
            <Button
              color="primary"
              onClick={() => navigate(`/order/${id}/update`)}
            >
              {buttonText.edit}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DetailOrderHeader;
