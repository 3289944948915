import {
  RetrieveProductOrderByIdArgs,
  useRetrieveProductOrderByIdQuery,
} from "app/services/product";

export const useRetrieveProductOrderById = (
  args: RetrieveProductOrderByIdArgs,
  opts?: Parameters<typeof useRetrieveProductOrderByIdQuery>[1],
) => {
  const result = useRetrieveProductOrderByIdQuery(args, opts as any);
  return {
    productOrders: result.data?.data.data,
    isLoadingProductOrder: result.isLoading,
    ...result,
  };
};
