import { AxiosError, AxiosResponse } from "axios";

export type IBaseModel = {
  id: ID;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  createdBy: ID;
  updatedBy?: ID;
};

export type DateTimeString = string; //* ISO Date String

export type PagingData<T> = {
  result: T;
  total: number;
};

export type ResponseWithPagingData<T> = Response<PagingData<T>>;

export type ErrorDetail = {
  field?: string;
  value?: any;
  issue?: string;
  location?: string;
};

export enum CommonError {
  InternalServerError = "INTERNAL_SERVER_ERROR",
  DataNotFound = "DATA_NOT_FOUND",
  BadRequest = "BAD_REQUEST",
  ForbiddenResource = "Forbidden resource",
}

//* Success response
export type Response<T, Error = any, M = string> = {
  status: number;
  message: M;
  errors: boolean | Error[] | null;
  data: T;
};

//* Error response
export type ErrorResponse<M extends string = string, D = null> = {
  status: number;
  message: M | CommonError;
  data?: D;
  details?: ErrorDetail[];
};

export type APISuccess<T = any> = AxiosResponse<T>;

export type APIError<M extends string = string, D = null> = AxiosError<
  ErrorResponse<M, D>
>;

export interface OptionSelect<T> {
  label: string;
  value: T;
}

export type CommonProperties<T, U> = {
  [K in keyof T & keyof U]: T[K] extends U[K] ? T[K] : never;
};
