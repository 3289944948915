import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  createVendorErrorMessages,
  RETRIEVE_VENDORS_URL,
  useCreateVendorMutation,
} from "app/services/vendor";

interface UseCreateVendorProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreateVendor = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseCreateVendorProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateVendorMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_VENDORS_URL] });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createVendorErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
