import { useRetrieveDepartmentById } from "app/modules/department/hooks";
import { Department } from "app/services/department";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { DepartmentForm, DepartmentFormData } from "./DepartmentForm";
import { mapDefaultDepartment } from "../../mapper";

export interface DepartmentFormModalProps {
  data?: Department;
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: DepartmentFormData) => void;
  submitting?: boolean;
  edit?: boolean;
}

export const DepartmentFormModal: FC<DepartmentFormModalProps> = ({
  data,
  onToggle,
  open = false,
  onSubmit,
  submitting,
  edit = false,
}) => {
  const title = edit ? "Sửa phòng ban" : "Thêm phòng ban";
  const { department, isLoading } = useRetrieveDepartmentById(
    {
      id: data?.id as string,
    },
    { enabled: edit && !!data?.id },
  );

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {!isLoading ? (
          <DepartmentForm
            onSubmit={onSubmit}
            onToggle={onToggle}
            defaultValues={mapDefaultDepartment(department)}
            submitting={submitting}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};
