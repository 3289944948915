import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import {
  CustomerForm,
  CustomerFormData,
} from "app/modules/customer/components";
import { useCreateCustomer } from "app/modules/customer/hooks";
import {
  mapCreateCustomer,
  mapDefaultCustomer,
} from "app/modules/customer/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateCustomerProps {}

const CreateCustomer: FC<CreateCustomerProps> = () => {
  const { customerText } = useAppTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateCustomer({
    onSuccess: () => {
      navigate("/customers");
    },
  });

  const handleSubmit = async (data: CustomerFormData) => {
    mutateAsync(mapCreateCustomer(data));
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={customerText.add}
          pageTitle={customerText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <CustomerForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultCustomer()}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateCustomer;
