import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import { RETRIEVE_EMPLOYEES_URL } from "app/services/employee";
import {
  activeUserErrorMessages,
  RETRIEVE_USER_BY_ID_URL,
  RETRIEVE_USERS_URL,
  useActiveUserMutation,
} from "app/services/user";

interface UseActiveUserProps {
  id?: ID;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useActiveUser = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
}: UseActiveUserProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useActiveUserMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_USERS_URL] });
      if (id)
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_USER_BY_ID_URL(id)],
        });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          activeUserErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
