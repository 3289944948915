import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Department } from "./types";
import { Response } from "../types";

export const RETRIEVE_DEPARTMENT_BY_ID_URL = (id: ID) =>
  `/api/departments/${id}`;

export type RetrieveDepartmentByIdResponse = Response<Department>;

export type RetrieveDepartmentByIdArgs = {
  id: ID;
};

export const retrieveDepartmentById = ({ id }: RetrieveDepartmentByIdArgs) => {
  return httpClient.get<RetrieveDepartmentByIdResponse>(
    RETRIEVE_DEPARTMENT_BY_ID_URL(id),
  );
};

export const useRetrieveDepartmentByIdQuery = (
  retrieveDepartmentsArgs: RetrieveDepartmentByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveDepartmentByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveDepartmentByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_DEPARTMENT_BY_ID_URL(retrieveDepartmentsArgs.id)],
    queryFn: () => retrieveDepartmentById(retrieveDepartmentsArgs),
    ...opts,
  });
