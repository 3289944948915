import {
  RetrieveBomProductByIdArgs,
  useRetrieveBomProductByIdQuery,
} from "app/services/bomProduct";

export const useRetrieveBomProductById = (
  args: RetrieveBomProductByIdArgs,
  opts?: Parameters<typeof useRetrieveBomProductByIdQuery>[1],
) => {
  const result = useRetrieveBomProductByIdQuery(args, opts as any);
  return {
    bomProduct: result.data?.data.data,
    isLoadingBomProduct: result.isLoading,
    ...result,
  };
};
