import { PagingData } from "./../types";
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { BomProduct } from "./types";

export const RETRIEVE_BOM_PRODUCTS_URL = "/api/bom-products";

export interface RetrieveBomProductsArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveBomProductsResponse = Response<PagingData<BomProduct[]>>;

export const retrieveBomProducts = (args: RetrieveBomProductsArgs) => {
  return httpClient.get<RetrieveBomProductsResponse>(
    RETRIEVE_BOM_PRODUCTS_URL,
    { params: args },
  );
};

export const useRetrieveBomProductsQuery = (
  retrieveBomProductsArgs: RetrieveBomProductsArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveBomProductsResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveBomProductsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_BOM_PRODUCTS_URL, retrieveBomProductsArgs],
    queryFn: () => retrieveBomProducts(retrieveBomProductsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
