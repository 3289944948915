import React, { FC } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
} from "reactstrap";

//formik
import AuthSlider from "app/components/molecules/AuthCarousel/AuthCarousel";
import { FormInput } from "app/components/molecules/FormInput";
import { useFormik } from "formik";
import * as Yup from "yup";

export interface ResetPasswordProps {}

export type ResetPasswordFormData = {
  email: string;
};

const ResetPassword: FC<ResetPasswordProps> = () => {
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Vui lòng nhập email"),
    }),
    onSubmit: (values) => {
      handleResetPassword(values);
    },
  });

  const handleResetPassword = (data: ResetPasswordFormData) => null;

  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden card-bg-fill border-0 card-border-effect-none">
                  <Row className="justify-content-center g-0">
                    <AuthSlider />

                    <Col lg={6}>
                      <div className="p-lg-5 p-4">
                        <h5 className="text-primary">Quên mật khẩu?</h5>
                        <p className="text-muted">
                          Lấy lại mật khẩu trên Kingston
                        </p>

                        <div className="mt-2 text-center">
                          <i className="ri-mail-send-line display-5 text-success"></i>
                        </div>

                        <div
                          className="alert border-0 alert-warning text-center mb-2 mx-2"
                          role="alert"
                        >
                          Hãy điền email đăng nhập để nhận được đường link dẫn
                          đến trang khôi phục mật khẩu!
                        </div>
                        <div className="p-2">
                          <Form onSubmit={validation.handleSubmit}>
                            <div className="mb-4">
                              <Label className="form-label">Email</Label>
                              <FormInput
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={validation.values.email}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                invalid={
                                  validation.errors.email &&
                                  validation.touched.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.email &&
                              validation.touched.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="text-center mt-4">
                              <Button
                                color="primary"
                                className="w-100"
                                type="submit"
                              >
                                Gửi
                              </Button>
                            </div>
                          </Form>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Quay lại &nbsp;
                            <Link
                              to="/login"
                              className="fw-bold text-primary text-decoration-underline"
                            >
                              Đăng nhập
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
