import { CreateJobTitleArgs, UpdateJobTitleArgs } from "app/services/jobTitle";
import { WithID } from "app/models";
import { JobTitleFormData } from "./components";

export const mapper = ({
  name,
}: JobTitleFormData): CreateJobTitleArgs | UpdateJobTitleArgs => {
  return {
    name,
  };
};

export const mapCreateJobTitle = (
  data: JobTitleFormData,
): CreateJobTitleArgs => {
  return mapper(data);
};

export const mapUpdateJobTitle = ({
  id,
  ...data
}: WithID<JobTitleFormData>): UpdateJobTitleArgs => {
  return { id, ...mapper(data) };
};
