import { FC, PropsWithChildren } from "react";
import { Container } from "reactstrap";
import { ListPageBreadCrumb } from "./ListPageBreadCrumb";
import { ListPageFilter } from "./ListPageFilter";
import { ListPageMain } from "./ListPageMain";

export interface ListPageProps {}

const ListPage: FC<ListPageProps & PropsWithChildren> = ({ children }) => {
  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-content-wrapper">{children}</div>
      </Container>
    </div>
  );
};

export default Object.assign(ListPage, {
  BreadCrumb: ListPageBreadCrumb,
  Filter: ListPageFilter,
  Main: ListPageMain,
});
