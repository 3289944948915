import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Order } from "./types";

export const CREATE_ORDER_URL = "/api/orders";

export enum CreateOrderError {}

export const createOrderErrorMessages = {};

export interface CreateOrderArgs {
  orderCode: string | null;
  orderDate: DateTimeString;
  customerId: ID;
  expectedDeliveryDate: DateTimeString;
  notes: string | null;
  orderItems: {
    productId: ID;
    quantity: number;
    deliveryDate: DateTimeString;
  }[];
  fileIds?: ID[];
}

export type CreateOrderResponse = Response<Order>;

export const createOrder = (args: CreateOrderArgs) => {
  return httpClient.post<CreateOrderResponse>(CREATE_ORDER_URL, args);
};

export const useCreateOrderMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateOrderResponse>,
      AxiosError<any>,
      CreateOrderArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateOrderResponse>,
    AxiosError<any>,
    CreateOrderArgs
  >({
    mutationFn: createOrder,
    mutationKey: [CREATE_ORDER_URL],
    ...opts,
  });
