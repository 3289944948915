import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { intl } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import { Vendor } from "app/services/vendor";
import { FC } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";

export interface DetailVendorGeneralProps {}

const DetailVendorGeneral: FC<DetailVendorGeneralProps> = () => {
  const { vendorText } = useAppTranslation();
  const { data: vendor } = useDetailPageContext<Vendor>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={8}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {vendorText.detail.infomation}
              </h5>
              <div className="table-responsive">
                <Table className="table-borderless mb-0">
                  <tbody>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.vendorName}:
                      </th>
                      <td className="text-muted">{vendor?.vendorName}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.vendorCode}:
                      </th>
                      <td className="text-muted">{vendor?.vendorCode}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.vendorType}:
                      </th>
                      <td className="text-muted">{vendor?.vendorType.name}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.address}:
                      </th>
                      <td className="text-muted">{vendor?.address || "-"}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.phoneNumber}:
                      </th>
                      <td className="text-muted">
                        {vendor?.phoneNumber || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.email}:
                      </th>
                      <td className="text-muted">{vendor?.email || "-"}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.website}:
                      </th>
                      <td className="text-muted">
                        {vendor?.website ? (
                          <a
                            href={vendor.website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {vendor.website}
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.representative}:
                      </th>
                      <td className="text-muted">
                        {vendor?.representative || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.representativeRole}:
                      </th>
                      <td className="text-muted">
                        {vendor?.representativeRole || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.startDate}:
                      </th>
                      <td className="text-muted">
                        {vendor?.startDate
                          ? intl.formatDate(new Date(vendor.startDate))
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.vendorStatus}:
                      </th>
                      <td className="text-muted">
                        {vendor?.vendorStatus ? vendor?.vendorStatus.name : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.assignee}:
                      </th>
                      <td className="text-muted">
                        {vendor?.assignee ? vendor.assignee.fullName : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {vendorText.field.notes}:
                      </th>
                      <td className="text-muted">{vendor?.notes || "-"}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailVendorGeneral;
