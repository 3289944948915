import { RetrieveUsersArgs, useRetrieveUsersQuery } from "app/services/user";

export const useRetrieveUsers = (args: RetrieveUsersArgs) => {
  const result = useRetrieveUsersQuery(args);
  return {
    users: result.data?.data.data,
    isLoadingUsers: result.isLoading,
    ...result,
  };
};
