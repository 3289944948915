import {
  AttachmentAction,
  AttachmentActionDropdown,
} from "app/components/molecules/AttachmentActionDropdown";
import { intl } from "app/helpers";
import { mapSource } from "app/helpers/utils";
import { FileDTO } from "app/models";
import { FC } from "react";
import { Badge, Col } from "reactstrap";

export interface SidebarDocumentGridItemProps {
  document: FileDTO;
  onActionClick: (action: AttachmentAction, data?: FileDTO) => void;
}

export const SidebarDocumentGridItem: FC<SidebarDocumentGridItemProps> = ({
  document,
  onActionClick,
}) => {
  return (
    <Col xs={6}>
      <div
        style={{
          height: 90,
          overflowClipMargin: "unset",
          backgroundColor: "#f5f5f5",
        }}
        className="w-100 rounded-3 d-flex align-items-center justify-content-center"
      >
        <i className="ri-file-3-fill fs-24 text-muted" />
      </div>
      <Badge color="primary">{mapSource(document.moduleType)}</Badge>
      <div className="d-flex justify-content-between">
        <div className="text-truncate">{document.fileName}</div>
        <AttachmentActionDropdown
          data={document}
          onAction={onActionClick}
          isDocument
        />
      </div>
      <p className="fs-10 mb-0">{intl.formatDate(document.createdAt)}</p>
    </Col>
  );
};
