import { PagingData } from "./../types";
import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { Customer } from "./types";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";

export const RETRIEVE_CUSTOMERS_URL = "/api/customers";

export interface RetrieveCustomersArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveCustomersResponse = Response<PagingData<Customer[]>>;

export const retrieveCustomers = (args: RetrieveCustomersArgs) => {
  return httpClient.get<RetrieveCustomersResponse>(RETRIEVE_CUSTOMERS_URL, {
    params: args,
  });
};

export const useRetrieveCustomersQuery = (
  retrieveCustomersArgs: RetrieveCustomersArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveCustomersResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveCustomersResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_CUSTOMERS_URL, retrieveCustomersArgs],
    queryFn: () => retrieveCustomers(retrieveCustomersArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveCustomersInfiniteQuery = (
  retrieveCustomersArgs: RetrieveCustomersArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveCustomersResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveCustomersResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveCustomersResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_CUSTOMERS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveCustomersArgs;
      return retrieveCustomers({
        ...retrieveCustomersArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveCustomersArgs;
      let nextPageParam: RetrieveCustomersArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveCustomersArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
