import { Loading } from "app/components/molecules/Loading";
import { PropsWithChildren } from "react";
import { DetailPageBreadCrumb } from "./DetailPageBreadCrumb";
import { createDetailPageContext } from "./DetailPageContext";
import { DetailPageHeader } from "./DetailPageHeader";
import { DetailPageMain } from "./DetailPageMain";

export interface DetailPageProps<T> {
  loading?: boolean;
  data?: T;
}

const DetailPage = <T,>({
  children,
  data,
  loading,
}: DetailPageProps<T> & PropsWithChildren) => {
  const DetailPageContext = createDetailPageContext<T>();
  return (
    <DetailPageContext.Provider
      value={{
        data,
        loading,
      }}
    >
      <div className="page-detail-content">
        {loading ? (
          <Loading />
        ) : (
          <div className="page-content-wrapper overflow-hidden">{children}</div>
        )}
      </div>
    </DetailPageContext.Provider>
  );
};

export default Object.assign(DetailPage, {
  Header: DetailPageHeader,
  Main: DetailPageMain,
  BreadCrumb: DetailPageBreadCrumb,
});
