import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { RETRIEVE_FILES_URL } from "./retrieveFiles";

export const DELETE_FILE_URL = (id: ID) => `/api/attachments/${id}`;

export enum DeleteFileError {}

export const deletedFileErrorMessages = {};

export type DeleteFileResponse = Response<unknown>;

export type DeleteFileArgs = {
  id: ID;
};

export const deleteFile = ({ id }: DeleteFileArgs) => {
  return httpClient.delete<DeleteFileResponse>(DELETE_FILE_URL(id));
};

export const useDeleteFile = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteFileResponse>,
    AxiosError<Response<null>>,
    DeleteFileArgs
  >,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [DELETE_FILE_URL],
    mutationFn: (deletedFileArgs: DeleteFileArgs) =>
      deleteFile(deletedFileArgs),
    ...opts,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_FILES_URL] });
      opts?.onSuccess?.(...args);
    },
  });
};
