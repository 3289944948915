import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { mapDefaultUser } from "../../../user/mapper";
import { UserForm, UserFormData } from "./UserForm";
import { useAppTranslation } from "app/hooks";

export interface UserFormModalProps {
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: UserFormData) => void;
  submitting?: boolean;
}

export const UserFormModal: FC<UserFormModalProps> = ({
  onToggle,
  open = false,
  onSubmit,
  submitting,
}) => {
  const { employeeText } = useAppTranslation();
  const title = employeeText.add;

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <UserForm
          onSubmit={onSubmit}
          onToggle={onToggle}
          defaultValues={mapDefaultUser()}
          submitting={submitting}
        />
      </ModalBody>
    </Modal>
  );
};
