import { useTranslation } from "react-i18next";

export const useAppTranslation = () => {
  const { t, i18n } = useTranslation();

  const placeHolderText = {
    search: t("placeholder.search"),
    input: t("placeholder.input"),
    select: t("placeholder.select"),
    date: t("placeholder.date"),
    createNew: t("placeholder.createNew"),
  };

  const uploadFileText = {
    title: t("uploadFile.title"),
    deleteBtn: t("uploadFile.deleteBtn"),
    imageTitle: t("uploadFile.imageTitle"),
    documentTitle: t("uploadFile.documentTitle"),
  };

  const buttonText = {
    cancel: t("button.cancel"),
    save: t("button.save"),
    edit: t("button.edit"),
    delete: t("button.delete"),
    add: t("button.add"),
    detail: t("button.detail"),
    back: t("button.back"),
    confirm: t("button.confirm"),
    duplicate: t("button.duplicate"),
    download: t("button.download"),
    upload: t("button.upload"),
    active: t("button.active"),
    deactive: t("button.deactive"),
    view: t("button.view"),
  };

  const confirmText = {
    deleteTitle: (name: string) => t("confirm.delete.title", { name }),
    deleteContent: (name: string) => t("confirm.delete.content", { name }),
  };

  const limitPageText = {
    show: t("limitPage.show"),
    in: t("limitPage.in"),
    result: t("limitPage.result"),
  };

  const noDataTableText = t("noDataTable");

  const menuText = {
    title: t("menu.title"),
    dashboard: t("menu.dashboar"),
    employee: t("menu.employee"),
    user: t("menu.user"),
    vendor: t("menu.vendor"),
    customer: t("menu.customer"),
    order: t("menu.order"),
    product: t("menu.product"),
    role: t("menu.role"),
    masterData: t("menu.masterData"),
  };

  const authText = {
    login: t("auth.login"),
    titleLogin: t("auth.titleLogin"),
    username: t("auth.username"),
    password: t("auth.password"),
    forgotPassword: t("auth.forgotPassword"),
    logout: t("auth.logout"),
    userInfo: t("auth.userInfo"),
    greeting: t("auth.greeting"),
    oldPassword: t("auth.oldPassword"),
    newPassword: t("auth.newPassword"),
    confirmPassword: t("auth.confirmPassword"),
    changePassword: t("auth.changePassword"),
  };

  const employeeText = {
    title: t("employee.title"),
    add: t("employee.add"),
    edit: t("employee.edit"),
    addAccount: t("employee.addAccount"),
    detail: {
      title: t("employee.detail.title"),
      infomation: t("employee.detail.infomation"),
      tab: {
        general: t("employee.detail.tab.general"),
      },
    },
    field: {
      fullName: t("employee.field.fullName"),
      firstName: t("employee.field.firstName"),
      middleName: t("employee.field.middleName"),
      lastName: t("employee.field.lastName"),
      employeeCode: t("employee.field.employeeCode"),
      username: t("employee.field.username"),
      email: t("employee.field.email"),
      phone: t("employee.field.phone"),
      jobTitle: t("employee.field.jobTitle"),
      department: t("employee.field.department"),
      gender: t("employee.field.gender"),
      dateOfBirth: t("employee.field.dateOfBirth"),
      taxCode: t("employee.field.taxCode"),
      nationalId: t("employee.field.nationalId"),
      dateOfIssue: t("employee.field.dateOfIssue"),
      placeOfIssue: t("employee.field.placeOfIssue"),
      permanentAddress: t("employee.field.permanentAddress"),
      dateOfJoining: t("employee.field.dateOfJoining"),
      dateOfLeaving: t("employee.field.dateOfLeaving"),
      avatar: t("employee.field.avatar"),
    },
    error: {
      departmentRequired: t("employee.error.departmentRequired"),
      jobTitleRequired: t("employee.error.jobTitleRequired"),
      employeeCodeRequired: t("employee.error.employeeCodeRequired"),
      firstNameRequired: t("employee.error.firstNameRequired"),
      lastNameRequired: t("employee.error.lastNameRequired"),
      emailRequired: t("employee.error.emailRequired"),
      emailInvalid: t("employee.error.emailInvalid"),
      phoneRequired: t("employee.error.phoneRequired"),
    },
  };

  const userText = {
    title: t("user.title"),
    field: {
      username: t("user.field.username"),
      fullName: t("user.field.fullName"),
      role: t("user.field.role"),
      status: t("user.field.status"),
      password: t("user.field.password"),
    },
  };

  const vendorText = {
    title: t("vendor.title"),
    add: t("vendor.add"),
    edit: t("vendor.edit"),
    detail: {
      infomation: t("vendor.detail.infomation"),
      title: t("vendor.detail.title"),
      tab: {
        general: t("vendor.detail.tab.general"),
      },
    },
    field: {
      vendorCode: t("vendor.field.vendorCode"),
      vendorName: t("vendor.field.vendorName"),
      vendorType: t("vendor.field.vendorType"),
      address: t("vendor.field.address"),
      phoneNumber: t("vendor.field.phoneNumber"),
      email: t("vendor.field.email"),
      website: t("vendor.field.website"),
      representative: t("vendor.field.representative"),
      representativeRole: t("vendor.field.representativeRole"),
      notes: t("vendor.field.notes"),
      startDate: t("vendor.field.startDate"),
      vendorStatus: t("vendor.field.vendorStatus"),
      assignee: t("vendor.field.assignee"),
    },
  };

  const customerText = {
    title: t("customer.title"),
    add: t("customer.add"),
    edit: t("customer.edit"),
    detail: {
      infomation: t("customer.detail.infomation"),
      title: t("customer.detail.title"),
      tab: {
        general: t("customer.detail.tab.general"),
      },
    },
    field: {
      customerCode: t("customer.field.customerCode"),
      customerName: t("customer.field.customerName"),
      customerType: t("customer.field.customerType"),
      address: t("customer.field.address"),
      phoneNumber: t("customer.field.phoneNumber"),
      email: t("customer.field.email"),
      website: t("customer.field.website"),
      representative: t("customer.field.representative"),
      representativeRole: t("customer.field.representativeRole"),
      paymentTerms: t("customer.field.paymentTerms"),
      depositPercentage: t("customer.field.depositPercentage"),
      paymentDueDays: t("customer.field.paymentDueDays"),
      notes: t("customer.field.notes"),
      startDate: t("customer.field.startDate"),
      customerStatus: t("customer.field.customerStatus"),
      assignee: t("customer.field.assignee"),
      avatar: t("customer.field.avatar"),
    },
    error: {
      customerNameRequired: t("customer.error.customerNameRequired"),
      emailInvalid: t("customer.error.emailInvalid"),
      websiteInvalid: t("customer.error.websiteInvalid"),
      depositPercentageInvalid: t("customer.error.depositPercentageInvalid"),
      paymentDueDaysInvalid: t("customer.error.paymentDueDaysInvalid"),
    },
  };

  const orderText = {
    title: t("order.title"),
    add: t("order.add"),
    edit: t("order.edit"),
    detail: {
      infomation: t("order.detail.infomation"),
      title: t("order.detail.title"),
      tab: {
        general: t("order.detail.tab.general"),
        orderItems: t("order.detail.tab.orderItems"),
      },
    },
    field: {
      orderCode: t("order.field.orderCode"),
      orderDate: t("order.field.orderDate"),
      customer: t("order.field.customer"),
      expectedDeliveryDate: t("order.field.expectedDeliveryDate"),
      notes: t("order.field.notes"),
      productInfomation: t("order.field.productInfomation"),
      product: t("order.field.product"),
      quantity: t("order.field.quantity"),
      deliveryDate: t("order.field.deliveryDate"),
      image: t("order.field.image"),
      document: t("order.field.document"),
    },
  };

  const productText = {
    title: t("product.title"),
    add: t("product.add"),
    edit: t("product.edit"),
    detail: {
      infomation: t("product.detail.infomation"),
      generalInfomation: t("product.detail.generalInfomation"),
      detailInfomation: t("product.detail.detailInfomation"),
      title: t("product.detail.title"),
      tab: {
        general: t("product.detail.tab.general"),
        orders: t("product.detail.tab.orders"),
        bom: t("product.detail.tab.bom"),
      },
    },
    field: {
      code: t("product.field.code"),
      name: t("product.field.name"),
      nameEn: t("product.field.nameEn"),
      nameVn: t("product.field.nameVn"),
      type: t("product.field.type"),
      group: t("product.field.group"),
      unit: t("product.field.unit"),
      countryOfOrigin: t("product.field.countryOfOrigin"),
      description: t("product.field.description"),
      lengthCm: t("product.field.lengthCm"),
      widthCm: t("product.field.widthCm"),
      heightCm: t("product.field.heightCm"),
      packagingLengthCm: t("product.field.packagingLengthCm"),
      packagingWidthCm: t("product.field.packagingWidthCm"),
      packagingHeightCm: t("product.field.packagingHeightCm"),
      seatSizeCm: t("product.field.seatSizeCm"),
      weightKg: t("product.field.weightKg"),
      boxQuantity: t("product.field.boxQuantity"),
      color: t("product.field.color"),
      isActive: t("product.field.isActive"),
      avatar: t("product.field.avatar"),
      isBom: t("product.field.isBom"),
      itemInfoTitle: t("product.field.itemInfoTitle"),
      product: t("product.field.product"),
      quantity: t("product.field.quantity"),
    },
  };

  const roleText = {
    title: t("role.title"),
    add: t("role.add"),
    edit: t("role.edit"),
    field: {
      name: t("role.field.name"),
      description: t("role.field.description"),
      permission: t("role.field.permission"),
    },
    error: {
      nameRequired: "Role name is required",
    },
  };

  const masterDataText = {
    title: t("masterData.title"),
    add: t("masterData.add"),
    edit: t("masterData.edit"),
    field: {
      name: t("masterData.field.name"),
      description: t("masterData.field.description"),
      type: t("masterData.field.type"),
      color: t("masterData.field.color"),
    },
  };

  const bomProductText = {
    title: t("bomProduct.title"),
    add: t("bomProduct.add"),
    edit: t("bomProduct.edit"),
    field: {
      bomCode: t("bomProduct.field.bomCode"),
      storage: t("bomProduct.field.storage"),
      productionLine: t("bomProduct.field.productionLine"),
      version: t("bomProduct.field.version"),
      quantity: t("bomProduct.field.quantity"),
    },
    detail: {
      infomation: t("bomProduct.detail.infomation"),
      bomStructure: t("bomProduct.detail.bomStructure"),
      title: t("bomProduct.detail.title"),
      tab: {
        general: t("bomProduct.detail.tab.general"),
        bomProductItems: t("bomProduct.detail.tab.bomProductItems"),
      },
    },
  };

  return {
    t,
    uploadFileText,
    i18n,
    authText,
    employeeText,
    placeHolderText,
    menuText,
    limitPageText,
    noDataTableText,
    buttonText,
    confirmText,
    userText,
    vendorText,
    customerText,
    orderText,
    productText,
    roleText,
    masterDataText,
    bomProductText,
  };
};
