import { CreateRoleArgs, UpdateRoleArgs } from "app/services/role";
import { WithID } from "app/models";
import { RoleFormData } from "./components";

export const mapper = ({
  name,
  description,
  permission,
}: RoleFormData): CreateRoleArgs | UpdateRoleArgs => {
  return {
    name,
    description: description || "",
    permissionIds: permission || [],
  };
};

export const mapCreateRole = (data: RoleFormData): CreateRoleArgs => {
  return mapper(data);
};

export const mapUpdateRole = ({
  id,
  ...data
}: WithID<RoleFormData>): UpdateRoleArgs => {
  return { id, ...mapper(data) };
};
