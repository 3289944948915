import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { Employee } from "./types";

export const RETRIEVE_EMPLOYEES_URL = "/api/employees";

export interface RetrieveEmployeesArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveEmployeesResponse = Response<PagingData<Employee[]>>;

export const retrieveEmployees = (args: RetrieveEmployeesArgs) => {
  return httpClient.get<RetrieveEmployeesResponse>(RETRIEVE_EMPLOYEES_URL, {
    params: args,
  });
};

export const useRetrieveEmployeesQuery = (
  retrieveEmployeesArgs: RetrieveEmployeesArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveEmployeesResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveEmployeesResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_EMPLOYEES_URL, retrieveEmployeesArgs],
    queryFn: () => retrieveEmployees(retrieveEmployeesArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveEmployeesInfiniteQuery = (
  retrieveEmployeesArgs: RetrieveEmployeesArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveEmployeesResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveEmployeesResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveEmployeesResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_EMPLOYEES_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveEmployeesArgs;
      return retrieveEmployees({
        ...retrieveEmployeesArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveEmployeesArgs;
      let nextPageParam: RetrieveEmployeesArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveEmployeesArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
