import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE, intl } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  VendorAction,
  VendorActionDropdown,
} from "app/modules/vendor/components";
import { useDeleteVendor, useRetrieveVendors } from "app/modules/vendor/hooks";
import { Vendor } from "app/services/vendor";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

interface ListVendorProps {}

const ListVendor: FC<ListVendorProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { vendorText, confirmText } = useAppTranslation();

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: DEFAULT_PAGE,
      initialLimit: DEFAULT_LIMIT_PAGE,
      initialSearchString: "",
    });

  const { vendors, isLoadingVendors } = useRetrieveVendors({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteVendor } = useDeleteVendor();

  const handleActionClick = (action: VendorAction, data?: Vendor) => {
    switch (action) {
      case "edit":
        if (data) {
          navigate(`/vendor/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/vendor/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(vendorText.title),
            description: confirmText.deleteContent(vendorText.title),
            onConfirm: () => deleteVendor({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Vendor>[] = useMemo(
    () => [
      {
        header: vendorText.field.vendorCode,
        accessorKey: "vendorCode",
      },
      {
        header: vendorText.field.vendorName,
        accessorKey: "vendorName",
      },
      {
        header: vendorText.field.vendorType,
        accessorKey: "vendorType",
        cell: ({ row }) => row.original.vendorType?.name || "-",
      },
      {
        header: vendorText.field.address,
        accessorKey: "address",
        cell: ({ row }) => row.original.address || "-",
      },
      {
        header: vendorText.field.phoneNumber,
        accessorKey: "phoneNumber",
        cell: ({ row }) => row.original.phoneNumber || "-",
      },
      {
        header: vendorText.field.email,
        accessorKey: "email",
        cell: ({ row }) => row.original.email || "-",
      },
      {
        header: vendorText.field.website,
        accessorKey: "website",
        cell: ({ row }) =>
          row.original.website ? (
            <a href={row.original.website} target="_blank" rel="noreferrer">
              {row.original.website}
            </a>
          ) : (
            "-"
          ),
      },
      {
        header: vendorText.field.representative,
        accessorKey: "representative",
        cell: ({ row }) => row.original.representative || "-",
      },
      {
        header: vendorText.field.representativeRole,
        accessorKey: "representativeRole",
        size: 200,
        cell: ({ row }) => row.original.representativeRole || "-",
      },
      {
        header: vendorText.field.startDate,
        accessorKey: "startDate",
        cell: ({ row }) =>
          row.original.startDate
            ? intl.formatDate(new Date(row.original.startDate))
            : "-",
      },
      {
        header: vendorText.field.vendorStatus,
        accessorKey: "vendorStatus",
        size: 200,
        cell: ({ row }) => row.original.vendorStatus?.name || "-",
      },
      {
        header: vendorText.field.assignee,
        accessorKey: "assignee",
        cell: ({ row }) => {
          const assignee = row.original.assignee;
          return assignee ? assignee.fullName : "-";
        },
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <VendorActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [vendorText],
  );

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={vendorText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <DebouncedInput
                  name="list-vendor-search"
                  value={searchString || ""}
                  onChange={(value) => {
                    setSearchString(String(value));
                  }}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => navigate("/vendor/new")}
              >
                {vendorText.add}
              </Button>
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<Vendor>
            columns={columns}
            data={vendors?.result || []}
            loading={isLoadingVendors}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={vendors?.total || 0}
            onRowClick={(row) => handleActionClick("detail", row)}
          />
        </ListPage.Main>
      </ListPage>
    </React.Fragment>
  );
};

export default ListVendor;
