import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";

export interface DepartmentFormProps {
  defaultValues?: DepartmentFormData;
  onSubmit: (data: DepartmentFormData) => void;
  submitting?: boolean;
  onToggle: () => void;
}

export interface DepartmentFormData {
  name: string;
}

export const DepartmentForm: FC<DepartmentFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onToggle,
}) => {
  const { control, handleSubmit } = useForm<DepartmentFormData>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().required("Vui lòng nhập tên"),
      }),
    ),
    defaultValues,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  return (
    <Form onSubmit={submit}>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="name" className="form-label">
              Tên
            </Label>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Input
                    {...field}
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Nhập"
                    invalid={!!fieldState.error}
                  />
                  <FormFeedback type="invalid">
                    {fieldState.error?.message}
                  </FormFeedback>
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button color="light" onClick={onToggle}>
              Hủy
            </Button>
            <Button type="submit" color="primary" loading={submitting}>
              Lưu
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
