import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  deactiveProductErrorMessages,
  RETRIEVE_PRODUCT_BY_ID_URL,
  RETRIEVE_PRODUCTS_URL,
  useDeactiveProductMutation,
} from "app/services/product";

interface UseDeactiveProductProps {
  id?: ID;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDeactiveProduct = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
}: UseDeactiveProductProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useDeactiveProductMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_PRODUCTS_URL] });
      if (id)
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_PRODUCT_BY_ID_URL(id)],
        });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          deactiveProductErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
