import {
  RetrieveProductsArgs,
  useRetrieveProductsQuery,
} from "app/services/product";

export const useRetrieveProducts = (args: RetrieveProductsArgs) => {
  const result = useRetrieveProductsQuery(args);
  return {
    products: result.data?.data.data,
    isLoadingProducts: result.isLoading,
    ...result,
  };
};
