import React from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";
import * as Sentry from "@sentry/react";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import AppProvider from "AppProvider";

if (
  import.meta.env.VITE_ENV === "production" ||
  import.meta.env.VITE_ENV === "staging"
) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENV,
  });
}

// const firebaseConfig = {
//   apiKey: import.meta.env.VITE_APIKEY,
//   authDomain: import.meta.env.VITE_AUTHDOMAIN,
//   databaseURL: import.meta.env.VITE_DATABASEURL,
//   projectId: import.meta.env.VITE_PROJECTID,
//   storageBucket: import.meta.env.VITE_STORAGEBUCKET,
//   messagingSenderId: import.meta.env.VITE_MESSAGINGSENDERID,
//   appId: import.meta.env.VITE_APPID,
//   measurementId: import.meta.env.VITE_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

function App() {
  return (
    <React.Fragment>
      <AppProvider>
        <Route />
      </AppProvider>
    </React.Fragment>
  );
}

export default App;
