import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { Source } from "app/models";
import { getDeleteFileIds, getUploadedFileIds } from "app/modules/file/helpers";
import { OrderForm, OrderFormData } from "app/modules/order/components";
import { useRetrieveOrderById, useUpdateOrder } from "app/modules/order/hooks";
import { mapDefaultOrder, mapUpdateOrder } from "app/modules/order/mapper";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateOrderProps {}

const UpdateOrder: FC<UpdateOrderProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { orderText } = useAppTranslation();

  const { order, isLoading } = useRetrieveOrderById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateOrder({
    id,
    onSuccess: () => {
      navigate(-1);
    },
  });

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const { mutateAsync: deleteFile } = useDeleteFile();

  const handleSubmit = async (data: OrderFormData) => {
    const deleteImageIds = getDeleteFileIds(order?.images, data.images);
    const deleteDocumentIds = getDeleteFileIds(
      order?.documents,
      data.documents,
    );
    const imageIds =
      (await getUploadedFileIds({
        data: data.images,
        fileKey: "image",
        source: Source.Order,
        upload,
      })) || [];
    const documentIds =
      (await getUploadedFileIds({
        data: data.documents,
        fileKey: "document",
        source: Source.Order,
        upload,
      })) || [];
    await mutateAsync(
      mapUpdateOrder({
        id: id as string,
        deleteFileIds: [...deleteImageIds, ...deleteDocumentIds],
        files: [...imageIds, ...documentIds],
        ...data,
      }),
      {
        onError: () => {
          imageIds?.map((imageId) => {
            deleteFile({ id: imageId });
          });
        },
      },
    );
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb title={orderText.edit} pageTitle={orderText.title} isBack />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <OrderForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultOrder(order)}
              submitting={isPending || isUploading}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateOrder;
