import {
  RetrieveBomProductsArgs,
  useRetrieveBomProductsQuery,
} from "app/services/bomProduct";

export const useRetrieveBomProducts = (args: RetrieveBomProductsArgs) => {
  const result = useRetrieveBomProductsQuery(args);
  return {
    bomProducts: result.data?.data.data,
    isLoadingBomProducts: result.isLoading,
    ...result,
  };
};
