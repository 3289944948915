import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import { RETRIEVE_EMPLOYEES_URL } from "app/services/employee";
import {
  createUserErrorMessages,
  RETRIEVE_USERS_URL,
  useCreateUserMutation,
} from "app/services/user";

interface UseCreateUserProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreateUser = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseCreateUserProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateUserMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_USERS_URL] });
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_EMPLOYEES_URL] });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createUserErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
