import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { JobTitle } from "./types";

export const CREATE_JOB_TITLE_URL = "/api/job-titles";

export enum CreateJobTitleError {}

export const createJobTitleErrorMessages = {};

export interface CreateJobTitleArgs {}

export type CreateJobTitleResponse = Response<JobTitle>;

export const createJobTitle = (args: CreateJobTitleArgs) => {
  return httpClient.post<CreateJobTitleResponse>(CREATE_JOB_TITLE_URL, args);
};

export const useCreateJobTitleMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateJobTitleResponse>,
      AxiosError<any>,
      CreateJobTitleArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateJobTitleResponse>,
    AxiosError<any>,
    CreateJobTitleArgs
  >({
    mutationFn: createJobTitle,
    mutationKey: [CREATE_JOB_TITLE_URL],
    ...opts,
  });
