import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Role } from "./types";
import { Response } from "../types";

export const RETRIEVE_ROLE_BY_ID_URL = (id: ID) => `/api/roles/${id}`;

export type RetrieveRoleByIdResponse = Response<Role>;

export type RetrieveRoleByIdArgs = {
  id: ID;
};

export const retrieveRoleById = ({ id }: RetrieveRoleByIdArgs) => {
  return httpClient.get<RetrieveRoleByIdResponse>(RETRIEVE_ROLE_BY_ID_URL(id));
};

export const useRetrieveRoleByIdQuery = (
  retrieveRolesArgs: RetrieveRoleByIdArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveRoleByIdResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveRoleByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_ROLE_BY_ID_URL(retrieveRolesArgs.id)],
    queryFn: () => retrieveRoleById(retrieveRolesArgs),
    ...opts,
  });
