import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { User } from "./types";
import { Response } from "../types";

export const RETRIEVE_USER_BY_ID_URL = (id: ID) => `/api/users/${id}`;

export type RetrieveUserByIdResponse = Response<User>;

export type RetrieveUserByIdArgs = {
  id: ID;
};

export const retrieveUserById = ({ id }: RetrieveUserByIdArgs) => {
  return httpClient.get<RetrieveUserByIdResponse>(RETRIEVE_USER_BY_ID_URL(id));
};

export const useRetrieveUserByIdQuery = (
  retrieveUsersArgs: RetrieveUserByIdArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveUserByIdResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveUserByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_USER_BY_ID_URL(retrieveUsersArgs.id)],
    queryFn: () => retrieveUserById(retrieveUsersArgs),
    ...opts,
  });
