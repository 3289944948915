import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { FileDTO } from "app/models";
import { FC, useState } from "react";
import { Collapse } from "reactstrap";
import { SidebarDocument } from "../SidebarDocument";
import { SidebarImage } from "../SidebarImage";

interface AttachmentItemProps {
  data: FileDTO[];
  loading?: boolean;
  uploading?: boolean;
  onUpload?: (files: File[]) => Promise<any>;
  onDelete?: (id: string) => Promise<void>;
  fetchingProps?: Pick<
    UseInfiniteQueryResult,
    "hasNextPage" | "isFetchingNextPage" | "fetchNextPage"
  >;
}

export interface AttachmentBarProps {
  imageProps: AttachmentItemProps;
  documentProps: AttachmentItemProps;
  onSearch?: (search: string) => void;
}

export const AttachmentBar: FC<AttachmentBarProps> = ({
  imageProps,
  documentProps,
  onSearch,
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [activeTabSidebar, setActiveTabSidebar] = useState<
    "IMG" | "DOC" | null
  >(null);

  const handleToggleTabSidebar = (tab: "IMG" | "DOC") => () => {
    if (activeTabSidebar !== tab) {
      setSidebarOpen(true);
      setActiveTabSidebar(tab);
      onSearch?.("");
    } else {
      setSidebarOpen(false);
      setTimeout(() => {
        setActiveTabSidebar(null);
      }, 175);
    }
  };

  return (
    <div className="attachment-sidebar-container border">
      <div>
        <Collapse className="h-100" horizontal isOpen={!!isSidebarOpen}>
          <div className="attachment-content h-100 overflow-hidden">
            {activeTabSidebar === "DOC" ? (
              <SidebarDocument
                documentProps={documentProps}
                onSearch={onSearch}
              />
            ) : null}
            {activeTabSidebar === "IMG" ? (
              <SidebarImage imageProps={imageProps} onSearch={onSearch} />
            ) : null}
          </div>
        </Collapse>
      </div>
      <div className="attachment-sidebar border-start">
        <div className="d-flex flex-column">
          <button
            onClick={handleToggleTabSidebar("IMG")}
            className="border-bottom btn btn-icon w-100"
          >
            <i className="fs-20 ri-image-line" />
          </button>
          <button
            onClick={handleToggleTabSidebar("DOC")}
            className="border-bottom btn btn-icon w-100"
          >
            <i className="fs-20 ri-file-3-line" />
          </button>
        </div>
      </div>
    </div>
  );
};
