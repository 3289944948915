import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "app/components/organisms/DataTable";
import { intl } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import { useRetrieveOrderById } from "app/modules/order/hooks";
import { OrderItem } from "app/services/order";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

export interface DetailOrderOrderItemsProps {}

const DetailOrderOrderItems: FC<DetailOrderOrderItemsProps> = () => {
  const { id } = useParams();
  const { orderText } = useAppTranslation();

  const { order, isLoadingOrder } = useRetrieveOrderById(
    { id: id as string },
    { enabled: !!id },
  );

  const orderItems = order?.orderItems;

  const columns: ColumnDef<OrderItem>[] = useMemo(
    () => [
      {
        header: orderText.field.product,
        accessorKey: "product",
        cell: ({ row }) => row.original.product?.nameVn || "-",
      },
      {
        header: orderText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
      {
        header: orderText.field.expectedDeliveryDate,
        accessorKey: "deliveryDate",
        cell: ({ row }) =>
          row.original.deliveryDate
            ? intl.formatDate(row.original.deliveryDate)
            : "-",
      },
    ],
    [orderText],
  );

  return (
    <div className="p-3 py-0 h-100 d-flex flex-column">
      <DataTable<OrderItem>
        columns={columns}
        data={orderItems || []}
        loading={isLoadingOrder}
        total={orderItems?.length || 0}
        isHiddenPagination
      />
    </div>
  );
};

export default DetailOrderOrderItems;
