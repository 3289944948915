import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  updateCustomerErrorMessages,
  RETRIEVE_CUSTOMERS_URL,
  useUpdateCustomerMutation,
  RETRIEVE_CUSTOMER_BY_ID_URL,
} from "app/services/customer";

interface useCreateCustomerProps {
  onSuccess?: () => void;
  onError?: () => void;
  id?: ID; // to invalidate the cache
}

export const useUpdateCustomer = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
}: useCreateCustomerProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useUpdateCustomerMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_CUSTOMERS_URL] });
      if (id) {
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_CUSTOMER_BY_ID_URL(id)],
        });
      }
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          updateCustomerErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
