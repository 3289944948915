import {
  RetrieveDepartmentsArgs,
  useRetrieveDepartmentsQuery,
} from "app/services/department";

export const useRetrieveDepartments = (args: RetrieveDepartmentsArgs) => {
  const result = useRetrieveDepartmentsQuery(args);
  return {
    departments: result.data?.data.data,
    isLoadingDepartments: result.isLoading,
    ...result,
  };
};
