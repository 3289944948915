import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { MasterData, MasterDataType } from "./types";

export const CREATE_MASTER_DATA_URL = "/api/master-data";

export enum CreateMasterDataError {}

export const createMasterDataErrorMessages = {};

export interface CreateMasterDataArgs {
  type: MasterDataType;
  name: string;
  description: string | null;
  colorCode: string;
}

export type CreateMasterDataResponse = Response<MasterData>;

export const createMasterData = (args: CreateMasterDataArgs) => {
  return httpClient.post<CreateMasterDataResponse>(
    CREATE_MASTER_DATA_URL,
    args,
  );
};

export const useCreateMasterDataMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateMasterDataResponse>,
      AxiosError<any>,
      CreateMasterDataArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateMasterDataResponse>,
    AxiosError<any>,
    CreateMasterDataArgs
  >({
    mutationFn: createMasterData,
    mutationKey: [CREATE_MASTER_DATA_URL],
    ...opts,
  });
