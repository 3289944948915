import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Department } from "./types";

export const DELETE_DEPARTMENT_URL = (id: ID) => `/api/departments/${id}`;

export enum DeleteDepartmentError {}

export const deletedDepartmentErrorMessages = {};

export type DeleteDepartmentResponse = Response<Department>;

export type DeleteDepartmentArgs = {
  id: ID;
};

export const deleteDepartment = ({ id }: DeleteDepartmentArgs) => {
  return httpClient.delete<DeleteDepartmentResponse>(DELETE_DEPARTMENT_URL(id));
};

export const useDeleteDepartmentMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteDepartmentResponse>,
    AxiosError<Response<null>>,
    DeleteDepartmentArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_DEPARTMENT_URL],
    mutationFn: (deletedDepartmentArgs: DeleteDepartmentArgs) =>
      deleteDepartment(deletedDepartmentArgs),
    ...opts,
  });
};
