import { Department } from "app/services/department";
import { FC, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type DepartmentAction = "edit" | "delete";

export interface DepartmentActionDropdownProps {
  data?: Department;
  onAction: (action: DepartmentAction, data?: Department) => void;
}

export const DepartmentActionDropdown: FC<DepartmentActionDropdownProps> = ({
  data,
  onAction,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: DepartmentAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  return (
    <Dropdown isOpen={isOpen} toggle={handleToggle} direction="start">
      <DropdownToggle tag="button" className="btn py-0 px-2 m-0">
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="p-0" onClick={handleAction("edit")}>
          <div className="dropdown-item">
            <i className="ri-pencil-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Sửa</span>
          </div>
        </DropdownItem>
        <DropdownItem className="p-0" onClick={handleAction("delete")}>
          <div className="dropdown-item">
            <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Xóa</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
