import { Loading } from "app/components/molecules/Loading";
import { FC, PropsWithChildren } from "react";
import { Col, Row } from "reactstrap";
import { useFormPageContext } from "./FormPageContext";

export interface FormPageMainProps {
  container?: boolean;
}

export const FormPageMain: FC<FormPageMainProps & PropsWithChildren> = ({
  children,
  container = true,
}) => {
  const { loading } = useFormPageContext();
  return container ? (
    <Row>
      <Col
        md={{
          offset: 2,
          size: 8,
        }}
        xl={{
          offset: 3,
          size: 6,
        }}
      >
        {loading ? <Loading /> : children}
      </Col>
    </Row>
  ) : (
    children
  );
};
