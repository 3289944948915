import { AttachmentBar } from "app/components/organisms/AttachmentBar";
import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import { useRetrieveBomProductById } from "app/modules/bomProduct/hooks";
import { BomProduct } from "app/services/bomProduct";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Outlet, useParams } from "react-router-dom";
import DetailBomProductHeader from "./DetailBomProductHeader";
import { DetailBomProductTabs } from "./DetailBomProductTabs";

const DetailBomProduct = () => {
  const { id } = useParams();
  const { bomProductText } = useAppTranslation();

  const { bomProduct, isLoadingBomProduct } = useRetrieveBomProductById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <DetailPage<BomProduct>
      data={bomProduct as BomProduct}
      loading={isLoadingBomProduct}
    >
      <DetailPage.BreadCrumb>
        <BreadCrumb
          title={bomProductText.detail.title}
          pageTitle={bomProductText.title}
        />
      </DetailPage.BreadCrumb>
      <DetailPage.Header>
        <DetailBomProductHeader />
      </DetailPage.Header>
      <DetailPage.Main
        tabs={<DetailBomProductTabs />}
        attachmentBar={
          <AttachmentBar
            imageProps={{ data: [] }}
            documentProps={{ data: [] }}
          />
        }
      >
        <Outlet />
      </DetailPage.Main>
    </DetailPage>
  );
};

export default DetailBomProduct;
