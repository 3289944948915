import * as Yup from "yup";

export const userNameSchema = Yup.string()
  .required("auth.error.usernameRequired")
  .test("is-valid-username", "auth.error.usernameInvalid", (value) => {
    if (typeof value === "number") {
      return true; // Valid if it's a number
    }
    if (typeof value === "string") {
      // Check if the string is either alphanumeric or a valid email
      const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(value);
      const isEmail = Yup.string().email().isValidSync(value);
      return isAlphanumeric || isEmail;
    }
    return false;
  });
