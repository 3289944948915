import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();

  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isEmployees, setIsEmployees] = useState(false);
  const [isRoles, setIsRoles] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isMasterDatas, setIsMasterDatas] = useState(false);
  const [isCustomers, setIsCustomers] = useState(false);
  const [isOrders, setIsOrders] = useState(false);
  const [isProducts, setIsProducts] = useState(false);
  const [isBomProducts, setIsBomProducts] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      const activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        const id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Employeess") {
      setIsEmployees(false);
    }
    if (iscurrentState !== "Roles") {
      setIsRoles(false);
    }
    if (iscurrentState !== "Users") {
      setIsUsers(false);
    }
    if (iscurrentState !== "MasterDatas") {
      setIsMasterDatas(false);
    }
    if (iscurrentState !== "Customers") {
      setIsCustomers(false);
    }
    if (iscurrentState !== "Orders") {
      setIsOrders(false);
    }
    if (iscurrentState !== "Products") {
      setIsProducts(false);
    }
    if (iscurrentState !== "BomProducts") {
      setIsBomProducts(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isEmployees,
    isRoles,
    isUsers,
    isMasterDatas,
    isCustomers,
    isOrders,
    isProducts,
  ]);

  const menuItems: any = [
    {
      label: "menu.title",
      isHeader: true,
    },
    // {
    //     id: "dashboard",
    //     label: "menu.dashboard",
    //     icon: "ri-dashboard-2-line",
    //     link: "/dashboard",
    //     stateVariables: isDashboard,
    //     click: function (e : any) {
    //         e.preventDefault();
    //         setIsDashboard(!isDashboard);
    //         setIscurrentState('Dashboard');
    //         updateIconSidebar(e);
    //     },
    // },
    {
      id: "employees",
      label: "menu.employee",
      icon: "ri-user-2-line",
      link: "/employees",
      stateVariables: isEmployees,
      click: function (e: any) {
        e.preventDefault();
        setIsEmployees(!isEmployees);
        setIscurrentState("Employees");
        updateIconSidebar(e);
      },
    },
    {
      id: "users",
      label: "menu.user",
      icon: "ri-user-2-line",
      link: "/users",
      stateVariables: isUsers,
      click: function (e: any) {
        e.preventDefault();
        setIsUsers(!isUsers);
        setIscurrentState("Users");
        updateIconSidebar(e);
      },
    },
    {
      id: "vendors",
      label: "menu.vendor",
      icon: "ri-store-line",
      link: "/vendors",
      stateVariables: isUsers,
      click: function (e: any) {
        e.preventDefault();
        setIsUsers(!isUsers);
        setIscurrentState("Users");
        updateIconSidebar(e);
      },
    },
    {
      id: "customers",
      label: "menu.customer",
      icon: "ri-team-line",
      link: "/customers",
      stateVariables: isCustomers,
      click: function (e: any) {
        e.preventDefault();
        setIsCustomers(!isCustomers);
        setIscurrentState("Customers");
        updateIconSidebar(e);
      },
    },
    {
      id: "orders",
      label: "menu.order",
      icon: "ri-shopping-cart-2-line",
      link: "/orders",
      stateVariables: isOrders,
      click: function (e: any) {
        e.preventDefault();
        setIsOrders(!isOrders);
        setIscurrentState("Orders");
        updateIconSidebar(e);
      },
    },
    {
      id: "products",
      label: "menu.product",
      icon: "bx bx-package",
      link: "/products",
      stateVariables: isProducts,
      click: function (e: any) {
        e.preventDefault();
        setIsProducts(!isProducts);
        setIscurrentState("Products");
        updateIconSidebar(e);
      },
    },
    {
      id: "roles",
      label: "menu.role",
      icon: "ri-user-settings-line",
      link: "/roles",
      stateVariables: isRoles,
      click: function (e: any) {
        e.preventDefault();
        setIsRoles(!isRoles);
        setIscurrentState("Roles");
        updateIconSidebar(e);
      },
    },
    {
      id: "masterDatas",
      label: "menu.masterData",
      icon: "ri-database-2-line",
      link: "/master-datas",
      stateVariables: isMasterDatas,
      click: function (e: any) {
        e.preventDefault();
        setIsMasterDatas(!isMasterDatas);
        setIscurrentState("MasterDatas");
        updateIconSidebar(e);
      },
    },
    // {
    //     id: "bombProducts",
    //     label: "menu.bomProduct",
    //     icon: "mdi mdi-file-tree",
    //     link: "/bom-products",
    //     stateVariables: isBomProducts,
    //     click: function (e : any) {
    //         e.preventDefault();
    //         setIsBomProducts(!isBomProducts);
    //         setIscurrentState('BomProducts');
    //         updateIconSidebar(e);
    //     },
    // },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
