import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { User } from "./types";

export const DELETE_USER_URL = (id: ID) => `/api/users/${id}`;

export enum DeleteUserError {}

export const deletedUserErrorMessages = {};

export type DeleteUserResponse = Response<User>;

export type DeleteUserArgs = {
  id: ID;
};

export const deleteUser = ({ id }: DeleteUserArgs) => {
  return httpClient.delete<DeleteUserResponse>(DELETE_USER_URL(id));
};

export const useDeleteUserMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteUserResponse>,
    AxiosError<Response<null>>,
    DeleteUserArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_USER_URL],
    mutationFn: (deletedUserArgs: DeleteUserArgs) =>
      deleteUser(deletedUserArgs),
    ...opts,
  });
};
