import { RetrieveRolesArgs, useRetrieveRolesQuery } from "app/services/role";

export const useRetrieveRoles = (args: RetrieveRolesArgs) => {
  const result = useRetrieveRolesQuery(args);
  return {
    roles: result.data?.data.data,
    isLoadingRoles: result.isLoading,
    ...result,
  };
};
