import { FC, PropsWithChildren } from "react";
import { Container } from "reactstrap";

export interface DetailPageBreadCrumbProps {}

export const DetailPageBreadCrumb: FC<
  DetailPageBreadCrumbProps & PropsWithChildren
> = ({ children }) => {
  return <Container fluid>{children}</Container>;
};
