import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Order } from "./types";
import { Response } from "../types";

export const RETRIEVE_ORDER_BY_ID_URL = (id: ID) => `/api/orders/${id}`;

export type RetrieveOrderByIdResponse = Response<Order>;

export type RetrieveOrderByIdArgs = {
  id: ID;
};

export const retrieveOrderById = ({ id }: RetrieveOrderByIdArgs) => {
  return httpClient.get<RetrieveOrderByIdResponse>(
    RETRIEVE_ORDER_BY_ID_URL(id),
  );
};

export const useRetrieveOrderByIdQuery = (
  retrieveOrdersArgs: RetrieveOrderByIdArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveOrderByIdResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveOrderByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_ORDER_BY_ID_URL(retrieveOrdersArgs.id)],
    queryFn: () => retrieveOrderById(retrieveOrdersArgs),
    ...opts,
  });
