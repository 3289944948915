import { useAppTranslation } from "app/hooks";
import { ChangeEventHandler, FC, forwardRef } from "react";
import { Input, InputProps } from "reactstrap";

export interface FormInputProps extends InputProps {
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const FormInput: FC<FormInputProps> = forwardRef<
  Input | null,
  FormInputProps
>(({ ...props }, ref) => {
  const { placeHolderText } = useAppTranslation();
  return <Input ref={ref} {...props} placeholder={placeHolderText.input} />;
});

FormInput.displayName = "FormInput";
