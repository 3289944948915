import { FC } from "react";
import { Badge, Col } from "reactstrap";
import { SidebarImageProps } from "./SidebarImage";
import { intl } from "app/helpers";
import { mapSource } from "app/helpers/utils";
import {
  AttachmentAction,
  AttachmentActionDropdown,
} from "app/components/molecules/AttachmentActionDropdown";
import { FileDTO } from "app/models";

export interface SidebarImageListItemProps {
  image: FileDTO;
  onActionClick: (action: AttachmentAction, data?: FileDTO) => void;
}

export const SidebarImageListItem: FC<SidebarImageListItemProps> = ({
  image,
  onActionClick,
}) => {
  return (
    <Col xs={12}>
      <div className="d-flex justify-content-between">
        <p className="fs-10 mb-0">{intl.formatDate(image.createdAt)}</p>
        <AttachmentActionDropdown data={image} onAction={onActionClick} />
      </div>
      <div className="d-flex align-items-center gap-2">
        <i className="ri-image-fill fs-24 text-muted" />
        <div className="text-truncate">{image.fileName}</div>
      </div>
      <Badge color="primary">{mapSource(image.moduleType)}</Badge>
    </Col>
  );
};
