import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { FileDTO, FileKey, Source } from "app/models";
import { AxiosError, AxiosResponse } from "axios";
import { PagingData, Response } from "../types";

export const RETRIEVE_FILES_URL = "/api/attachments";

export interface RetrieveFilesArgs {
  page?: number;
  limit?: number;
  searchString?: string;
  moduleType: Source;
  fileType?: FileKey;
  moduleId: ID;
}

export type RetrieveFilesResponse = Response<PagingData<FileDTO[]>>;

export const retrieveFiles = (args: RetrieveFilesArgs) => {
  return httpClient.get<RetrieveFilesResponse>(RETRIEVE_FILES_URL, {
    params: args,
  });
};

export const useRetrieveFilesQuery = (
  retrieveFilesArgs: RetrieveFilesArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveFilesResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveFilesResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_FILES_URL, retrieveFilesArgs],
    queryFn: () => retrieveFiles(retrieveFilesArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveFilesInfiniteQuery = (
  retrieveFilesArgs: RetrieveFilesArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveFilesResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): unknown => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveFilesResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveFilesResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_FILES_URL, "INFINITE_QUERY", retrieveFilesArgs],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveFilesArgs;
      return retrieveFiles({
        ...retrieveFilesArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveFilesArgs;
      let nextPageParam: RetrieveFilesArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveFilesArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
