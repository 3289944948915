import { useAppTranslation } from "app/hooks";
import { FileKey, FilePreview } from "app/models";
import { FC, useState } from "react";
import Dropzone from "react-dropzone";

export interface AvatarUploadProps {
  type?: FileKey;
  size?: "sm" | "md";
  value: FilePreview[];
  onChange?: (files: FilePreview[]) => void;
}

export const AvatarUpload: FC<AvatarUploadProps> = ({
  onChange,
  value,
  type = "IMAGE",
  size = "md",
}) => {
  const [selectedFiles, setselectedFiles] = useState<FilePreview[]>(value);
  const { uploadFileText } = useAppTranslation();
  function handleAcceptedFiles(files: File[]) {
    files.map((file: File) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    onChange?.([...selectedFiles, ...files]);
    setselectedFiles([...selectedFiles, ...files]);
  }

  const handleDelete = (file: File & { preview?: string }) => {
    const newFiles = selectedFiles.filter(
      (item) => item.preview !== file.preview,
    );
    onChange?.(newFiles);
    setselectedFiles(newFiles);
  };

  /**
   * Formats the size
   */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <>
      <Dropzone
        multiple={false}
        maxFiles={1}
        onDrop={(acceptedFiles) => {
          handleAcceptedFiles(acceptedFiles);
        }}
        accept={
          type === "IMAGE"
            ? { "image/*": [] }
            : {
                "application/msword": [],
                "application/vnd.ms-excel": [],
                "application/vnd.ms-powerpoint": [],
                "text/plain": [],
                "application/pdf": [],
                "image/*": [],
              }
        }
      >
        {({ getRootProps }) =>
          selectedFiles.length === 1 ? (
            <div className="dropzone avatar-zone col col-auto">
              <img
                data-dz-thumbnail=""
                className="rounded bg-light"
                alt={selectedFiles[0].name}
                src={selectedFiles[0].preview}
              />
              <i
                className="ri-close-line fs-20 text-white dropzone-remove-btn"
                onClick={() => handleDelete(selectedFiles[0])}
              />
            </div>
          ) : (
            <div className="dropzone avatar-zone dz-clickable cursor-pointer">
              {size === "md" ? (
                <div
                  className="dz-message needsclick m-0 px-2 h-100"
                  {...getRootProps()}
                >
                  <div className="mb-0">
                    <i className="fs-20 text-muted ri-upload-cloud-2-fill" />
                  </div>
                  <h6>{uploadFileText.title}</h6>
                </div>
              ) : (
                <div className="dz-message needsclick m-1" {...getRootProps()}>
                  <div className="mb-0 d-flex align-items-center gap-2">
                    <i className="text-muted fs-20 ri-upload-cloud-2-fill" />
                    <p className="fs-14 mb-0 text-muted">
                      {uploadFileText.title}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )
        }
      </Dropzone>
    </>
  );
};
