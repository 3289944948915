import {
  RetrieveMasterDatasArgs,
  useRetrieveMasterDatasQuery,
} from "app/services/masterData";

export const useRetrieveMasterDatas = (args: RetrieveMasterDatasArgs) => {
  const result = useRetrieveMasterDatasQuery(args);
  return {
    masterDatas: result.data?.data.data,
    isLoadingMasterDatas: result.isLoading,
    ...result,
  };
};
