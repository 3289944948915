import { Navigate } from "react-router-dom";

// App
import Dashboard from "app/components/pages/dashboard/Dashboards";

// Auth
import LoginMain from "app/components/pages/auth/Login";
import LogoutMain from "app/components/pages/auth/Logout";
import ResetPassword from "app/components/pages/auth/ResetPassword";

// Setting
import ProfileSetting from "app/components/pages/setting/ProfileSetting";

// Role
import ListRole from "app/components/pages/role/ListRole";

// Job Title
import ListJobTitle from "app/components/pages/jobTitle/ListJobTitle";

// Department
import ListDepartment from "app/components/pages/department/ListDepartment";

// Employee
import CreateEmployee from "app/components/pages/employee/CreateEmployee";
import DetailEmployee from "app/components/pages/employee/DetailEmployee";
import DetailEmployeeGeneral from "app/components/pages/employee/DetailEmployeeGeneral";
import ListEmployee from "app/components/pages/employee/ListEmployee";
import UpdateEmployee from "app/components/pages/employee/UpdateEmployee";

// User
import ListUser from "app/components/pages/user/ListUser";

// Master data
import ListMasterData from "app/components/pages/masterData/ListMasterData";

// Vendor
import CreateVendor from "app/components/pages/vendor/CreateVendor";
import DetailVendor from "app/components/pages/vendor/DetailVendor";
import DetailVendorGeneral from "app/components/pages/vendor/DetailVendorGeneral";
import ListVendor from "app/components/pages/vendor/ListVendor";
import UpdateVendor from "app/components/pages/vendor/UpdateVendor";

// Customer
import CreateCustomer from "app/components/pages/customer/CreateCustomer";
import DetailCustomer from "app/components/pages/customer/DetailCustomer";
import DetailCustomerGeneral from "app/components/pages/customer/DetailCustomerGeneral";
import ListCustomer from "app/components/pages/customer/ListCustomer";
import UpdateCustomer from "app/components/pages/customer/UpdateCustomer";

// Order
import CreateOrder from "app/components/pages/order/CreateOrder";
import DetailOrder from "app/components/pages/order/DetailOrder";
import DetailOrderGeneral from "app/components/pages/order/DetailOrderGeneral";
import DetailOrderOrderItems from "app/components/pages/order/DetailOrderOrderItems";
import DuplicateOrder from "app/components/pages/order/DuplicateOrder";
import ListOrder from "app/components/pages/order/ListOrder";
import UpdateOrder from "app/components/pages/order/UpdateOrder";

// Product
import CreateProduct from "app/components/pages/product/CreateProduct";
import DetailProduct from "app/components/pages/product/DetailProduct";
import DetailProductGeneral from "app/components/pages/product/DetailProductGeneral/DetailProductGeneral";
import DetailProductOrderItems from "app/components/pages/product/DetailProductOrderItems";
import ListProduct from "app/components/pages/product/ListProduct";
import UpdateProduct from "app/components/pages/product/UpdateProduct";

// Bom Product
import LoginPos from "app/components/pages/auth/LoginPos";
import CreateBomProduct from "app/components/pages/bomProduct/CreateBomProduct";
import DetailBomProduct from "app/components/pages/bomProduct/DetailBomProduct";
import DetailBomProductGeneral from "app/components/pages/bomProduct/DetailBomProductGeneral";
import DetailBomProductItems from "app/components/pages/bomProduct/DetailBomProductItems";
import ListBomProduct from "app/components/pages/bomProduct/ListBomProduct";
import DetailProductBom from "app/components/pages/product/DetailProductBom/DetailProductBom";

const appProtectedRoutes = [
  {
    index: true,
    component: <Navigate replace to="/employees" />,
  },
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/profile",
    component: <ProfileSetting />,
  },
  {
    path: "/roles",
    component: <ListRole />,
  },
  {
    path: "/job-titles",
    component: <ListJobTitle />,
  },
  {
    path: "/departments",
    component: <ListDepartment />,
  },
  {
    path: "/employee/new",
    component: <CreateEmployee />,
  },
  {
    path: "/employee/:id/update",
    component: <UpdateEmployee />,
  },
  {
    path: "/employees",
    component: <ListEmployee />,
  },
  {
    path: "/employee/:id",
    component: <DetailEmployee />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailEmployeeGeneral />,
      },
    ],
  },
  {
    path: "/users",
    component: <ListUser />,
  },
  {
    path: "/master-datas",
    component: <ListMasterData />,
  },
  {
    path: "/vendor/new",
    component: <CreateVendor />,
  },
  {
    path: "/vendor/:id/update",
    component: <UpdateVendor />,
  },
  {
    path: "/vendors",
    component: <ListVendor />,
  },
  {
    path: "/vendor/:id",
    component: <DetailVendor />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailVendorGeneral />,
      },
    ],
  },
  {
    path: "/customer/new",
    component: <CreateCustomer />,
  },
  {
    path: "/customer/:id/update",
    component: <UpdateCustomer />,
  },
  {
    path: "/customers",
    component: <ListCustomer />,
  },
  {
    path: "/customer/:id",
    component: <DetailCustomer />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailCustomerGeneral />,
      },
    ],
  },
  {
    path: "/order/new",
    component: <CreateOrder />,
  },
  {
    path: "/order/:id/update",
    component: <UpdateOrder />,
  },
  {
    path: "/order/:id/duplicate",
    component: <DuplicateOrder />,
  },
  {
    path: "/orders",
    component: <ListOrder />,
  },
  {
    path: "/order/:id",
    component: <DetailOrder />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailOrderGeneral />,
      },
      {
        path: "order-items",
        component: <DetailOrderOrderItems />,
      },
    ],
  },
  {
    path: "/product/new",
    component: <CreateProduct />,
  },
  {
    path: "/product/:id/update",
    component: <UpdateProduct />,
  },
  {
    path: "/products",
    component: <ListProduct />,
  },
  {
    path: "/product/:id",
    component: <DetailProduct />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailProductGeneral />,
      },
      {
        path: "order-items",
        component: <DetailProductOrderItems />,
      },
      {
        path: "bom",
        component: <DetailProductBom />,
      },
    ],
  },
  {
    path: "/bom-product/new",
    component: <CreateBomProduct />,
  },
  {
    path: "/bom-products",
    component: <ListBomProduct />,
  },
  {
    path: "/bom-product/:id",
    component: <DetailBomProduct />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailBomProductGeneral />,
      },
      {
        path: "items",
        component: <DetailBomProductItems />,
      },
    ],
  },
];

const appPublicRoutes = [
  {
    path: "/login",
    component: <LoginMain />,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
  },
  {
    path: "/logout",
    component: <LogoutMain />,
  },
];

const appPosPublicRoutes = [
  {
    path: "/pos/login",
    component: <LoginPos />,
  },
];

const appPosProtectedRoutes = [
  {
    path: "/pos/count",
    component: <>Hello!</>,
  },
];

export {
  appPosProtectedRoutes,
  appPosPublicRoutes,
  appProtectedRoutes,
  appPublicRoutes,
};
