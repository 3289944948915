import { useQueryClient } from "@tanstack/react-query";
import { useAppTranslation } from "app/hooks";
import { MasterData } from "app/services/masterData";
import {
  RETRIEVE_MASTER_DATAS_URL,
  RetrieveMasterDatasArgs,
  useRetrieveMasterDatasInfiniteQuery,
} from "app/services/masterData/retrieveMasterDatas";
import { OptionSelect } from "app/services/types";
import React, { useMemo, useRef, useState } from "react";
import Select, { Props } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useCreateMasterData } from "../../hooks";
import { useRetrieveMasterTypes } from "../../hooks/useRetrieveMasterTypes";
import { mapCreateMasterData } from "../../mapper";
import {
  MasterDataFormData,
  MasterDataFormModal,
} from "../MasterDataFormModal";

export type RetrieveKeys = keyof RetrieveMasterDatasArgs;

export interface MasterDataInfiniteSelectProps
  extends Omit<Props<OptionSelect<ID>>, "value" | "onChange"> {
  value: OptionSelect<ID> | null;
  onChange: (value: OptionSelect<ID> | null) => void;
  isInvalid?: boolean;
  retrieveKeys?: {
    [Property in RetrieveKeys]?: any;
  };
  isCreatable?: boolean;
  onCreateSuccess?: (data: MasterData) => void;
}

const MasterDataInfiniteSelect: React.FC<MasterDataInfiniteSelectProps> = ({
  value,
  onChange,
  retrieveKeys,
  isInvalid,
  isCreatable,
  onCreateSuccess,
  ...props
}) => {
  const { placeHolderText } = useAppTranslation();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const [isMasterDataModalOpen, toggleMasterDataModal] = useState(false);
  const createValue = useRef<string | null>(null);

  const handleToggleMasterDataModal = () => {
    toggleMasterDataModal((prevState) => !prevState);
  };

  const { data, fetchNextPage, isLoading } =
    useRetrieveMasterDatasInfiniteQuery(
      {
        page: 1,
        limit: 10,
        ...retrieveKeys,
      },
      { enabled: !!open },
    );

  const options = useMemo(() => {
    let result: MasterData[] = [];
    data?.pages.forEach((page: any) => {
      result = result.concat(page.data.data.result);
    });
    return result;
  }, [data, retrieveKeys]);

  const { mutateAsync: createMasterData, isPending: isCreating } =
    useCreateMasterData({
      onSuccess: (data) => {
        handleToggleMasterDataModal();
        if (data?.data?.data) {
          onCreateSuccess?.(data.data.data);
        }
      },
    });

  const { masterTypes } = useRetrieveMasterTypes(
    {
      searchString: retrieveKeys?.type,
      page: 1,
      limit: 100,
    },
    { enabled: !!retrieveKeys?.type },
  );

  const handleCreateMasterData = (data: MasterDataFormData) => {
    createMasterData(mapCreateMasterData(data));
  };

  const handleSubmitMasterData = (data: MasterDataFormData) => {
    handleCreateMasterData(data);
  };

  return (
    <>
      {isCreatable ? (
        <>
          <CreatableSelect<OptionSelect<ID>>
            className={`${props.className} ${isInvalid ? "is-invalid" : ""}`}
            {...props}
            noOptionsMessage={() => "Không có dữ liệu"}
            menuIsOpen={open}
            onMenuOpen={() => {
              setOpen(true);
            }}
            onMenuClose={() => {
              queryClient.removeQueries({
                queryKey: [RETRIEVE_MASTER_DATAS_URL, "INFINITE_QUERY"],
              });
              setOpen(false);
            }}
            value={value}
            isMulti={false}
            isLoading={isLoading}
            onCreateOption={async (inputValue) => {
              createValue.current = inputValue;
              handleToggleMasterDataModal();
            }}
            formatCreateLabel={(inputValue) =>
              `${placeHolderText.createNew}: "${inputValue}"`
            }
            onChange={onChange}
            options={
              options.map((item) => ({ value: item.id, label: item.name })) ||
              []
            }
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            isClearable
            placeholder={placeHolderText.select}
            onMenuScrollToBottom={fetchNextPage}
            styles={{
              control: (provided) => ({
                ...provided,
                boxShadow: "none",
                borderColor: isInvalid
                  ? "var(--vz-form-invalid-color)"
                  : provided.borderColor,
                ":hover": {
                  borderColor: isInvalid
                    ? "var(--vz-form-invalid-color)"
                    : provided.borderColor,
                },
              }),
            }}
          />

          <MasterDataFormModal
            open={isMasterDataModalOpen}
            onToggle={handleToggleMasterDataModal}
            onSubmit={handleSubmitMasterData}
            submitting={isCreating}
            edit={false}
            isQuickAdd
            defaultValues={{
              name: createValue.current || "",
              colorCode: "#646a73",
              description: "",
              type: {
                value: masterTypes?.result[0].key || "",
                label: masterTypes?.result[0].name || "",
              },
            }}
          />
        </>
      ) : (
        <Select<OptionSelect<ID>>
          {...props}
          className={`${props.className} ${isInvalid ? "is-invalid" : ""}`}
          noOptionsMessage={() => "Không có dữ liệu"}
          menuIsOpen={open}
          onMenuOpen={() => {
            setOpen(true);
          }}
          onMenuClose={() => {
            queryClient.removeQueries({
              queryKey: [RETRIEVE_MASTER_DATAS_URL, "INFINITE_QUERY"],
            });
            setOpen(false);
          }}
          value={value}
          isMulti={false}
          isLoading={isLoading}
          onChange={onChange}
          options={
            options.map((item) => ({ value: item.id, label: item.name })) || []
          }
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          isClearable
          placeholder={placeHolderText.select}
          onMenuScrollToBottom={fetchNextPage}
          styles={{
            control: (provided) => ({
              ...provided,
              boxShadow: "none",
              borderColor: isInvalid
                ? "var(--vz-form-invalid-color)"
                : provided.borderColor,
              ":hover": {
                borderColor: isInvalid
                  ? "var(--vz-form-invalid-color)"
                  : provided.borderColor,
              },
            }),
          }}
        />
      )}
    </>
  );
};

export default MasterDataInfiniteSelect;
