import {
  RetrieveProductByIdArgs,
  useRetrieveProductByIdQuery,
} from "app/services/product";

export const useRetrieveProductById = (
  args: RetrieveProductByIdArgs,
  opts?: Parameters<typeof useRetrieveProductByIdQuery>[1],
) => {
  const result = useRetrieveProductByIdQuery(args, opts as any);
  return {
    product: result.data?.data.data,
    isLoadingProduct: result.isLoading,
    ...result,
  };
};
