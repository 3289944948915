import { useAppTranslation } from "app/hooks";
import { FC } from "react";
import { FormFeedback } from "reactstrap";

export interface FormHelperProps {
  isTranslate?: boolean;
  message?: string;
}

export const FormHelper: FC<FormHelperProps> = ({
  isTranslate = true,
  message,
}) => {
  const { t } = useAppTranslation();
  return (
    <FormFeedback type="invalid">
      {message ? (isTranslate ? t(message) : message) : null}
    </FormFeedback>
  );
};
