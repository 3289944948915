import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { intl } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import { Customer } from "app/services/customer";
import { FC } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";

export interface DetailCustomerGeneralProps {}

const DetailCustomerGeneral: FC<DetailCustomerGeneralProps> = () => {
  const { data: customer } = useDetailPageContext<Customer>();
  const { customerText } = useAppTranslation();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xxl={6}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {customerText.detail.infomation}
              </h5>
              <div className="table-responsive">
                <Table className="table-borderless mb-0">
                  <tbody>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.customerName}:
                      </th>
                      <td className="text-muted">{customer?.customerName}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.customerCode}:
                      </th>
                      <td className="text-muted">
                        {customer?.customerCode || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.address}:
                      </th>
                      <td className="text-muted">{customer?.address || "-"}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.phoneNumber}:
                      </th>
                      <td className="text-muted">
                        {customer?.phoneNumber || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.email}:
                      </th>
                      <td className="text-muted">{customer?.email || "-"}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.website}:
                      </th>
                      <td className="text-muted">
                        {customer?.website ? (
                          <a
                            href={customer.website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {customer.website}
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.customerType}:
                      </th>
                      <td className="text-muted">
                        {customer?.customerType.name || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.representative}:
                      </th>
                      <td className="text-muted">
                        {customer?.representative || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.representativeRole}:
                      </th>
                      <td className="text-muted">
                        {customer?.representativeRole || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.paymentTerms}:
                      </th>
                      <td className="text-muted">
                        {customer?.paymentTerms || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.depositPercentage}:
                      </th>
                      <td className="text-muted">
                        {customer?.depositPercentage || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.paymentDueDays}:
                      </th>
                      <td className="text-muted">
                        {customer?.paymentDueDays || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.startDate}:
                      </th>
                      <td className="text-muted">
                        {customer?.startDate
                          ? intl.formatDate(new Date(customer.startDate))
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.customerStatus}:
                      </th>
                      <td className="text-muted">
                        {customer?.customerStatus.name || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.assignee}:
                      </th>
                      <td className="text-muted">
                        {customer?.assignee.fullName || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {customerText.field.notes}:
                      </th>
                      <td className="text-muted">{customer?.notes || "-"}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailCustomerGeneral;
