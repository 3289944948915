import { CommonError } from "./types";

export const commonErrorMessages = {
  [CommonError.InternalServerError]:
    "Lỗi máy chủ, vui lòng liên hệ quản trị hệ thống",
  [CommonError.BadRequest]: "Dữ liệu không hợp lệ",
  [CommonError.DataNotFound]: "Dữ liệu không tồn tại",

  [CommonError.ForbiddenResource]: "Bạn không có quyền thao tác",
};

export const errorMessages: Record<string, string> = {
  ...commonErrorMessages,
};
