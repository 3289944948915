import { useQueryClient } from "@tanstack/react-query";
import { useAppTranslation } from "app/hooks";
import { Product } from "app/services/product";
import {
  RETRIEVE_PRODUCTS_URL,
  RetrieveProductsArgs,
  useRetrieveProductsInfiniteQuery,
} from "app/services/product/retrieveProducts";
import { OptionSelect } from "app/services/types";
import React, { useMemo, useState } from "react";
import Select, { Props } from "react-select";

export type RetrieveKeys = keyof RetrieveProductsArgs;

export interface ProductSelectProps
  extends Omit<Props<OptionSelect<ID>>, "value" | "onChange"> {
  value: OptionSelect<ID> | null;
  onChange: (value: OptionSelect<ID> | null) => void;
  isInvalid?: boolean;
  retrieveKeys?: {
    [Property in RetrieveKeys]?: any;
  };
}

export const ProductSelect: React.FC<ProductSelectProps> = ({
  value,
  onChange,
  retrieveKeys,
  isInvalid,
  ...props
}) => {
  const { placeHolderText } = useAppTranslation();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const { data, fetchNextPage, isLoading } = useRetrieveProductsInfiniteQuery(
    {
      page: 1,
      limit: 10,
      ...retrieveKeys,
    },
    { enabled: !!open },
  );

  const options = useMemo(() => {
    let result: Product[] = [];
    data?.pages.forEach((page: any) => {
      result = result.concat(page.data.data.result);
    });
    return result;
  }, [data, retrieveKeys]);

  return (
    <>
      <Select<OptionSelect<ID>>
        {...props}
        className={`${props.className} ${isInvalid ? "is-invalid" : ""}`}
        noOptionsMessage={() => "Không có dữ liệu"}
        menuIsOpen={open}
        onMenuOpen={() => {
          setOpen(true);
        }}
        onMenuClose={() => {
          queryClient.removeQueries({
            queryKey: [RETRIEVE_PRODUCTS_URL, "INFINITE_QUERY"],
          });
          setOpen(false);
        }}
        value={value}
        isMulti={false}
        isLoading={isLoading}
        onChange={onChange}
        options={
          options.map((item) => ({
            value: item.id,
            label: `${item.nameVn} - ${item.code}`,
          })) || []
        }
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isClearable
        placeholder={placeHolderText.select}
        onMenuScrollToBottom={fetchNextPage}
        styles={{
          control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: isInvalid
              ? "var(--vz-form-invalid-color)"
              : provided.borderColor,
            ":hover": {
              borderColor: isInvalid
                ? "var(--vz-form-invalid-color)"
                : provided.borderColor,
            },
          }),
        }}
      />
    </>
  );
};
