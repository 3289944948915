import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Department } from "./types";

export const CREATE_DEPARTMENT_URL = "/api/departments";

export enum CreateDepartmentError {}

export const createDepartmentErrorMessages = {};

export interface CreateDepartmentArgs {}

export type CreateDepartmentResponse = Response<Department>;

export const createDepartment = (args: CreateDepartmentArgs) => {
  return httpClient.post<CreateDepartmentResponse>(CREATE_DEPARTMENT_URL, args);
};

export const useCreateDepartmentMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateDepartmentResponse>,
      AxiosError<any>,
      CreateDepartmentArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateDepartmentResponse>,
    AxiosError<any>,
    CreateDepartmentArgs
  >({
    mutationFn: createDepartment,
    mutationKey: [CREATE_DEPARTMENT_URL],
    ...opts,
  });
