import { FC } from "react";
import { Label, LabelProps } from "reactstrap";

export interface FormLabelProps extends LabelProps {
  required?: boolean;
}

export const FormLabel: FC<FormLabelProps> = ({
  required,
  children,
  ...props
}) => {
  return (
    <Label {...props}>
      {children}
      {required ? <span className="text-danger">*</span> : null}
    </Label>
  );
};
