import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "app/components/organisms/DataTable";
import { useAppTranslation } from "app/hooks";
import { useRetrieveProductBomById } from "app/modules/product/hooks";
import { BomProductItem } from "app/services/bomProduct";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

export interface DetailProductBomProps {}

const DetailProductBom: FC<DetailProductBomProps> = () => {
  const { id } = useParams();
  const { bomProductText, productText } = useAppTranslation();

  const { productBoms, isLoadingProductBom } = useRetrieveProductBomById(
    { id: id as string },
    { enabled: !!id },
  );

  const bomProductItems = productBoms?.product;

  const columns: ColumnDef<BomProductItem>[] = useMemo(
    () => [
      {
        header: productText.field.code,
        accessorKey: "productCode",
        cell: ({ row }) => row.original.code || "-",
      },
      {
        header: productText.field.name,
        accessorKey: "productName",
        cell: ({ row }) => row.original.nameVn || "-",
      },
      {
        header: productText.field.type,
        accessorKey: "productType",
        cell: ({ row }) => row.original.productType?.name || "-",
      },
      {
        header: productText.field.group,
        accessorKey: "productGroup",
        cell: ({ row }) => row.original.productGroup?.name || "-",
      },
      {
        header: productText.field.unit,
        accessorKey: "productUnit",
        cell: ({ row }) => row.original.productUnit?.name || "-",
      },
      {
        header: bomProductText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
    ],
    [bomProductText, productText],
  );

  return (
    <div className="p-3 py-0 h-100 d-flex flex-column">
      {isLoadingProductBom ? null : (
        <DataTable<BomProductItem>
          columns={columns}
          data={bomProductItems ? [bomProductItems] : []}
          total={1}
          loading={isLoadingProductBom}
          isHiddenPagination
        />
      )}
    </div>
  );
};

export default DetailProductBom;
