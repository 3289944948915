import { intl } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import { useRetrieveOrderById } from "app/modules/order/hooks";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row, Table } from "reactstrap";

export interface DetailOrderGeneralProps {}

const DetailOrderGeneral: FC<DetailOrderGeneralProps> = () => {
  const { id } = useParams();
  const { orderText } = useAppTranslation();
  const { order } = useRetrieveOrderById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={8}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">{orderText.detail.infomation}</h5>
              <div className="table-responsive">
                <Table className="table-borderless mb-0">
                  <tbody>
                    <tr>
                      <th className="ps-0" scope="row">
                        {orderText.field.orderCode}:
                      </th>
                      <td className="text-muted">{order?.orderCode || "-"}</td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {orderText.field.customer}:
                      </th>
                      <td className="text-muted">
                        {order?.customer?.customerName || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {orderText.field.orderDate}:
                      </th>
                      <td className="text-muted">
                        {order?.orderDate
                          ? intl.formatDate(order.orderDate)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {orderText.field.expectedDeliveryDate}:
                      </th>
                      <td className="text-muted">
                        {order?.expectedDeliveryDate
                          ? intl.formatDate(order.expectedDeliveryDate)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        {orderText.field.notes}:
                      </th>
                      <td className="text-muted">{order?.notes || "-"}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailOrderGeneral;
