import { commonErrorMessages } from "app/services/messages";
import { DEFAULT_ERROR_MESSAGE } from "./constants";

export const errorMessageMapper = (
  messageDescriptors: Record<string, string>,
  errorCode?: string,
): string => {
  const combinedMessageDescriptors: Record<string, string> = {
    ...messageDescriptors,
    ...commonErrorMessages,
  };

  const messageDescriptor =
    (errorCode && combinedMessageDescriptors[errorCode]) ||
    DEFAULT_ERROR_MESSAGE;

  return messageDescriptor;
};
