import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { BomProduct } from "./types";
import { Response } from "../types";

export const RETRIEVE_BOM_PRODUCT_BY_ID_URL = (id: ID) =>
  `/api/bom-products/${id}`;

export type RetrieveBomProductByIdResponse = Response<BomProduct>;

export type RetrieveBomProductByIdArgs = {
  id: ID;
};

export const retrieveBomProductById = ({ id }: RetrieveBomProductByIdArgs) => {
  return httpClient.get<RetrieveBomProductByIdResponse>(
    RETRIEVE_BOM_PRODUCT_BY_ID_URL(id),
  );
};

export const useRetrieveBomProductByIdQuery = (
  retrieveBomProductsArgs: RetrieveBomProductByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveBomProductByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveBomProductByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_BOM_PRODUCT_BY_ID_URL(retrieveBomProductsArgs.id)],
    queryFn: () => retrieveBomProductById(retrieveBomProductsArgs),
    ...opts,
  });
