import once from "lodash/once";
import { createContext, useContext } from "react";
interface FormPageContextProps<T = any> {
  loading?: boolean;
}

export const createFormPageContext = once(<T,>() =>
  createContext<FormPageContextProps<T>>({
    loading: false,
  }),
);

export const useFormPageContext = <T,>() => {
  const context = useContext(createFormPageContext<T>());

  return context;
};
