import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation } from "app/hooks";
import { useChangePassword } from "app/modules/auth/hooks";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface ChangePasswordFormProps {}

export interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const ChangePasswordForm: FC<ChangePasswordFormProps> = () => {
  const { authText, buttonText } = useAppTranslation();
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const validationSchema = Yup.object().shape({
    oldPassword: yupSchema.stringRequired("auth.error.oldPasswordRequired"),
    newPassword: yupSchema.stringMoreThanOrEqualRequired(
      8,
      "auth.error.passwordMinLength",
      "auth.error.newPasswordRequired",
    ),
    confirmPassword: yupSchema.stringMatchRequired(
      "newPassword",
      "auth.error.confirmPasswordNotMatch",
      "auth.error.confirmPasswordRequired",
    ),
  });

  const { control, handleSubmit, reset } = useForm<ChangePasswordFormData>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { mutateAsync: changePassword, isPending } = useChangePassword({
    onSuccess: () => {
      reset();
    },
  });

  const handleChangeProfile = ({
    oldPassword,
    newPassword,
  }: ChangePasswordFormData) => {
    changePassword({ oldPassword, newPassword });
  };

  return (
    <Form onSubmit={handleSubmit(handleChangeProfile)}>
      <Row className="g-2">
        <Col lg={4}>
          <FormGroup>
            <FormLabel
              required
              className="form-label"
              htmlFor="old-password-input"
            >
              {authText.oldPassword}
            </FormLabel>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Controller
                name="oldPassword"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <FormInput
                      {...field}
                      type={oldPasswordShow ? "text" : "password"}
                      className="form-control pe-5 password-input"
                      id="old-password-input"
                      invalid={!!fieldState.error}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                type="button"
                id="old-password-addon"
                onClick={() => setOldPasswordShow(!oldPasswordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <FormLabel
              required
              className="form-label"
              htmlFor="new-password-input"
            >
              {authText.newPassword}
            </FormLabel>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Controller
                name="newPassword"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <FormInput
                      {...field}
                      type={newPasswordShow ? "text" : "password"}
                      className="form-control pe-5 password-input"
                      id="new-password-input"
                      invalid={!!fieldState.error}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                type="button"
                id="new-password-addon"
                onClick={() => setNewPasswordShow(!newPasswordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <FormLabel
              required
              className="form-label"
              htmlFor="confirm-password-input"
            >
              {authText.confirmPassword}
            </FormLabel>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <FormInput
                      {...field}
                      type={confirmPasswordShow ? "text" : "password"}
                      className="form-control pe-5 password-input"
                      id="confirm-password-input"
                      invalid={!!fieldState.error}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                type="button"
                id="comfirm-password-addon"
                onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </FormGroup>
        </Col>

        <Col lg={12}>
          <div className="text-end">
            <Button type="submit" color="primary" loading={isPending}>
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
