import { useAppTranslation } from "app/hooks";
import { Product } from "app/services/product";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type ProductAction =
  | "edit"
  | "delete"
  | "detail"
  | "active"
  | "deactive";

export interface ProductActionDropdownProps {
  data?: Product;
  onAction: (action: ProductAction, data?: Product) => void;
}

export const ProductActionDropdown: FC<ProductActionDropdownProps> = ({
  data,
  onAction,
}) => {
  const { buttonText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const isActive = !!data?.isActive;

  const handleAction = (action: ProductAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle tag="button" className="btn py-0 px-2 m-0">
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="p-0" onClick={handleAction("detail")}>
          <div className="dropdown-item">
            <i className=" ri-arrow-right-s-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.detail}</span>
          </div>
        </DropdownItem>
        <DropdownItem className="p-0" onClick={handleAction("edit")}>
          <div className="dropdown-item">
            <i className="ri-pencil-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.edit}</span>
          </div>
        </DropdownItem>
        <DropdownItem className="p-0" onClick={handleAction("delete")}>
          <div className="dropdown-item">
            <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.delete}</span>
          </div>
        </DropdownItem>
        {!isActive ? (
          <DropdownItem className="p-0" onClick={handleAction("active")}>
            <div className="dropdown-item">
              <i className="ri-checkbox-circle-line text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">{buttonText.active}</span>
            </div>
          </DropdownItem>
        ) : null}
        {isActive ? (
          <DropdownItem className="p-0" onClick={handleAction("deactive")}>
            <div className="dropdown-item">
              <i className="ri-close-circle-line text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">{buttonText.deactive}</span>
            </div>
          </DropdownItem>
        ) : null}
      </DropdownMenu>
    </Dropdown>
  );
};
