import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Product } from "./types";

export const UPDATE_PRODUCT_URL = (id: ID) => `/api/products/${id}`;

export enum UpdateProductError {
  ProductCodeAlreadyExists = "Product Code Already Exists",
}

export const updateProductErrorMessages = {
  [UpdateProductError.ProductCodeAlreadyExists]: "Product Code Already Exists",
};

export type UpdateProductResponse = Response<Product>;

export type UpdateProductArgs = {
  id: ID;
  code: string;
  nameEn: string | null;
  nameVn: string;
  typeId: ID | null;
  groupId: ID | null;
  unitId: ID;
  countryOfOrigin: string;
  description: string | null;
  lengthCm: number | null;
  widthCm: number | null;
  heightCm: number | null;
  packageLengthCm: number | null;
  packageWidthCm: number | null;
  packageHeightCm: number | null;
  seatSizeCm: string | null;
  weightKg: number | null;
  color: string | null;
  packageBoxCount: number | null;
  imageId: ID | null;

  // BOM
  // bomProductItems?: {
  //   productId: ID;
  //   quantity: number;
  // }[];
};

export const updateProduct = ({ id, ...args }: UpdateProductArgs) => {
  return httpClient.put<UpdateProductResponse>(UPDATE_PRODUCT_URL(id), args);
};

export const useUpdateProductMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateProductResponse>,
      AxiosError<any>,
      UpdateProductArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateProductResponse>,
    AxiosError<any>,
    UpdateProductArgs
  >({
    mutationFn: updateProduct,
    mutationKey: [UPDATE_PRODUCT_URL],
    ...opts,
  });
