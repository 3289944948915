import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Vendor } from "./types";

export const CREATE_VENDOR_URL = "/api/vendors";

export enum CreateVendorError {}

export const createVendorErrorMessages = {};

export interface CreateVendorArgs {
  vendorCode: string | null;
  vendorName: string;
  vendorTypeId: ID | null;
  address: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  representative: string | null;
  representativeRole: string | null;
  notes: string | null;
  startDate: DateTimeString | null;
  vendorStatusId: ID | null;
  assigneeId: ID | null;
}

export type CreateVendorResponse = Response<Vendor>;

export const createVendor = (args: CreateVendorArgs) => {
  return httpClient.post<CreateVendorResponse>(CREATE_VENDOR_URL, args);
};

export const useCreateVendorMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateVendorResponse>,
      AxiosError<any>,
      CreateVendorArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateVendorResponse>,
    AxiosError<any>,
    CreateVendorArgs
  >({
    mutationFn: createVendor,
    mutationKey: [CREATE_VENDOR_URL],
    ...opts,
  });
