import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Role } from "./index";

export const DELETE_ROLE_URL = (id: ID) => `/api/roles/${id}`;

export enum DeleteRoleError {
  DataNotFound = "DATA_NOT_FOUND",
  ExistedAccountUsingRole = "EXISTED_ACCOUNT_USING_ROLE",
}

export const deletedRoleErrorMessages = {
  [DeleteRoleError.DataNotFound]: "Dữ liệu không tồn tại",
  [DeleteRoleError.ExistedAccountUsingRole]: "Không thể xoá vai trò này",
};

export type DeleteRoleResponse = Response<Role>;

export type DeleteRoleArgs = {
  id: ID;
};

export const deleteRole = ({ id }: DeleteRoleArgs) => {
  return httpClient.delete<DeleteRoleResponse>(DELETE_ROLE_URL(id));
};

export const useDeleteRoleMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteRoleResponse>,
    AxiosError<Response<null>>,
    DeleteRoleArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_ROLE_URL],
    mutationFn: (deletedRoleArgs: DeleteRoleArgs) =>
      deleteRole(deletedRoleArgs),
    ...opts,
  });
};
