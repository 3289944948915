import { RetrieveOrdersArgs, useRetrieveOrdersQuery } from "app/services/order";

export const useRetrieveOrders = (args: RetrieveOrdersArgs) => {
  const result = useRetrieveOrdersQuery(args);
  return {
    orders: result.data?.data.data,
    isLoadingOrders: result.isLoading,
    ...result,
  };
};
