import { AttachmentBar } from "app/components/organisms/AttachmentBar";
import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import { useRetrieveCustomerById } from "app/modules/customer/hooks";
import { Customer } from "app/services/customer";
import BreadCrumb from "Components/Common/BreadCrumb";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { DetailCustomerHeader } from "./DetailCustomerHeader";
import { DetailCustomerTabs } from "./DetailCustomerTabs";

const DetailCustomer = () => {
  const { id } = useParams();
  const { customerText } = useAppTranslation();

  const { customer, isLoadingCustomer } = useRetrieveCustomerById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <React.Fragment>
      <DetailPage<Customer>
        data={customer as Customer}
        loading={isLoadingCustomer}
      >
        <DetailPage.BreadCrumb>
          <BreadCrumb
            title={customerText.detail.title}
            pageTitle={customerText.title}
          />
        </DetailPage.BreadCrumb>
        <DetailPage.Header>
          <DetailCustomerHeader />
        </DetailPage.Header>
        <DetailPage.Main
          tabs={<DetailCustomerTabs />}
          attachmentBar={
            <AttachmentBar
              imageProps={{ data: [] }}
              documentProps={{ data: [] }}
            />
          }
        >
          <Outlet />
        </DetailPage.Main>
      </DetailPage>
    </React.Fragment>
  );
};

export default DetailCustomer;
