import { useAppTranslation } from "app/hooks";
import { FC } from "react";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";

export interface DateInputProps extends DateTimePickerProps {
  isInvalid?: boolean;
}

export const DateInput: FC<DateInputProps> = ({ isInvalid, ...props }) => {
  const { placeHolderText } = useAppTranslation();
  return (
    <Flatpickr
      {...props}
      className={`form-control ${isInvalid ? "is-invalid" : ""}`}
      placeholder={placeHolderText.date}
      options={{
        dateFormat: "d-m-Y",
        ...props.options,
      }}
    />
  );
};
