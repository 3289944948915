import { PagingData } from "./../types";
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { JobTitle } from "./types";

export const RETRIEVE_JOB_TITLES_URL = "/api/job-titles";

export interface RetrieveJobTitlesArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveJobTitlesResponse = Response<PagingData<JobTitle[]>>;

export const retrieveJobTitles = (args: RetrieveJobTitlesArgs) => {
  return httpClient.get<RetrieveJobTitlesResponse>(RETRIEVE_JOB_TITLES_URL, {
    params: args,
  });
};

export const useRetrieveJobTitlesQuery = (
  retrieveJobTitlesArgs: RetrieveJobTitlesArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveJobTitlesResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveJobTitlesResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_JOB_TITLES_URL, retrieveJobTitlesArgs],
    queryFn: () => retrieveJobTitles(retrieveJobTitlesArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
