import { ReactNode, createContext } from "react";

export type ConfirmationOptions = {
  severity?: "info" | "warning" | "success" | "error" | "primary";
  title?: string;
  description?: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => Promise<any>;
  onOpen?: () => void;
};

export interface ConfirmationContextValue {
  confirm: (options?: ConfirmationOptions) => void;
}

export const ConfirmationContext = createContext<ConfirmationContextValue>({
  confirm: () => null,
});
