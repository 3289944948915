import {
  RetrieveDepartmentByIdArgs,
  useRetrieveDepartmentByIdQuery,
} from "app/services/department";

export const useRetrieveDepartmentById = (
  args: RetrieveDepartmentByIdArgs,
  opts: Parameters<typeof useRetrieveDepartmentByIdQuery>[1],
) => {
  const result = useRetrieveDepartmentByIdQuery(args, opts as any);
  return {
    department: result.data?.data.data,
    isLoadingDepartment: result.isLoading,
    ...result,
  };
};
