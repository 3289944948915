import {
  AttachmentAction,
  AttachmentActionDropdown,
} from "app/components/molecules/AttachmentActionDropdown";
import { intl } from "app/helpers";
import { mapSource } from "app/helpers/utils";
import { FileDTO } from "app/models";
import { FC } from "react";
import { Badge, Col } from "reactstrap";

export interface SidebarDocumentListItemProps {
  document: FileDTO;
  onActionClick: (action: AttachmentAction, data?: FileDTO) => void;
}

export const SidebarDocumentListItem: FC<SidebarDocumentListItemProps> = ({
  document,
  onActionClick,
}) => {
  return (
    <Col xs={12}>
      <div className="d-flex justify-content-between">
        <p className="fs-10 mb-0">{intl.formatDate(document.createdAt)}</p>
        <AttachmentActionDropdown
          data={document}
          onAction={onActionClick}
          isDocument
        />
      </div>
      <div className="d-flex align-items-center gap-2">
        <i className="ri-file-3-fill fs-24 text-muted" />
        <div className="text-truncate">{document.fileName}</div>
      </div>
      <Badge color="primary">{mapSource(document.moduleType)}</Badge>
    </Col>
  );
};
