import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { BomProduct } from "./types";

export const DELETE_BOM_PRODUCT_URL = (id: ID) => `/api/bom-products/${id}`;

export enum DeleteBomProductError {}

export const deletedBomProductErrorMessages = {};

export type DeleteBomProductResponse = Response<BomProduct>;

export type DeleteBomProductArgs = {
  id: ID;
};

export const deleteBomProduct = ({ id }: DeleteBomProductArgs) => {
  return httpClient.delete<DeleteBomProductResponse>(
    DELETE_BOM_PRODUCT_URL(id),
  );
};

export const useDeleteBomProductMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteBomProductResponse>,
    AxiosError<Response<null>>,
    DeleteBomProductArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_BOM_PRODUCT_URL],
    mutationFn: (deletedBomProductArgs: DeleteBomProductArgs) =>
      deleteBomProduct(deletedBomProductArgs),
    ...opts,
  });
};
