import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface UseAppSearchProps<T> {
  initialPage?: number;
  initialLimit?: number;
  initialSearchString?: string;
  initialCustomParams?: T;
}

const useAppSearch = <T extends Record<string, any>>({
  initialPage = DEFAULT_PAGE,
  initialLimit = DEFAULT_LIMIT_PAGE,
  initialSearchString = "",
  initialCustomParams = {} as T,
}: UseAppSearchProps<T>) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParams = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page") || `${initialPage}`, 10);
    const limit = parseInt(params.get("limit") || `${initialLimit}`, 10);
    const searchString = params.get("searchString") || initialSearchString;

    const customParams: Partial<T> = { ...initialCustomParams };
    (Object.keys(initialCustomParams) as Array<keyof T>).forEach((key) => {
      const value = params.get(key as string);
      const label = params.get(`${key as any}-label`);
      if (value !== null) {
        if (Array.isArray(initialCustomParams[key])) {
          customParams[key] = value.split(",") as any;
        } else if ((initialCustomParams[key] as any) instanceof Date) {
          customParams[key] = new Date(value) as any;
        } else if (typeof initialCustomParams[key] === "boolean") {
          customParams[key] = (value === "true") as T[keyof T];
        } else if (
          typeof initialCustomParams[key] === "object" &&
          label !== null
        ) {
          customParams[key] = { label, value } as any;
        } else if (typeof initialCustomParams[key] === "object") {
          try {
            customParams[key] = JSON.parse(value) as any;
          } catch (e) {
            console.error(`Failed to parse value for key ${key as string}:`, e);
          }
        } else {
          customParams[key] = value as any;
        }
      }
    });

    return { page, limit, searchString, customParams: customParams as T };
  }, [
    location.search,
    initialPage,
    initialLimit,
    initialSearchString,
    initialCustomParams,
  ]);

  const [page, setPage] = useState(getQueryParams().page);
  const [limit, setLimit] = useState(getQueryParams().limit);
  const [searchString, setSearchString] = useState(
    getQueryParams().searchString,
  );
  const [customParams, setCustomParams] = useState(
    getQueryParams().customParams,
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("page", page.toString());
    params.set("limit", limit.toString());
    params.set("searchString", searchString);
    (Object.keys(customParams) as Array<keyof T>).forEach((key) => {
      const value = customParams[key];
      const keyLabel = `${key as string}-label`;
      if (!value) {
        params.delete(key as string);
        const label = `${key as string}-label`;
        if (label) {
          params.delete(keyLabel);
        }
        return;
      }
      if (Array.isArray(value)) {
        params.set(key as string, value.join(","));
      } else if ((value as any) instanceof Date) {
        params.set(key as string, value.toISOString());
      } else if (typeof value === "object") {
        params.set(key as string, value.value);
        params.set(`${key as string}-label`, value.label);
      } else {
        params.set(key as string, value.toString());
      }
    });
    if (params.get("searchString") === "") {
      params.delete("searchString");
    }
    const searchParams = params.toString();
    const decodedParams = decodeURIComponent(searchParams);
    navigate({ search: decodedParams }, { replace: true });
  }, [page, limit, searchString, customParams, history, location.search]);

  useEffect(() => {
    setPage(1);
  }, [searchString, customParams]);

  return {
    page,
    limit,
    searchString,
    customParams,
    setPage,
    setLimit,
    setSearchString,
    setCustomParams,
  };
};

export default useAppSearch;
