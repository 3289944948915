import { CurrentUser } from "app/services/auth";
import { createContext } from "react";

export type FetchCurrentUserError = "UnauthorizedError" | "UnknowError";

export interface IAuthContext {
  authenticated: boolean;
  currentUser: CurrentUser | null;
  fetchingCurrentUser: boolean;
  fetchCurrentUserError: FetchCurrentUserError | null;
  authenticate(payload: { accessToken: string }): Promise<void>;
  refreshCurrentUser: () => void;
  logout(): Promise<void>;
}

export const AuthContext = createContext<IAuthContext>({
  authenticated: false,
  currentUser: null,
  fetchingCurrentUser: false,
  fetchCurrentUserError: null,
  authenticate: async () => {
    // TODO
  },
  refreshCurrentUser: () => {
    // TODO
  },
  logout: async () => {
    // TODO
  },
});
