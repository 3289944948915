import { ColumnDef } from "@tanstack/react-table";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { ListPageFilter } from "app/components/templates/ListPage/ListPageFilter";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import { UserAction, UserActionDropdown } from "app/modules/user/components";
import {
  useActiveUser,
  useDeactiveUser,
  useDeleteUser,
  useRetrieveUsers,
} from "app/modules/user/hooks";
import { User } from "app/services/user";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo, useRef } from "react";
import { Col, Row } from "reactstrap";

interface ListUserProps {}

const ListUser: FC<ListUserProps> = () => {
  const { confirm } = useConfirm();
  const rowSelected = useRef<User | null>(null);
  const { userText, confirmText } = useAppTranslation();

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: DEFAULT_PAGE,
      initialLimit: DEFAULT_LIMIT_PAGE,
      initialSearchString: "",
    });

  const { users, isLoadingUsers } = useRetrieveUsers({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteUser } = useDeleteUser();

  const { mutateAsync: activeUser } = useActiveUser({
    id: rowSelected.current?.id,
  });

  const { mutateAsync: deactiveUser } = useDeactiveUser({
    id: rowSelected.current?.id,
  });

  const handleActionClick = (action: UserAction, data?: User) => {
    switch (action) {
      case "active":
        if (data) activeUser({ id: data.id });
        break;
      case "deactive":
        if (data) deactiveUser({ id: data.id });
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(userText.title),
            description: confirmText.deleteContent(userText.title),
            onConfirm: () => deleteUser({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<User>[] = useMemo(
    () => [
      {
        header: userText.field.username,
        accessorKey: "username",
      },
      {
        header: userText.field.fullName,
        accessorKey: "fullName",
        cell: ({ row }) =>
          row.original.employee
            ? `${row.original.employee.lastName}${
                row.original.employee.middleName
                  ? " " + row.original.employee.middleName + " "
                  : " "
              }${row.original.employee.firstName}`
            : "-",
      },
      {
        header: userText.field.role,
        accessorKey: "role",
        cell: ({ row }) => row.original.role?.name || "-",
      },
      {
        header: userText.field.status,
        accessorKey: "status",
        cell: ({ row }) =>
          row.original.status === 1 ? "Hoạt động" : "Không hoạt động",
      },
      {
        header: "",
        size: 40,
        accessorKey: "action",
        cell: ({ row }) => (
          <UserActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [userText],
  );

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={userText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPageFilter>
          <Row>
            <Col xs={6}>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <DebouncedInput
                  name="list-user-search"
                  value={searchString || ""}
                  onChange={(value) => {
                    setSearchString(String(value));
                  }}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
          </Row>
        </ListPageFilter>
        <ListPage.Main>
          <DataTable<User>
            columns={columns}
            data={users?.result || []}
            loading={isLoadingUsers}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={users?.total || 0}
          />
        </ListPage.Main>
      </ListPage>
    </React.Fragment>
  );
};

export default ListUser;
