import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  createMasterDataErrorMessages,
  CreateMasterDataResponse,
  RETRIEVE_MASTER_DATAS_URL,
  useCreateMasterDataMutation,
} from "app/services/masterData";
import { AxiosResponse } from "axios";

interface UseCreateMasterDataProps {
  onSuccess?: (data?: AxiosResponse<CreateMasterDataResponse, any>) => void;
  onError?: () => void;
}

export const useCreateMasterData = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseCreateMasterDataProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateMasterDataMutation({
    onSuccess: (data) => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_MASTER_DATAS_URL] });
      handleSuccess?.(data);
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createMasterDataErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
