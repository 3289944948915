import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  createJobTitleErrorMessages,
  RETRIEVE_JOB_TITLES_URL,
  useCreateJobTitleMutation,
} from "app/services/jobTitle";

interface UseCreateJobTitleProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreateJobTitle = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseCreateJobTitleProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateJobTitleMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_JOB_TITLES_URL] });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createJobTitleErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
