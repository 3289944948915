import {
  RetrieveRoleByIdArgs,
  useRetrieveRoleByIdQuery,
} from "app/services/role";

export const useRetrieveRoleById = (
  args: RetrieveRoleByIdArgs,
  opts: Parameters<typeof useRetrieveRoleByIdQuery>[1],
) => {
  const result = useRetrieveRoleByIdQuery(args, opts as any);
  return {
    role: result.data?.data.data,
    isLoadingRole: result.isLoading,
    ...result,
  };
};
