import { FC, PropsWithChildren } from "react";
import { Container } from "reactstrap";

export interface FormPageBreadCrumbProps {}

export const FormPageBreadCrumb: FC<
  FormPageBreadCrumbProps & PropsWithChildren
> = ({ children }) => {
  return (
    <Container fluid className="position-sticky top-0 z-1">
      {children}
    </Container>
  );
};
