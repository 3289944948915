import { useAppTranslation } from "app/hooks";
import { FC } from "react";
import ReactSelect, { Props } from "react-select";

export interface ReactSelectCustomProps extends Props {
  isInvalid?: boolean;
}

export const ReactSelectCustom: FC<ReactSelectCustomProps> = ({
  isInvalid,
  ...args
}) => {
  const { placeHolderText } = useAppTranslation();
  return (
    <ReactSelect
      {...args}
      className={`${args.className} ${isInvalid ? "is-invalid" : ""}`}
      isClearable
      noOptionsMessage={() => "Không có dữ liệu"}
      placeholder={placeHolderText.select}
      styles={{
        control: (provided) => ({
          ...provided,
          boxShadow: "none",
          borderColor: isInvalid
            ? "var(--vz-form-invalid-color)"
            : provided.borderColor,
          ":hover": {
            borderColor: isInvalid
              ? "var(--vz-form-invalid-color)"
              : provided.borderColor,
          },
        }),
      }}
    />
  );
};
