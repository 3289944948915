import { useAppTranslation } from "app/hooks";
import { useRetrieveMasterDataById } from "app/modules/masterData/hooks";
import { MasterData } from "app/services/masterData";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { mapDefaultMasterData } from "../../mapper";
import { MasterDataForm, MasterDataFormData } from "./MasterDataForm";

export interface MasterDataFormModalProps {
  data?: MasterData;
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: MasterDataFormData) => void;
  submitting?: boolean;
  edit?: boolean;
  defaultValues?: MasterDataFormData;
  isQuickAdd?: boolean;
}

export const MasterDataFormModal: FC<MasterDataFormModalProps> = ({
  data,
  onToggle,
  open = false,
  onSubmit,
  submitting,
  edit = false,
  defaultValues,
  isQuickAdd,
}) => {
  const { masterDataText } = useAppTranslation();
  const title = edit ? masterDataText.edit : masterDataText.add;
  const { masterData, isLoading } = useRetrieveMasterDataById(
    {
      id: data?.id as string,
    },
    { enabled: edit && !!data?.id },
  );

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {!isLoading ? (
          <MasterDataForm
            isQuickAdd={isQuickAdd}
            onSubmit={onSubmit}
            onToggle={onToggle}
            defaultValues={defaultValues || mapDefaultMasterData(masterData)}
            submitting={submitting}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};
