import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE } from "app/helpers";
import { toast } from "react-toastify";

const useToastify = () => {
  const success = (message: string = DEFAULT_SUCCESS_MESSAGE) => {
    toast.success(message);
  };

  const error = (message: string = DEFAULT_ERROR_MESSAGE) => {
    toast.error(message);
  };

  return {
    success,
    error,
  };
};

export default useToastify;
