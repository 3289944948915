import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import {
  EmployeeForm,
  EmployeeFormData,
} from "app/modules/employee/components";
import {
  useRetrieveEmployeeById,
  useUpdateEmployee,
} from "app/modules/employee/hooks";
import {
  mapDefaultEmployee,
  mapUpdateEmployee,
} from "app/modules/employee/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateEmployeeProps {}

const UpdateEmployee: FC<UpdateEmployeeProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { employeeText } = useAppTranslation();

  const { employee, isLoading } = useRetrieveEmployeeById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateEmployee({
    id,
    onSuccess: () => {
      navigate(-1);
    },
  });

  const handleSubmit = async (data: EmployeeFormData) => {
    mutateAsync(mapUpdateEmployee({ id: id as string, ...data }));
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={employeeText.edit}
          pageTitle={employeeText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <EmployeeForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultEmployee(employee)}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateEmployee;
