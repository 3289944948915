import {
  RetrieveCustomersArgs,
  useRetrieveCustomersQuery,
} from "app/services/customer";

export const useRetrieveCustomers = (args: RetrieveCustomersArgs) => {
  const result = useRetrieveCustomersQuery(args);
  return {
    customers: result.data?.data.data,
    isLoadingCustomers: result.isLoading,
    ...result,
  };
};
