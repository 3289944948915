import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { mapPermission } from "app/modules/permission/helpers";
import { useRetrievePermissions } from "app/modules/permission/hooks";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface RoleFormProps {
  defaultValues?: RoleFormData;
  onSubmit: (data: RoleFormData) => void;
  submitting?: boolean;
  onToggle: () => void;
}

export interface RoleFormData {
  name: string;
  description: string;
  permission?: ID[];
}

export const RoleForm: FC<RoleFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onToggle,
}) => {
  const { roleText, buttonText, t } = useAppTranslation();
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<RoleFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        name: yupSchema.stringRequired("role.error.nameRequired"),
        description: yupSchema.stringNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const { permissions } = useRetrievePermissions();

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  return (
    <Form onSubmit={submit} id="role-form">
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="name" className="form-label">
              {roleText.field.name}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="name"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="description" className="form-label">
              {roleText.field.description}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="description"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel className="form-label">
              {roleText.field.permission}
            </FormLabel>
            <Controller
              name="permission"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  {permissions?.map((permission) => (
                    <FormGroup check key={permission.id}>
                      <FormInput
                        type="checkbox"
                        value={permission.id}
                        name="permission"
                        id={`permission${permission.id}`}
                        checked={field.value?.includes(permission.id)}
                        onChange={(e) => {
                          const newValue = [...(field.value || [])];
                          if (e.target.checked) {
                            newValue.push(permission.id);
                          } else {
                            const index = newValue.indexOf(permission.id);
                            if (index > -1) {
                              newValue.splice(index, 1);
                            }
                          }
                          field.onChange(newValue);
                        }}
                      />
                      <FormLabel check htmlFor={`permission${permission.id}`}>
                        {t(
                          `role.field.permissionList.${mapPermission(
                            permission.key,
                          )}`,
                        )}
                      </FormLabel>
                    </FormGroup>
                  ))}
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button color="light" onClick={onToggle}>
              {buttonText.cancel}
            </Button>
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
