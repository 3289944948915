import { jwtDecode } from "jwt-decode";
import { CurrentUser, tokenStorage } from "app/services/auth";
import { FetchCurrentUserError } from "./AuthContext";

export type JsonWebTokenState = {
  invalid: boolean;
  expired: boolean;
};

export type AuthState = {
  authenticated: boolean;
  currentUser: CurrentUser | null;
  fetchingCurrentUser: boolean;
  fetchCurrentUserError: FetchCurrentUserError | null;
};

interface AuthenticatedAction {
  type: "AUTHENTICATED";
  payload: {
    accessToken: string;
  };
}

interface FetchCurrentUserAction {
  type: "FETCH_CURRENT_USER";
}

interface UpdateCurrentUserAction {
  type: "UPDATE_CURRENT_USER";
  payload: CurrentUser;
}

interface FetchCurrentUserFailAction {
  type: "FETCH_CURRENT_USER_FAIL";
  payload: FetchCurrentUserError;
}

interface LogoutAction {
  type: "LOGOUT";
  payload?: number;
}

export type AuthAction =
  | AuthenticatedAction
  | FetchCurrentUserAction
  | UpdateCurrentUserAction
  | FetchCurrentUserFailAction
  | LogoutAction;

export const checkAccessToken = (): JsonWebTokenState => {
  try {
    const accessToken = tokenStorage.getAccessToken();

    if (!accessToken) {
      return {
        invalid: true,
        expired: false,
      };
    }

    const decodedJWT = accessToken && jwtDecode<{ exp: number }>(accessToken);
    const isJWTExprired = !!decodedJWT && decodedJWT.exp * 1000 < Date.now();

    return {
      invalid: false,
      expired: isJWTExprired,
    };
  } catch (error) {
    console.log(`Invalid JWT: ${JSON.stringify(error)}`);

    return {
      invalid: true,
      expired: false,
    };
  }
};

export const authReducer = (
  state: AuthState,
  action: AuthAction,
): AuthState => {
  switch (action.type) {
    case "AUTHENTICATED":
      return {
        ...state,
        authenticated: true,
      };
    case "FETCH_CURRENT_USER":
      return {
        ...state,
        fetchingCurrentUser: true,
      };
    case "UPDATE_CURRENT_USER":
      return {
        ...state,
        fetchingCurrentUser: false,
        currentUser: action.payload,
      };
    case "FETCH_CURRENT_USER_FAIL":
      return {
        ...state,
        fetchingCurrentUser: false,
        fetchCurrentUserError: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        authenticated: false,
        currentUser: null,
      };
    default:
      throw new Error(
        `Unhandled auth reducer for action type: ${JSON.stringify(action)}`,
      );
  }
};
