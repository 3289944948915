import { Button } from "app/components/molecules/Button";
import { FC, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export interface NewUpdateModalProps {
  open: boolean;
  onToggle: () => void;
  onClose: () => void;
  onConfirm: () => void;
}

export const NewUpdateModal: FC<NewUpdateModalProps> = ({
  onToggle,
  open,
  onClose,
  onConfirm,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = () => {
    setIsLoading(true);
    onConfirm();
  };

  const handleClose = () => {
    onClose();
    onToggle();
  };

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        Cập nhật
      </ModalHeader>
      <ModalBody>
        <p>Có cập nhật mới, bạn có muốn cập nhật không?</p>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleClose}>
          Hủy
        </Button>
        <Button loading={isLoading} color="primary" onClick={handleConfirm}>
          Cập nhật
        </Button>
      </ModalFooter>
    </Modal>
  );
};
