import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Product } from "./types";

export const ACTIVE_PRODUCT_URL = (id: ID) => `/api/products/${id}/active`;

export enum ActiveProductError {}

export const activeProductErrorMessages = {};

export type ActiveProductResponse = Response<Product>;

export type ActiveProductArgs = {
  id: ID;
};

export const activeProduct = ({ id }: ActiveProductArgs) => {
  return httpClient.put<ActiveProductResponse>(ACTIVE_PRODUCT_URL(id));
};

export const useActiveProductMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<ActiveProductResponse>,
      AxiosError<any>,
      ActiveProductArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<ActiveProductResponse>,
    AxiosError<any>,
    ActiveProductArgs
  >({
    mutationFn: activeProduct,
    mutationKey: [ACTIVE_PRODUCT_URL],
    ...opts,
  });
