import { CommonProperties } from "app/services/types";
import {
  CreateVendorArgs,
  UpdateVendorArgs,
  Vendor,
} from "app/services/vendor";
import { WithID } from "app/models";
import { VendorFormData } from "./components";

export const mapper = ({
  address,
  assignee,
  email,
  notes,
  phoneNumber,
  representative,
  representativeRole,
  startDate,
  vendorCode,
  vendorName,
  vendorStatus,
  vendorType,
  website,
}: VendorFormData): CommonProperties<CreateVendorArgs, UpdateVendorArgs> => {
  return {
    address: address || null,
    assigneeId: assignee?.value || null,
    email: email || null,
    notes: notes || null,
    phoneNumber: phoneNumber || null,
    representative: representative || null,
    representativeRole: representativeRole || null,
    startDate: startDate?.toISOString() || null,
    vendorCode: vendorCode || null,
    vendorName,
    vendorStatusId: vendorStatus?.value || null,
    vendorTypeId: vendorType?.value || null,
    website: website || null,
  };
};

export const mapCreateVendor = (data: VendorFormData): CreateVendorArgs => {
  return mapper(data);
};

export const mapUpdateVendor = ({
  id,
  ...data
}: WithID<VendorFormData>): UpdateVendorArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultVendor = ({
  address,
  assignee,
  email,
  notes,
  phoneNumber,
  representative,
  representativeRole,
  startDate,
  vendorCode,
  vendorName,
  vendorStatus,
  vendorType,
  website,
}: Partial<Vendor> = {}): VendorFormData => {
  return {
    vendorCode: vendorCode || "",
    vendorName: vendorName || "",
    vendorType: vendorType
      ? {
          label: vendorType?.name || "",
          value: vendorType?.id || "",
        }
      : null,
    address: address || "",
    phoneNumber: phoneNumber || "",
    email: email || "",
    website: website || "",
    representative: representative || "",
    representativeRole: representativeRole || "",
    notes: notes || "",
    startDate: startDate ? new Date(startDate) : null,
    vendorStatus: vendorStatus
      ? {
          label: vendorStatus?.name || "",
          value: vendorStatus?.id || "",
        }
      : null,
    assignee: assignee
      ? {
          label: assignee.fullName,
          value: assignee.id,
        }
      : null,
  };
};
