import { useAppTranslation, useDebounceValue } from "app/hooks";
import { useEffect, useState } from "react";

export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  isBorder,
  ...props
}: {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
  isBorder?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
  const [value, setValue] = useState(initialValue);
  const { placeHolderText } = useAppTranslation();

  const debouncedValue = useDebounceValue(value, debounce);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  return (
    <input
      {...props}
      value={value}
      placeholder={`${placeHolderText.search}...`}
      className={`form-control ${isBorder ? "" : "border-0"} search`}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
