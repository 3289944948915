import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  createCustomerErrorMessages,
  RETRIEVE_CUSTOMERS_URL,
  useCreateCustomerMutation,
} from "app/services/customer";

interface UseCreateCustomerProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreateCustomer = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseCreateCustomerProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateCustomerMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_CUSTOMERS_URL] });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createCustomerErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
