import {
  RetrieveMasterDataByIdArgs,
  useRetrieveMasterDataByIdQuery,
} from "app/services/masterData";

export const useRetrieveMasterDataById = (
  args: RetrieveMasterDataByIdArgs,
  opts?: Parameters<typeof useRetrieveMasterDataByIdQuery>[1],
) => {
  const result = useRetrieveMasterDataByIdQuery(args, opts as any);
  return {
    masterData: result.data?.data.data,
    isLoadingMasterData: result.isLoading,
    ...result,
  };
};
