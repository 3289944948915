import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { BomProduct } from "../bomProduct";
import { Response } from "../types";

export const RETRIEVE_PRODUCT_BOM_BY_ID_URL = (id: ID) =>
  `/api/products/${id}/bom`;

export type RetrieveProductBomByIdResponse = Response<BomProduct>;

export type RetrieveProductBomByIdArgs = {
  id: ID;
};

export const retrieveProductBomById = ({
  id,
  ...args
}: RetrieveProductBomByIdArgs) => {
  return httpClient.get<RetrieveProductBomByIdResponse>(
    RETRIEVE_PRODUCT_BOM_BY_ID_URL(id),
    { params: args },
  );
};

export const useRetrieveProductBomByIdQuery = (
  retrieveProductBomsArgs: RetrieveProductBomByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductBomByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductBomByIdResponse>, AxiosError<any>>({
    queryKey: [
      RETRIEVE_PRODUCT_BOM_BY_ID_URL(retrieveProductBomsArgs.id),
      retrieveProductBomsArgs,
    ],
    queryFn: () => retrieveProductBomById(retrieveProductBomsArgs),
    ...opts,
  });
