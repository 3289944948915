import {
  RetrieveJobTitlesArgs,
  useRetrieveJobTitlesQuery,
} from "app/services/jobTitle";

export const useRetrieveJobTitles = (args: RetrieveJobTitlesArgs) => {
  const result = useRetrieveJobTitlesQuery(args);
  return {
    jobTitles: result.data?.data.data,
    isLoadingJobTitles: result.isLoading,
    ...result,
  };
};
