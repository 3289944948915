import {
  RetrieveCustomerByIdArgs,
  useRetrieveCustomerByIdQuery,
} from "app/services/customer";

export const useRetrieveCustomerById = (
  args: RetrieveCustomerByIdArgs,
  opts?: Parameters<typeof useRetrieveCustomerByIdQuery>[1],
) => {
  const result = useRetrieveCustomerByIdQuery(args, opts as any);
  return {
    customer: result.data?.data.data,
    isLoadingCustomer: result.isLoading,
    ...result,
  };
};
