import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { Source } from "app/models";
import { getUploadedFileIds } from "app/modules/file/helpers";
import { ProductForm, ProductFormData } from "app/modules/product/components";
import { useCreateProduct } from "app/modules/product/hooks";
import {
  mapCreateProduct,
  mapDefaultProduct,
} from "app/modules/product/mapper";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateProductProps {}

const CreateProduct: FC<CreateProductProps> = () => {
  const { productText } = useAppTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateProduct({
    onSuccess: () => {
      navigate("/products");
    },
  });

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const { mutateAsync: deleteFile } = useDeleteFile();

  const handleSubmit = async (data: ProductFormData) => {
    const imageIds =
      (await getUploadedFileIds({
        data: data.images,
        fileKey: "image",
        source: Source.Product,
        upload,
      })) || [];
    const imageId = imageIds[0];
    await mutateAsync(mapCreateProduct({ ...data, imageId }), {
      onError: () => {
        if (imageIds[0]) {
          deleteFile({ id: imageId });
        }
      },
    });
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={productText.add}
          pageTitle={productText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <ProductForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultProduct()}
              submitting={isPending || isUploading}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateProduct;
