import { Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "app/components/organisms/AuthProvider";
import { LoadingScreen } from "app/components/organisms/LoadingScreen";

const AuthPosProtected = (props: any) => {
  const navigate = useNavigate();
  const { authenticated, fetchingCurrentUser, currentUser } = useAuth();

  useEffect(() => {
    if (!authenticated) {
      navigate("/pos/login");
    }
  }, [authenticated]);

  if (fetchingCurrentUser || !currentUser) {
    return <LoadingScreen />;
  }

  return <Suspense fallback={<LoadingScreen />}>{props.children}</Suspense>;
};

export default AuthPosProtected;
