import { FC, PropsWithChildren } from "react";
import { Container } from "reactstrap";

export interface DetailPageHeaderProps {}

export const DetailPageHeader: FC<
  DetailPageHeaderProps & PropsWithChildren
> = ({ children }) => {
  return (
    <Container fluid>
      <div className="mb-4 mb-lg-3">{children}</div>
    </Container>
  );
};
